<template>
  <div>
    <section class="bg6 m-t-15">
      <div class="container p-lr-0 bg0 bor8">
        <div class="mall-banner">
          <img
            src="https://www.eurekabookhouse.co.id/assets/front/images/banner-promo/banner-author-atas.jpg"
          />
        </div>
        <div class="mall-detail p-all-15">
          <div class="row">
            <div class="col-lg-10 col-md-10">
              <div class="flex-w">
                <div
                  class="logo-mall bs1"
                  style="
                    background: #ffffff
                      url('https://cdn.eurekabookhouse.co.id/ebh/mall/EurekaBookhouse_foto18_37_57.png')
                      no-repeat center center;
                    background-size: contain;
                    border: 2px solid #eee;
                  "
                ></div>
                <div class="detail-mall flex-m">
                  <div>
                    <h1 class="ltext-101">
                      <b>EurekaBookhouse</b>
                      <span class="mtext-101">
                        <i class="fa fa-star"></i><i class="fa fa-star"></i
                        ><i class="fa fa-star"></i><i class="fa fa-star"></i
                        ><i class="fa fa-star"></i>
                      </span>
                    </h1>
                    <p class="stext-107 cl1">
                      <i class="fa fa-map-marker m-r-10"></i>Jakarta Timur |
                      <span class="stext-107 cl3">Total 7167 produk</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 flex-m">
              <div class="pt-3">
                <a
                  href="https://www.facebook.com/sharer.php?u=https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse"
                  target="_blank"
                  class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"
                  ><i class="fa fa-facebook"></i
                ></a>
                <a
                  href="https://twitter.com/share?u=https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse"
                  target="_blank"
                  class="p-lr-13 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 m-r-8 bor4 customer share"
                  ><i class="fa fa-twitter"></i
                ></a>
                <a
                  href="https://plus.google.com/share?u=https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse"
                  target="_blank"
                  class="p-lr-15 p-tb-8 bg5 hov-bg1 cl0 hov-cl0 bor4 customer share"
                  ><i class="fa fa-google-plus"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg6 p-t-15 p-b-10">
      <div class="container p-lr-0">
        <div class="row">
          <div class="col-lg-12 col-xl-12 m-lr-auto">
            <div class="m-b-10 p-lr-15-sm">
              <div class="row">
                <div class="col-lg-3 col-md-3" id="menu_side">
                  <div class="p-all-15 bg0">
                    <div class="p-b-18">
                      <p class="mtext-101 cl2 p-b-10 text-left">
                        <b>Kategori Toko</b>
                      </p>
                    </div>
                    <div class="scrollbar" id="">
                      <div class="content">
                        <div id="cc">
                          <div class="flex-w flex-t bor12 p-b-13 text-left">
                            <ul id="tree2" class="tree">
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/3?sort=terbaru"
                                >
                                  <u><b>Buku cok </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/4?sort=terbaru"
                                      >Buku SD</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/5?sort=terbaru"
                                      >Buku SMP</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/8?sort=terbaru"
                                      >Buku TK</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/9?sort=terbaru"
                                  ><u><b>Perguruan Tinggi </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/11?sort=terbaru"
                                      >Akuntansi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/13?sort=terbaru"
                                      >Bahasa &amp; Sastra</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/14?sort=terbaru"
                                      >Ekonomi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/15?sort=terbaru"
                                      >Hukum</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/16?sort=terbaru"
                                      >Kedokteran</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/19?sort=terbaru"
                                      >Lecture Notes</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/20?sort=terbaru"
                                      >Manajamen</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/35?sort=terbaru"
                                      >Jurnalistik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/200?sort=terbaru"
                                      >Komputer</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/36?sort=terbaru"
                                  ><u><b>Buku Umum </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/1?sort=terbaru"
                                      >Buku Agama</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/37?sort=terbaru"
                                      >Biografi dan Otobiografi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/38?sort=terbaru"
                                      >Komik dan Novel</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/39?sort=terbaru"
                                      >Masakan dan Minuman</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/45?sort=terbaru"
                                      >Anak dan Remaja</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/46?sort=terbaru"
                                      >Ekonomi dan Bisnis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/47?sort=terbaru"
                                      >Hukum, Sosial dan Politik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/48?sort=terbaru"
                                      >Kesehatan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/49?sort=terbaru"
                                      >Hobi dan Hastakarya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/50?sort=terbaru"
                                      >Komputer</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/51?sort=terbaru"
                                      >Parenting</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/60?sort=terbaru"
                                      >Buku Parenting</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/167?sort=terbaru"
                                      >Kamus dan Bahasa</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/199?sort=terbaru"
                                      >Komik</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/207?sort=terbaru"
                                      >Motivasi Dan Inspirasi</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/313?sort=terbaru"
                                      >Buku Umum Lainnya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/315?sort=terbaru"
                                      >Psikology</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/40?sort=terbaru"
                                  ><u><b>Buku Soal </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/41?sort=terbaru"
                                      >Soal SD</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/42?sort=terbaru"
                                      >Soal SMP</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/43?sort=terbaru"
                                      >Soal SMA</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/193?sort=terbaru"
                                      >Buku Soal Ujian UN</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/252?sort=terbaru"
                                      >Soal SMK</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/54?sort=terbaru"
                                  ><u><b>Novel </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/387?sort=terbaru"
                                      >Novel Remaja</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/389?sort=terbaru"
                                      >Novel Romance</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/391?sort=terbaru"
                                      >Novel Horor</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/393?sort=terbaru"
                                      >Terjemahan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/395?sort=terbaru"
                                      >Lainnya</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/55?sort=terbaru"
                                  ><u><b>Buku Anak </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/67?sort=terbaru"
                                      >Buku Seri</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/69?sort=terbaru"
                                      >Creativity Series</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/73?sort=terbaru"
                                      >Cerita Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/74?sort=terbaru"
                                      >Edukasi Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/75?sort=terbaru"
                                      >Buku Anak Lainnya</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/77?sort=terbaru"
                                      >Membaca Dan Menulis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/100?sort=terbaru"
                                      >Cerita Rakyat Nusantara</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/101?sort=terbaru"
                                      >Creative Writing</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/117?sort=terbaru"
                                      >Keterampilan Anak</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/148?sort=terbaru"
                                      >Pendidikan Umum</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/154?sort=terbaru"
                                      >Majalah Bravo!</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/156?sort=terbaru"
                                      >Menggambar Dan Mewarnai</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/158?sort=terbaru"
                                      >Paket Buku Spesial</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/196?sort=terbaru"
                                      >Ensiklopedia</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/76?sort=terbaru"
                                  ><u><b>Mainan & Hobi </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/349?sort=terbaru"
                                      >Perlengkapan Menggambar</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/373?sort=terbaru"
                                      >Puzzle</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/375?sort=terbaru"
                                      >Diecast & Model Kit</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/377?sort=terbaru"
                                      >Figur</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/150?sort=terbaru"
                                  ><u><b>Gaya Hidup </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/151?sort=terbaru"
                                      >Tas</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/367?sort=terbaru"
                                      >Sepatu</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/369?sort=terbaru"
                                      >Tumbler</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/251?sort=terbaru"
                                  ><u><b>Buku Import </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/286?sort=terbaru"
                                      >Fiction</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/288?sort=terbaru"
                                  ><u><b>Buku Murah </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/343?sort=terbaru"
                                  ><u><b> ATK</b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/345?sort=terbaru"
                                      >Alat dan buku tulis</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/347?sort=terbaru"
                                      >Perlengkapan Kantor</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/353?sort=terbaru"
                                      >IT Supply</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/449?sort=terbaru"
                                  ><u><b>Paket Push </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/451?sort=terbaru"
                                  ><u><b>Buku Flat </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/499?sort=terbaru"
                                  ><u><b>Off. Merchandise </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/501?sort=terbaru"
                                      >Local Merch</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/513?sort=terbaru"
                                  ><u><b>Eureka Mart </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/509?sort=terbaru"
                                      >Kebutuhan Rumah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/515?sort=terbaru"
                                      >Perawatan Kesehatan</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/527?sort=terbaru"
                                      >Paling Dicari</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/529?sort=terbaru"
                                  ><u><b>Action Figure </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/541?sort=terbaru"
                                  ><u><b>eBook </b></u></a
                                >
                                <ul id="tree1">
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/555?sort=terbaru"
                                      >Majalah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/559?sort=terbaru"
                                      >Buku Sekolah</a
                                    >
                                  </li>
                                  <li class="40 child" style="">
                                    <a
                                      style="width: 80%"
                                      href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/561?sort=terbaru"
                                      >Buku Umum</a
                                    >
                                  </li>
                                </ul>
                              </li>
                              <li class="0 branch">
                                <a
                                  style="width: 80%"
                                  href="https://www.eurekabookhouse.co.id/mall/detail/eurekabookhouse/549?sort=terbaru"
                                  ><u><b>DIY </b></u></a
                                >
                                <ul id="tree1"></ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-9 col-md-9" id="content_side">
                  <div class="bg0 p-all-15 p-all-0-sm">
                    <div id="postList" class="flex-w">
                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-purple-id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2020) TEXTLINER 365 TRANSLUCENT purple ID.jpg"
                                alt="textliner-365-translucent-purple-id"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-purple-id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                TEXTLINER 365 TRANSLUCENT PURPLE ID
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.10.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-green-id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2020) TEXTLINER 365 TRANSLUCENT GREEN ID.jpg"
                                alt="textliner-365-translucent-green-id"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-green-id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                TEXTLINER 365 TRANSLUCENT GREEN ID
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.10.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-yellow-id"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/TEXTLINER 365 TRANSLUCENT YELLOW ID.jpg"
                                alt="textliner-365-translucent-yellow-id"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/textliner-365-translucent-yellow-id"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                TEXTLINER 365 TRANSLUCENT YELLOW ID
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.10.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/econ-mech-pencil-05"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ECON MECH PENCIL 0.5 BOX 12 PCS.jpg"
                                alt="econ-mech-pencil-0-5"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/econ-mech-pencil-05"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                ECON MECH PENCIL 0.5
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.13.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/lead-superfine-05-2b"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/LEAD SUPERFINE 0.5 2B.jpg"
                                alt="lead-superfine-0-5-2b"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/lead-superfine-05-2b"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                LEAD SUPERFINE 0.5 2B
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.7.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/super-dough---creativity-set--95030099"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2018) SUPER DOUGH - CREATIVITY SET.jpg"
                                alt="super-dough-creativity-set-9503-00-99"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/super-dough---creativity-set--95030099"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                SUPER DOUGH - CREATIVITY SET #9503.00.99
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.26.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/indo-brix---cityscapes"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/INDO BRIX - CITYSCAPES.jpg"
                                alt="indo-brix-cityscapes"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/indo-brix---cityscapes"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                INDO BRIX - CITYSCAPES
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.60.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/monsterjam170valuetrucks"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/MONSTERJAM1 70VALUETRUCKS.jpg"
                                alt="monsterjam1-70valuetrucks"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/monsterjam170valuetrucks"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                MONSTERJAM1:70VALUETRUCKS
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.40.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/rezim-kerja-keras-dan-masa-depan-kita"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/rezim.jpg"
                                alt="rezim-kerja-keras-dan-masa-depan-kita"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/rezim-kerja-keras-dan-masa-depan-kita"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Rezim Kerja Keras dan Masa Depan Kita
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.88.000</small></s
                                ><strong class="cl13"> Rp.70.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/parade-yang-tak-pernah-usai"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/parade.jpg"
                                alt="parade-yang-tak-pernah-usai"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/parade-yang-tak-pernah-usai"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Parade yang Tak Pernah Usai
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.88.000</small></s
                                ><strong class="cl13"> Rp.70.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="10%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/membicarakan-feminisme--edisi-revisi-"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/feminisme.jpg"
                                alt="membicarakan-feminisme-edisi-revisi"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/membicarakan-feminisme--edisi-revisi-"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Membicarakan Feminisme (Edisi Revisi)
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.78.000</small></s
                                ><strong class="cl13"> Rp.70.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/kisah-cinta-kesekian--puisi-"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/kisah cinta.jpg"
                                alt="kisah-cinta-kesekian-puisi"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/kisah-cinta-kesekian--puisi-"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Kisah Cinta Kesekian (Puisi)
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.55.000</small></s
                                ><strong class="cl13"> Rp.44.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/lagu-tidur---puisi"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/lagu tidur.jpg"
                                alt="lagu-tidur-puisi"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/lagu-tidur---puisi"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                LAGU TIDUR - PUISI
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.52.000</small></s
                                ><strong class="cl13"> Rp.41.600</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/alligator-cloth-tape-36mm-black"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2020) ALLIGATOR CLOTH TAPE 36MM BLACK.jpg"
                                alt="alligator-cloth-tape-36mm-black"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/alligator-cloth-tape-36mm-black"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                ALLIGATOR CLOTH TAPE 36MM BLACK
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.12.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/autoclick-05mm-white-barrel-blister"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/auto clic.jpg"
                                alt="autoclick-0-5mm-white-barrel-blister"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/autoclick-05mm-white-barrel-blister"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                AUTOCLICK 0.5MM WHITE BARREL BLISTER
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.23.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/corrtape-qar-506-yellow-barrel-fancy"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/CORR.TAPE QAR-506 LIGHT yellow BARREL FANCY.png"
                                alt="corr-tape-qar-506-yellow-barrel-fancy"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/corrtape-qar-506-yellow-barrel-fancy"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                CORR.TAPE QAR-506 YELLOW BARREL FANCY
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.18.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/corrtape-qar-506-light-pink-barrel-fancy"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/CORR.TAPE QAR-506 LIGHT PINK BARREL FANCY.png"
                                alt="corr-tape-qar-506-light-pink-barrel-fancy"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/corrtape-qar-506-light-pink-barrel-fancy"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                CORR.TAPE QAR-506 LIGHT PINK BARREL FANCY
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.18.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/uhu-stick-82-gr-bts-version"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2020) UHU STICK 8.2 GR BTS VERSION.jpg"
                                alt="uhu-stick-8-2-gr-bts-version"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/uhu-stick-82-gr-bts-version"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                UHU STICK 8.2 GR BTS VERSION
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.8.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="100%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/faber-castell-uhu-stic-21-gr-bts-version"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(2020) UHU STIC 21 GR BTS VERSION.jpg"
                                alt="faber-castell-uhu-stic-21-gr-bts-version"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/faber-castell-uhu-stic-21-gr-bts-version"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                FABER CASTELL UHU STIC 21 GR BTS VERSION
                              </a>
                              <div class="stext-105">
                                <strong class="cl13 fs-15">Rp.15.500</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/rahasia-keluarga"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/RAHASIA KELUARGA.jpg"
                                alt="rahasia-keluarga"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/rahasia-keluarga"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Rahasia Keluarga
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.85.000</small></s
                                ><strong class="cl13"> Rp.68.000</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/catatan-kronik--free-totebag-"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(1196) CATATAN KRONIK.jpg"
                                alt="catatan-kronik-free-totebag"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/catatan-kronik--free-totebag-"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Catatan Kronik (Free totebag)
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.99.000</small></s
                                ><strong class="cl13"> Rp.79.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/classics-1984"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/1984_cov.jpg"
                                alt="classics-1984"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/classics-1984"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Classics: 1984
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.99.000</small></s
                                ><strong class="cl13"> Rp.79.200</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/marketing-50-teknologi-untuk-kemanusiaan"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(1196) MARKETING 5.0 TEKNOLOGI UNTUK KEMANUSIAAN.jpg"
                                alt="marketing-5-0-teknologi-untuk-kemanusiaan"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/marketing-50-teknologi-untuk-kemanusiaan"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Marketing 5.0: Teknologi untuk Kemanusiaan
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.98.000</small></s
                                ><strong class="cl13"> Rp.78.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="block-produk">
                        <div class="kartu bg0">
                          <div
                            class="block2-pic hov-img0 label-new text-center"
                            data-label="20%"
                          >
                            <img
                              class="img-official"
                              src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                            />
                            <a
                              href="https://www.eurekabookhouse.co.id/product/pasar-dan-karier-kembali-ke-akar-rekontruksi-pasar-dan-dunia"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                            >
                              <img
                                class="img-responsive"
                                src="https://cdn.eurekabookhouse.co.id/ebh/product/all/KB-(1196) PASAR DAN KARIER KEMBALI KE AKAR-REKONSTRUKSI PASAR DAN DUNIA.jpg"
                                alt="pasar-dan-karier-kembali-ke-akar-rekontruksi-pasar-dan-dunia"
                              />
                            </a>
                          </div>
                          <div class="block2-txt flex-w flex-t p-all-8">
                            <div class="block2-txt-child1 flex-col-l">
                              <a
                                href="https://www.eurekabookhouse.co.id/product/pasar-dan-karier-kembali-ke-akar-rekontruksi-pasar-dan-dunia"
                                class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                              >
                                Pasar Dan Karier Kembali Ke Akar-Rekontruksi
                                Pasar Dan Dunia
                              </a>
                              <div class="stext-105">
                                <s><small class="fs-9">Rp.98.000</small></s
                                ><strong class="cl13"> Rp.78.400</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script scope>
export default {
  name: "ebhPage",
};
</script>

<style type="text/css">
.block-produk {
  width: 25%;
  margin-bottom: 15px;
}
.block-produk .kartu {
  margin-left: 10px;
  margin-right: 10px;
}
.block-produk .kartu:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
}
</style>

<style>
.mall-overview {
  display: inline-block;
}
.logo-mall {
  width: 160px;
  height: 160px;
  display: inline;
  float: left;
  background-color: #ffffff;
  margin-right: 20px;
  margin-top: -100px;
}
.mall-detail-stat img {
  height: 24px;
}
span.data {
  color: red;
}
.tree ul {
  margin-left: 1em;
  position: relative;
}
.tree,
.tree ul {
  margin: 0;
  margin-left: 0px;
  padding: 0;
  list-style: none;
  width: 100%;
}
.carousel-cell {
  margin-right: 10px;
  border-radius: 5px;
}
.carousel-cell img {
  width: 100%;
}
@media (min-width: 767px) {
  .mall-banner {
    height: 200px;
  }
  .mall-banner img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .wd-100 {
    width: 100%;
    height: 280px;
  }
}
@media (max-width: 767px) {
  .mall-banner {
    height: 120px;
  }
  .mall-banner img {
    width: 100%;
    height: 120px;
    object-fit: cover;
  }
  .wd-100 {
    width: 100%;
    height: 180px;
  }
}
/* cell number */
.carousel-cell:before {
  display: block;
  text-align: center;
  line-height: 200px;
  font-size: 80px;
}
.nav-sort-mall > li > a {
  padding: 5px;
  color: #666;
}
.nav-sort-mall > li > .active {
  color: #2c6b9b;
  font-weight: 600;
}
</style>
