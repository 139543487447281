<template>
  <!-- <div style="position: fixed; z-index: 999;" class="w-full"> -->
  <div>
    <div>
      <b-navbar
        toggleable="lg"
        type="dark"
        variant="info"
        max-height="100"
        style="background-color: #091d42 !important"
      >
        <div class="d-lg-none w-full">
          <div class="d-flex justify-content-between">
            <div @click="toggleSearchForm">
              <img
                class="mt-2"
                src="../assets/images/navbar/search.png"
                alt="Right Logo"
              />
            </div>

            <div>
              <router-link :to="'/'">
                <img
                  class="justify-center items-center"
                  style="height: 50px"
                  src="../assets/images/navbar/ebh2.png"
                  alt="Right Logo"
                />
              </router-link>
            </div>
            <b-navbar-toggle
              v-model="navCollapsed"
              target="nav-collapse"
            ></b-navbar-toggle>
          </div>
          <!-- Tampilkan form jika searchActive bernilai true -->
          <form
            @input="handleInput"
            v-if="searchActive && navCollapsed"
            class="mt-3 col-md-4 ml-0"
          >
            <div>
              <center>
                <div style="position: relative">
                  <input
                    v-model="searchQuery"
                    style="
                      padding: 5px 20px;
                      width: 100%;
                      border-radius: 40px;
                      height: 40px;
                      margin-top: 10px;
                      background: var(--neutral-01, #fefefe);
                    "
                    type="text"
                    autocomplete="off"
                    placeholder="Cari bukumu disini"
                    required
                  />
                  <button
                    style="
                      position: absolute;
                      right: 5px;
                      top: 50%;
                      transform: translateY(-50%);
                      padding: 5px 15px;
                      border-radius: 20px;
                      background: var(--primary-04, #3860a8);
                    "
                    @click.prevent="searchProducts"
                  >
                    <i class="fa fa-search fs-0" style="color: white"></i>
                  </button>
                </div>
              </center>
              <div
                class="mt-3 suggestion-box submenu"
                v-show="isOpen"
                style="width: 92%"
              >
                <div class="text-left" v-if="results.productResult.length">
                  <h5
                    class="mt-3"
                    style="padding: 10px 15px; color: #5178be; font-weight: 500"
                  >
                    Product
                  </h5>
                  <hr style="margin: 0" />
                  <ul>
                    <li
                      class="pilih-produk"
                      v-for="product in results.productResult"
                      :key="product.id"
                      @click="handleProductClick(product)"
                    >
                      <span class="submenu-item">{{ product.name }} </span>
                    </li>
                  </ul>
                </div>
                <div
                  class="text-left"
                  v-if="results.categoryResult && results.categoryResult.length"
                >
                  <h5
                    class="mt-3"
                    style="padding: 10px 15px; color: #5178be; font-weight: 500"
                  >
                    Kategori
                  </h5>
                  <hr style="margin: 0" />
                  <ul>
                    <li
                      class="pilih-produk"
                      v-for="category in results.categoryResult"
                      :key="category.slug"
                    >
                      <span
                        @click="handleCategoryClick(category)"
                        class="submenu-item"
                      >
                        {{ category.name }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="text-left" v-if="results.manufactureResult.length">
                  <h5
                    class="mt-3"
                    style="padding: 10px 15px; color: #5178be; font-weight: 500"
                  >
                    Penerbit
                  </h5>
                  <hr style="margin: 0" />
                  <ul>
                    <li
                      class="pilih-produk"
                      v-for="manufacturer in results.manufactureResult"
                      :key="manufacturer.slug"
                    >
                      <span
                        class="submenu-item"
                        @click="handleManufacturerClick(manufacturer)"
                      >
                        {{ manufacturer.text }}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div id="suggesstion-box" class="suggestion-box"></div>
          </form>
          <!-- Hamburger button -->
        </div>

        <!-- Left Logo -->
        <img
          class="regis-left d-xl-block d-lg-none d-md-none d-sm-none d-none"
          src="../assets/images/navbar/tema2.png"
          alt="Left Logo"
        />

        <!-- Right Logo -->
        <img
          class="regis-right d-xl-block d-lg-none d-md-none d-sm-none d-none"
          src="../assets/images/navbar/tema1.png"
          alt="Right Logo"
        />
        <b-navbar-brand href="#">
          <div
            class="left-top-bar mr-3 d-xl-block d-lg-none d-md-none d-sm-none d-none"
          >
            <router-link :to="'/'"
              ><img
                class="img-fluid"
                src="../assets/images/navbar/ebh.png"
                alt="Vue Logo"
                style="max-width: 100%; max-height: 80px"
            /></router-link></div
        ></b-navbar-brand>

        <div class="d-xl-block d-lg-block d-md-block d-sm-none d-none">
          <div class="mr-4"><MegaMenu /></div>
        </div>
        <!-- Collapsible menu content -->
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav style="align-items: center">
            <b-nav-item href="#" class="title"
              ><router-link :to="'/blog'" style="color: white"
                >Blog</router-link
              ></b-nav-item
            >
            <b-nav-item href="#" class="title"
              ><router-link :to="'/BantuanEBH/bantuan'" style="color: white"
                >Bantuan</router-link
              ></b-nav-item
            >
            <b-nav-item
              v-if="isLoggedIn"
              href="#"
              class="title d-xl-none d-lg-none d-md-block d-sm-block d-block"
              ><router-link :to="'/cart'" style="color: white"
                >Keranjang</router-link
              ></b-nav-item
            >
            <b-nav-item
              v-if="isLoggedIn"
              href="#"
              class="title d-xl-none d-lg-none d-md-block d-sm-block d-block"
              ><router-link
                :to="'/Users/notificationprofile'"
                style="color: white"
                >Notifikasi</router-link
              ></b-nav-item
            >
            <b-nav-item
              v-if="!isLoggedIn"
              href="#"
              class="title d-xl-none d-lg-none d-md-block d-sm-block d-block"
              ><router-link :to="'/UserEBH/Login'" style="color: white"
                >Login</router-link
              ></b-nav-item
            >
            <b-nav-item
              v-if="isLoggedIn"
              href="#"
              class="title d-xl-none d-lg-none d-md-block d-sm-block d-block"
              ><div @click="logout" style="color: white">
                Logout
              </div></b-nav-item
            >
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- WEBSITE -->
            <b-nav-form
              class="d-xl-block d-lg-none d-md-none d-sm-none d-none"
              style="margin-left: -21%"
            >
              <form class="col-md-4 ml-0" @input="handleInput">
                <div>
                  <center>
                    <div style="position: relative">
                      <input
                        v-model="searchQuery"
                        style="
                          width: 430px;
                          padding: 5px 20px;
                          border-radius: 40px;
                          height: 40px;
                          margin-top: 10px;
                          background: var(--neutral-01, #fefefe);
                        "
                        type="text"
                        autocomplete="off"
                        placeholder="Cari dengan kode atau judul produk"
                        required
                      />
                      <button
                        style="
                          position: absolute;
                          right: -300px;
                          top: 50%;
                          transform: translateY(-50%);
                          padding: 5px 15px;
                          border-radius: 20px;
                          background: var(--primary-04, #3860a8);
                        "
                        @click.prevent="searchProducts"
                      >
                        <i class="fa fa-search fs-0" style="color: white"></i>
                      </button>
                    </div>
                  </center>
                  <div class="mt-3 suggestion-box submenu" v-show="isOpen">
                    <div class="text-left" v-if="results.productResult.length">
                      <h5
                        class="mt-3"
                        style="
                          padding: 10px 15px;
                          color: #5178be;
                          font-weight: 500;
                        "
                      >
                        Product
                      </h5>
                      <hr style="margin: 0" />
                      <ul>
                        <li
                          class="pilih-produk"
                          v-for="product in results.productResult"
                          :key="product.id"
                          @click="handleProductClick(product)"
                        >
                          <span class="submenu-item">{{ product.name }} </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="text-left"
                      v-if="
                        results.categoryResult && results.categoryResult.length
                      "
                    >
                      <h5
                        class="mt-3"
                        style="
                          padding: 10px 15px;
                          color: #5178be;
                          font-weight: 500;
                        "
                      >
                        Kategori
                      </h5>
                      <hr style="margin: 0" />
                      <ul>
                        <li
                          class="pilih-produk"
                          v-for="category in results.categoryResult"
                          :key="category.slug"
                        >
                          <span
                            @click="handleCategoryClick(category)"
                            class="submenu-item"
                          >
                            {{ category.name }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="text-left"
                      v-if="results.manufactureResult.length"
                    >
                      <h5
                        class="mt-3"
                        style="
                          padding: 10px 15px;
                          color: #5178be;
                          font-weight: 500;
                        "
                      >
                        Penerbit
                      </h5>
                      <hr style="margin: 0" />
                      <ul>
                        <li
                          class="pilih-produk"
                          v-for="manufacturer in results.manufactureResult"
                          :key="manufacturer.slug"
                        >
                          <span
                            class="submenu-item"
                            @click="handleManufacturerClick(manufacturer)"
                          >
                            {{ manufacturer.text }}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </form>
            </b-nav-form>
            <b-navbar-nav
              class="d-xl-block d-lg-block d-md-block d-sm-none d-none"
              style="margin-left: -1%"
            >
              <router-link
                v-if="!isLoggedIn"
                :to="'/UserEBH/Login'"
                class="flex-c-m trans- p-lr-17"
              >
                <button
                  style="
                    display: flex;
                    width: 103px;
                    height: 40px;
                    padding: 10px;
                    margin-top: 10px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 50px;
                    background: var(--secondary-05, #ffc341);
                    color: #091d42;
                    margin-left: 20px;
                  "
                >
                  Login
                </button>
              </router-link>
              <div v-if="isLoggedIn">
                <router-link :to="'/cart'" class="mr-2"
                  ><img
                    class="img-nav mt-3"
                    src="../assets/images/navbar/cart.png"
                    alt="Right Logo"
                  />
                  <div
                    class="badge"
                    style="
                      padding: 0.3em0.4em;
                      font-size: 100%;
                      font-weight: 700;
                      background-color: #5178be;
                      color: white;
                      left: 10px;
                    "
                  >
                    {{ totalItems }}
                  </div>
                </router-link>
                <router-link :to="'/Users/notificationprofile'">
                  <img
                    class="img-nav mr-1 mt-3"
                    src="../assets/images/navbar/notif.png"
                    alt="Right Logo"
                  />
                  <!-- <div class="badge" style="background-color: red">5</div> -->
                </router-link>

                <b-dropdown variant="link" no-caret class="custom-dropdown">
                  <template #button-content>
                    <!-- Gambar Statis -->
                    <!-- <img
                      class="img-nav mr-2"
                      src="../assets/images/navbar/user.png"
                      alt="Right Logo"
                    /> -->

                    <!-- Gambar Dinamis -->
                    <!-- <span >Halo ini tasya</span> -->
                    <!-- <img
                      class="img-nav mr-2"
                      :src="getAvatarUrl()"
                      style="width: 60px; height: 30px; border-radius: 50%"
                    /> -->
                    <img
                      class="img-nav mr-2"
                      src="https://tse4.mm.bing.net/th?id=OIP.l54ICAiwopa2RCt7J2URWwHaHa&pid=Api&P=0&h=180"
                      style="width: 60px; height: 30px; border-radius: 50%"
                    />
                    <!-- <span style="color: white; margin-top: 50px">
                      Halo,
                      <b
                        >{{ customer.firstname }} {{ customer.lastname }}</b
                      ></span
                    > -->
                  </template>

                  <b-dropdown-item href="#">
                    <router-link
                      :to="'/Users/Dashboarduser'"
                      style="color: black"
                      >User</router-link
                    ></b-dropdown-item
                  >

                  <b-dropdown-item href="#" @click="logout"
                    >Logout</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import Swal from "sweetalert2";
import {
  BNavbar,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavItem,
  BNavForm,
  BCollapse,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import MegaMenu from "@/components/MegaMenu.vue";
export default {
  name: "navBar",
  data() {
    return {
      totalItems: 0,
      name: "BootstrapVue",
      isOpen: false,
      searchActive: false,
      isDropdown2Visible: false,
      kategoriMaster: [],
      isLoggedIn: false,
      isNavCollapsed: false,
      navCollapsed: true,
      searchQuery: "",
      results: {
        productResult: [],
        categoryResult: [],
        manufactureResult: [],
      },
      username: "",
      selectedProduct: null,
      customer: null,
    };
  },
  components: {
    BNavbar,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavItem,
    BNavForm,
    BCollapse,
    MegaMenu,
  },
  mounted() {
    // Listen for the 'updateCart' event emitted by other components
    this.$root.$on("updateCart", () => {
      // Call the notifCart() method to update the total number of items
      this.notifCart();
    });

    // Call notifCart() initially when the component is mounted
    this.notifCart();
    this.fetchCustomerDetails(); //Profile User
    const menuItems = document.querySelectorAll(".title");

    // Mengatasi setiap elemen "title"
    menuItems.forEach((item) => {
      item.addEventListener("click", () => {
        // Menghapus kelas "aktif" dari semua elemen "title"
        menuItems.forEach((menuItem) => {
          menuItem.classList.remove("aktif");
        });
        // Menambahkan kelas "aktif" ke elemen yang diklik
        item.classList.add("aktif");
      });
    });

    // Menambahkan kelas "aktif" ke elemen "Home" secara otomatis saat halaman dimuat
    const homeMenuItem = document.querySelector(".title");
    homeMenuItem.classList.add("aktif");

    // this.getKategori();
    const token = localStorage.getItem("token");
    if (token) {
      this.isLoggedIn = true;
      // Lakukan apa yang diperlukan untuk mendapatkan nama pengguna atau info pengguna lainnya
    }
  },
  methods: {
    //Data User
    fetchCustomerDetails() {
      const token = localStorage.getItem("token");
      const idCustomer = localStorage.getItem("id_customer");
      const apiUrl = `https://stagingapi.eurekabookhouse.co.id/customer/detail?customer_id=${idCustomer}`;

      axios
        .get(apiUrl, { headers: { Authorization: `Bearer ${token}` } })
        .then((response) => {
          if (response.data.data) {
            this.customer = response.data.data;
          } else {
            console.error(
              "Failed to fetch customer details:",
              response.data.message
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching customer details:", error);
        });
    },

    //Profile User
    getAvatarUrl() {
      // Check if customer.avatar exists
      if (this.customer.avatar) {
        // If it exists, return the full URL
        return `https://stagingapi.eurekabookhouse.co.id/${this.customer.avatar}`;
      } else {
        // If it doesn't exist, return the default avatar URL
        return "https://www.eurekabookhouse.co.id/assets/uplod/default-avatar.png";
      }
    },
    //Pencarian berdasarkan Keyword
    async searchProducts() {
      try {
        const response = await axios.get(
          `https://stagingapi.eurekabookhouse.co.id/product/search?keyword=${this.searchQuery}`
        );

        if (response.data.status.code === 200) {
          this.massageData = response.data.status.message;
          // console.log("inii statssus", this.massageData);
          const categoryData = response.data.data.categoryData;
          // console.log("Product Data:", categoryData);

          // Close the suggestion box
          this.isOpen = false;

          // Navigate to the "result" page with the search query as a parameter
          this.$router.push({
            name: "result",
            params: { searchQuery: this.searchQuery },
          });

          // Clear the search query after successful search
          this.searchQuery = "";
        } else {
          console.error(
            "Error fetching product data:",
            response.data.status.message
          );
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    },

    // Integrasi API Pencarian Autocomplate atau elastic
    debouncedFetchResults: _.debounce(function () {
      if (this.searchQuery.trim() === "") {
        // If the search query is empty, close the suggestion box
        this.isOpen = false;
        return;
      }

      axios
        .get(
          `https://elasticsearch.ebh.jaja.id/autocompleteBookhouse?q=${this.searchQuery}`
        )
        .then((response) => {
          this.results = response.data.data;
          this.isOpen = true;
        })
        .catch((error) => {
          console.error("Error fetching results:", error);
        });
    }, 500), // Set the debounce delay to 500 milliseconds or adjust it as needed

    // Trigger fetchResults when the user stops typing
    handleInput: function () {
      this.debouncedFetchResults();
    },

    //Redirect hasil pencarian product
    handleProductClick(product) {
      this.$store.dispatch("setProductId", product.id);
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: product.slug },
      });
      this.isOpen = false;
      this.resetForm();
    },

    //Redirect hasil pencarian kategori
    handleCategoryClick(category) {
      this.$router.push(`/kategori/${category.slug}`);
      this.isOpen = false;
      this.resetForm(); // Menambahkan pemanggilan resetForm
    },

    //Redirect hasil pencarian penerbit
    handleManufacturerClick(manufacturer) {
      const slug = manufacturer.slug;
      this.$router.push({
        name: "kategoriPenerbitPage",
        params: { slug: slug },
      });
      this.isOpen = false;
      this.resetForm();
    },

    //Reset form input
    resetForm() {
      this.searchQuery = ""; // Reset nilai formulir
    },

    //Fungsi buka tutup navbar
    toggleSearchForm() {
      this.searchActive = !this.searchActive;
      this.navCollapsed = true; // Menutup navbar toggle saat form dibuka
    },
    toggleNav() {
      this.isNavCollapsed = !this.isNavCollapsed; // Toggle the navigation state
    },

    // Integrasi API notif
    notifCart() {
      const idCustomer = localStorage.getItem("id_customer");
      const url = `https://stagingapi.eurekabookhouse.co.id/cart/notification?id_customer=${idCustomer}`;

      axios
        .get(url)
        .then((response) => {
          // Mengambil nilai totalItems dari respon
          this.totalItems = response.data.data.totalItems;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    // getKategori() {
    //   var requestOptions = {
    //     method: "GET",
    //     redirect: "follow",
    //   };

    //   fetch(
    //     "http://localhost:8080/bupel/category/get-categories",
    //     requestOptions
    //   )
    //     .then((response) => response.text())
    //     .then((result) => {
    //       const res = JSON.parse(result);
    //       this.kategoriMaster = res.data;
    //     })
    //     .catch((error) => console.log("error", error));
    // },

    //Dropdown mobile responsive
    onOver() {
      this.$refs.dropdown.visible = true;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
    //Fungsi logout
    logout() {
      Swal.fire({
        title: "Konfirmasi Logout",
        text: "Apakah Anda yakin ingin logout?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya",
        confirmButtonColor: "#3860A8",
        cancelButtonText: "Tidak",
      }).then((result) => {
        if (result.isConfirmed) {
          // Hapus token dari local storage
          localStorage.removeItem("token");
          this.isLoggedIn = false;
          this.username = "";

          Swal.fire({
            icon: "success",
            confirmButtonColor: "#3860A8",
            title: "Logout Berhasil",
            text: "Anda telah berhasil logout.",
            customClass: {
              container: "small-alert", // Menambahkan class CSS custom untuk mengatur ukuran
            },
          });

          // Redirect pengguna ke halaman login atau halaman lain yang sesuai
          this.$router.push({ name: "login" });
        }
      });
    },
  },
};
</script>

<style type="text/css" scoped>
.custom-dropdown {
  max-height: none; /* Set your desired max height */
  overflow-y: none; /* Enable vertical scrollbar if content exceeds max height */
}
.custom-link {
  color: black; /* Ganti dengan warna yang Anda inginkan */
  text-decoration: none; /* Untuk menghapus garis bawah default pada tautan */
}

.custom-link:hover {
  text-decoration: none; /* Tambahkan garis bawah pada hover jika diinginkan */
  color: white;
}
.result-title {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Adjust the color as needed */
}

.result-divider {
  border: 1px solid #ccc; /* Adjust the color as needed */
  margin: 10px 0;
}

.result-list {
  list-style: none;
  padding: 0;
}

.product-name {
  color: black; /* Adjust the color as needed */
}
.product-name:hover {
  color: white; /* Adjust the color as needed */
  background-color: #254a6f;
  width: 100%;
}
.pilih-produk {
  padding: 5px 15px;
}
.pilih-produk:hover {
  background-color: #2c497e;
  color: white !important;
}
.submenu-item {
  height: 20px;
  cursor: pointer;
  text-align: left;
}
.text-search {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.text-search:hover {
  background-color: #254a6f;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-item {
  padding: 5%;
  cursor: pointer;
  background-color: transparent;
}

.submenu {
  margin-left: 15px;
  position: absolute;
  border-radius: 10px;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: white;
  height: auto;
  width: 430px;
  color: black;
  overflow-y: auto;
  max-height: 800px;
}

/* Gaya scrollbar untuk browser WebKit (Chrome, Safari, dll.) */
.submenu::-webkit-scrollbar {
  width: 8px; /* Lebar scrollbar */
}

.submenu::-webkit-scrollbar-thumb {
  background-color: #5178be; /* Warna thumb scrollbar */
  border-radius: 6px; /* Border radius untuk thumb scrollbar */
}

.submenu::-webkit-scrollbar-track {
  background-color: transparent; /* Tanpa latar belakang untuk track scrollbar */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .input-pencarian {
    width: 170px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .input-pencarian {
    width: 380px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .input-pencarian {
    width: 500px;
  }
}

.kategoriss {
  margin-bottom: 1rem !important;
  flex: 0 0 14%;
  max-width: 14%;
  margin-right: -24px;
  margin-left: -24px;
}

.img-nav {
  margin-top: 10px;
  max-width: 32px;
}

.small-alert {
  width: 250px; /* Sesuaikan lebar sesuai kebutuhan Anda */
  font-size: 14px; /* Sesuaikan ukuran font sesuai kebutuhan Anda */
}

.regis-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 70px; /* Sesuaikan dengan ukuran gambar Anda */
}

.regis-right {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 70px; /* Sesuaikan dengan ukuran gambar Anda */
}
.title {
  display: flex;
  width: 72px;
  font-weight: 500;
  padding: 10px;
  gap: 10px;
  color: #ffffff;
  text-decoration: none; /* Menonaktifkan garis bawah default pada tautan */
  transition: opacity 0.3s, border-bottom 0.3s; /* Efek transisi untuk perubahan opasitas dan garis bawah */
}

.title:hover {
  opacity: 1;
  border-bottom: 2px solid var(--neutral-01, #fefefe);
}
.mobile-dropdown {
  max-height: none; /* Set the maximum height to none or a specific value */
  transition: max-height 0.3s ease; /* Add a transition for smoother opening and closing */
}

/* Adjust the maximum height when the dropdown is expanded */
.mobile-dropdown.show {
  max-height: 300px; /* Set your desired maximum height here */
}
.scrollable-menu {
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.rounded-lg {
  border-radius: 2rem !important;
}
.catalog__dropdown-link {
  color: white !important;
  content: "";
  width: 24px;
  height: 18px;
  margin-right: 4px;
}

.b-dropdown {
  float: right;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 20rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  width: 350px !important;
  border-radius: 0.25rem;
}

.dropdown-menu.show {
  width: 350px !important;
}

.ul.dropdown-menu.show {
  width: 350px !important;
}

.popUpBannerBox {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  width: 100%;
  height: 100%;
  top: 100%;
  left: 100%;
  color: #fff;
  z-index: 999999;
  display: none;
}

.popUpBannerInner {
  max-width: 36px;
  margin: 0 auto;
}

.popUpBannerContent {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.gambar-pop {
  max-height: 500px;
}

@media (max-width: 575px) {
  .gambar-pop {
    height: 300px;
    width: 300px;
    object-fit: contain;
  }
}

.closeButton {
  position: absolute;
  right: -15px;
  top: -15px;
  color: red;
  text-decoration: none;
  font-size: 18px;
}

.closeButton img {
  height: 45px;
}

body {
  padding: 1rem;
}

.left-top-bar {
  line-height: 1.8;
  margin-left: 10rem;
}

.left-top-bar a span {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 1.8;
  color: #fff;
  margin-right: 10px;
}

.right-top-bar {
  margin-left: 10rem;
}

.right-top-bar a span {
  font-size: 13px;
}

.center-nav {
  margin-left: 10rem;
  font-size: 13px;
  color: #fff;
}

.dropdown-menu.show {
  display: block;
  margin-left: 100px;
  width: 350px !important;
}

.sobatgrosir {
  display: none;
}

.v-slider img {
  width: 100%;
}

.gbr-inspirasi {
  height: 70px;
}

.flickity-page-dots {
  bottom: 45px !important;
}

.main-carousel .flickity-prev-next-button {
  top: 40% !important;
}

@media (max-width: 575px) {
  .ban_3 {
    display: none;
  }

  .gbr-inspirasi {
    height: 40px;
  }

  .flickity-page-dots {
    bottom: 15px !important;
  }
}

.bgu4 {
  background-color: #c4d9e9;
}

.ava-promo-img img {
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: contain;
}

.ads-pic {
  height: 332px;
}

.product-sec {
  height: 342px;
}

.ads-pic img {
  height: 50%;
  width: 50%;
}

.pic-todaypromo img {
  width: 100%;
}

/**/

.header {
  position: relative;
  z-index: 2;
  display: flex;
}

/*	.header__catalog {
        width: 250px;
        height: 64px;
        }*/

.catalog {
  position: relative;
}

.catalog__dropdown {
  height: inherit;
  /*			background-color: #1d5987;*/
  padding: 8px 1px;
  border-radius: 5px;
}

.catalog__dropdown:hover {
  opacity: 0.85;
  background-color: #254a6f;
}

.catalog__dropdown-link {
  display: flex;
  height: inherit;
  padding: 0 15px;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  align-items: center;
  justify-content: center;
}

.catalog__dropdown-link:hover {
  color: #fff;
  text-decoration: none;
}

.catalog__dropdown-link::before,
.catalog__dropdown-link::after {
  content: "";
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;
}

.catalog__dropdown-link::before {
  content: "";
  width: 24px;
  height: 18px;
  margin-right: 4px;
}

.catalog__popover {
  position: absolute;
  z-index: 1;
  top: 100%;
  display: none;
  /*width: 100%;*/
  width: 210px;
  border-radius: 0 0 2px 2px;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.catalog__popover.is-visible {
  display: block;
}

.catalog__popover-list {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.catalog__popover-link {
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: block;
  padding: 8px 16px;
  text-decoration: none;
  color: #333333;
}

.catalog__popover-link--view-all {
  border-top: 1px solid #e2e2e2;
}

.catalog__popover-popup {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: calc(100% - 1px);
  display: none;
  width: 210px;
  height: 100%;
  border-radius: 0 0 2px 2px;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.catalog__popover-popup .catalog__popover-list {
  overflow-y: auto;
  height: 100%;
  border-radius: 0 0 0 2px;
}

.catalog__popover-item.is-hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.has-child.is-hover > .catalog__popover-popup {
  display: block;
}

.fade-page {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 0.6;
  display: none;
  background-color: #000000;
  transition: opacity 1s ease-out;
}

.fade-page.is-active {
  display: block;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

.size-icon {
  height: 21px;
}
</style>
