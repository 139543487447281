<template>
  <div>
    <flickity
      v-if="produkMaster.length > 0"
      ref="flickity"
      class="flickity"
      :options="flickityOptions"
    >
      <div
        class="carousel-cell wd-15 block2 bg0"
        v-for="(item, index) in produkMaster"
        :key="index"
      >
        <div
          class="block2-pic hov-img0 text-center"
          :class="{ 'label-new': item.discount > 0 }"
          :data-label="item.discount + '%'"
        >
          <!-- <div class="label-original badge badge-primary">100% Original</div> -->
          <img
            class="img-official"
            src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
          />
          <div>
            <img
              class="img-responsive"
              :src="
                'https://cdn.eurekabookhouse.co.id/ebh/product/all/' +
                item.productImg
              "
              :style="{
                'max-width': '100%',
                height: 'auto',
                cursor: 'pointer',
                opacity: item.quantity === 0 ? 0.2 : 1,
              }"
              alt=""
              @error="handleImageError"
              @click="item.quantity > 0 && handleProductClick(item)"
            />

            <div
              v-if="item.quantity === 0"
              class="sold-out-label"
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding: 8px;
                font-size: 20px;
                color: rgb(119, 119, 119);
                border-radius: 5px;
              "
            >
              Habis
            </div>
          </div>
        </div>
        <div class="block2-txt flex-w flex-t p-all-8">
          <div class="block2-txt-child1 flex-col-l">
            <div
              @click="handleProductClick(item)"
              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
              style="
                color: black;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
              "
            >
              {{ item.productName }}
            </div>

            <div class="stext-105">
              <strong
                class="cl13"
                style="
                  color: var(--neutral-04, #989898);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 13.2px; /* 110% */
                  letter-spacing: -0.12px;
                  text-decoration: line-through;
                  cursor: pointer;
                "
              >
                Rp
                {{
                  item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </strong>
            </div>
            <div class="stext-105">
              <strong
                class="cl13"
                style="
                  color: var(--primary-05, #244786);
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 18px; /* 112.5% */
                  letter-spacing: -0.16px;
                  cursor: pointer;
                "
              >
                Rp
                {{
                  (item.price - item.price * (item.discount / 100))
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                }}
              </strong>
            </div>
          </div>
        </div>
      </div>
    </flickity>
  </div>
</template>

<script>
import Vue from "vue";
import numeral from "numeral";
import Flickity from "vue-flickity";
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "cardKesehatanPage",
  components: {
    Flickity,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        cellAlign: "left",
        wrapAround: false,
        freeScroll: false,
      },
      produkMaster: [], // Variable to store product data
    };
  },
  mounted() {
    this.getProduk();
  },

  methods: {
    handleProductClick(item) {
      // Dispatch an action to set the product_id in the Vuex store
      this.$store.dispatch("setProductId", item.product_id);

      // Navigate to the detail page
      this.$router.push({
        name: "detailprodukPage",
        params: { slug: item.slug },
      });
    },
    handleImageError(event) {
      event.target.src =
        "https://sandbox.eurekabookhouse.co.id/img/ebh.812ea2ce.png";
    },
    getProduk() {
      // ALAMANDA
      const apiUrl =
        "https://stagingapi.eurekabookhouse.co.id/product/kesehatan";

      // PUSAT
      // const apiUrl = "http://192.168.0.233:3000/product/kesehatan";

      axios
        .get(apiUrl)
        .then((response) => {
          // Check response status

          if (response.status === 200) {
            // Handle the response from the server
            const data = response.data.data.order;
            this.produkMaster = data;
          } else {
            // Handle unsuccessful response status here
            console.error(`Response failed, status: ${response.status}`);
            // Show a SweetAlert for failed response status
            Swal.fire({
              confirmButtonColor: "#3860A8",
              icon: "error",
              title: "Failed to Fetch Product Data",
              text: "An error occurred while fetching product data. Please try again.",
            });
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error(error);

          // Show a SweetAlert for errors
          Swal.fire({
            confirmButtonColor: "#3860A8",
            icon: "error",
            title: "Failed to Fetch Product Data",
            text: "An error occurred while fetching product data. Please try again.",
          });
        });
    },
  },
};
</script>
