<template>
  <div>
     <div>
       <section
         class="bg-img1 txt-center p-lr-15 p-t-50 p-b-30"
         style="
           /*background-image:url('');*/
           background-repeat: no-repeat;
           background-size: 100%;
           background-color: #f4d747;
         "
       >
         <h2 class="ltext-105 cl5 txt-center">Kebijakan Privasi</h2>
         <h5>Kebijakan Privasi Eurekabookhouse</h5>
       </section>
       <section class="bg0 p-t-75 p-b-120">
         <div class="container">
           <div class="row p-b-50">
             <div class="stext-105 m-b-30 text-left">
               <!-- Pendahuluan -->
               <h3>1. Pendahuluan</h3>
               <br>
               <p>
                 1.1 Selamat datang di platform Eureka BookHouse ("Situs"). Silakan baca Syarat Layanan berikut
 dengan cermat dan teliti sebelum menggunakan situs ini atau membuka akun di Eureka
 BookHouse ("Akun") agar Anda mengetahui tentang hak dan kewajiban hukum Anda
 sehubungan dengan PT Eureka BookHouse (masing-masing dan bersama-sama, "Eureka
 BookHouse" atau "kami"). "Layanan" yang kami berikan atau sediakan termasuk (a) Situs, (b)
 layanan yang disediakan oleh Situs dan oleh perangkat lunak klien Eureka BookHouse yang
 tersedia melalui Situs, dan (c) semua informasi, halaman tertaut, fitur, data, teks, gambar, foto,
 grafik, musik, suara, video, pesan, tag, konten, pemrograman, perangkat lunak, layanan
 aplikasi (termasuk, dengan tidak terbatas pada, setiap layanan aplikasi mobile) atau materi
 lainnya yang tersedia melalui Situs atau layanan terkait ("Konten"). Setiap fitur baru yang
 ditambahkan atau memperbesar Layanan juga tunduk pada Syarat Layanan ini. Syarat
 Layanan ini mengatur penggunaan Layanan yang disediakan oleh Eureka BookHouse.
               </p>
               <br>
               <p>
                 1.2 Layanan termasuk layanan platform online yang menyediakan tempat dan peluang untuk
 penjualan barang antara pembeli ("Pembeli") dan penjual ("Penjual") (secara bersama-sama
 disebut “anda”, "Pengguna" atau "Para Pihak"). Kontrak penjualan yang sebenarnya adalah
 secara langsung antara Pembeli dan Penjual dan Eureka BookHouse bukan merupakan pihak
 di dalamnya atau setiap kontrak lainnya antara Pembeli dan Penjual serta tidak bertanggung
 jawab sehubungan dengan kontrak tersebut. Para Pihak dalam transaksi tersebut akan
 sepenuhnya bertanggung jawab untuk kontrak penjualan antara mereka, daftar barang, garansi
 pembelian dan sebagainya. Eureka BookHouse tidak terlibat dalam transaksi antara Pengguna.
 Eureka BookHouse dapat atau tidak dapat melakukan penyaringan awal terhadap Pengguna
 atau Konten atau informasi yang diberikan oleh Pengguna. Eureka BookHouse berhak untuk
 menghapus setiap Konten atau informasi yang diposting oleh Anda di Situs sesuai
 dengan Bagian 6.4 di sini.
               </p>
               <br>
               <p>
                 1.3 Sebelum menjadi Pengguna Situs, Anda harus membaca dan menerima semua syarat dan
 ketentuan dalam, dan yang berkaitan dengan, Syarat Layanan ini dan Anda harus memberikan
 persetujuan atas pemrosesan data pribadi Anda sebagaimana dijelaskan dalam Kebijakan
 Privasi yang terkait dengan Syarat Layanan ini.
               </p>
               <br>
               <p>
                 1.4 Eureka BookHouse berhak untuk mengganti, mengubah, menangguhkan atau menghentikan
 semua atau bagian apapun dari Situs ini atau Layanan setiap saat atau setelah memberikan
 pemberitahuan sebagaimana dipersyaratkan oleh undang-undang dan peraturan setempat.
 Eureka BookHouse dapat meluncurkan Layanan tertentu atau fitur tertentu dalam versi beta,
 yang mungkin tidak berfungsi dengan baik atau sama seperti versi akhir, dan kami tidak
 bertanggung jawab dalam hal demikian. Eureka BookHouse juga dapat membatasi fitur tertentu
 atau membatasi akses Anda ke bagian atau seluruh Situs atau Layanan atas kebijakannya
 sendiri dan tanpa pemberitahuan atau kewajiban.
               </p>
               <br>
               <p>
                 1.5 Eureka BookHouse berhak untuk menolak memberikan akses ke Situs atau Layanan kepada
                 Anda atau mengizinkan Anda untuk membuka Akun dengan alasan apapun.
               </p>
               <br>
               <p>
                 DENGAN MENGGUNAKAN LAYANAN EUREKA BOOKHOUSE ATAU MEMBUKA AKUN,
 ANDA MEMBERIKAN PENERIMAAN DAN PERSETUJUAN YANG TIDAK DAPAT DICABUT
 ATAS PERSYARATAN PERJANJIAN INI, TERMASUK SYARAT DAN KETENTUAN
 TAMBAHAN SERTA KEBIJAKAN YANG DISEBUTKAN DI SINI DAN/ATAU TERKAIT DI SINI.
               </p>
               <br>
               <p>
                 APABILA ANDA TIDAK MENYETUJUI KETENTUAN INI, MOHON JANGAN MENGGUNAKAN
                 LAYANAN KAMI ATAU MENGAKSES SITUS. 
               </p>
               <br>
               <h3>2. Privasi</h3>
               <br>
               <p>
                 Di Eureka BookHouse, privasi Anda sangatlah penting. Untuk melindungi hak-hak Anda dengan lebih
 baik, silakan baca Kebijakan Privasi untuk memahami bagaimana Eureka BookHouse
 mengumpulkan dan menggunakan informasi yang berkaitan dengan Akun Anda dan/atau
 penggunaan Layanan oleh Anda (“Informasi Pengguna”). Dengan menggunakan Layanan atau
 memberikan informasi di Situs, Anda:
               </p>
               <br/>
               <p>
               (a) menyetujui dan mengakui bahwa hak kepemilikan atas Informasi Pengguna dimiliki secara
               bersama oleh Anda dan Eureka BookHouse; 
               </p>
               <p>
               (b) menyetujui tindakan Eureka BookHouse untuk mengumpulkan, menggunakan,
 mengungkapkan dan/atau mengolah Konten, data pribadi dan Informasi Pengguna Anda
 sebagaimana dijelaskan dalam Kebijakan Privasi; dan
 
               </p>
               <p>
               (c) tidak akan, baik secara langsung maupun tidak langsung, mengungkapkan Informasi
 Pengguna Anda kepada setiap pihak ketiga, atau sebaliknya memperbolehkan setiap pihak
 ketiga untuk mengakses atau menggunakan Informasi Pengguna Anda, tanpa persetujuan
 tertulis sebelumnya dari Eureka BookHouse.
               </p>
               <br>
               <!-- Informasi yang Kami Kumpulkan -->
               <h3>3. IZIN TERBATAS</h3>
               <br>
               <p>
                 3.1  Eureka BookHouse memberikan izin terbatas yang dapat dicabut kembali kepada Anda untuk
 mengakses Layanan dengan tunduk pada syarat dan ketentuan Syarat Layanan ini. Semua
 Konten eksklusif, merek dagang, merek layanan, nama merek, logo dan kekayaan intelektual
 lainnya ("Kekayaan Intelektual") yang ditampilkan di Situs merupakan milik Eureka BookHouse.
 Tidak ada hak atau izin yang diberikan secara langsung atau tidak langsung kepada pihak
 mana pun yang mengakses Situs untuk menggunakan atau memperbanyak Kekayaan
 Intelektual, dan tidak ada pihak yang mengakses Situs yang dapat mengklaim hak, kepemilikan
 atau kepentingan apapun di dalamnya. Dengan menggunakan atau mengakses Layanan Anda
 setuju untuk mematuhi hak cipta, merek dagang, merek layanan, yang berlaku lainnya yang
 melindungi Layanan, Situs dan Kontennya. Anda setuju untuk tidak menyalin, mendistribusikan,
 mempublikasikan ulang, mengirimkan, menampilkan secara terbuka, melakukan secara
 terbuka, mengubah, menyesuaikan, menyewa, menjual, atau membuat karya turunan dari
 bagian apapun dari Layanan, Situs atau Kontennya. Anda juga tidak boleh, tanpa persetujuan
 tertulis sebelumnya dari kami, me-mirror atau membingkai bagian apapun atau seluruh konten
 Situs ini di server lain mana pun atau sebagai bagian dari situs web lain apapun. Selain itu,
 Anda setuju bahwa Anda tidak akan menggunakan robot, spider atau perangkat otomatis
 maupun proses manual lain apapun untuk memantau atau menyalin Konten kami, tanpa
 persetujuan tertulis sebelumnya dari kami (persetujuan tersebut dianggap diberikan untuk teknologi mesin pencari standar yang digunakan oleh situs web pencari Internet guna
 mengarahkan pengguna Internet ke situs web ini).
               </p>
               <br>
               <p>
                 3.2  Anda boleh membuat tautan ke Situs dari situs web Anda, asalkan situs web Anda tidak
 menyiratkan adanya dukungan oleh atau asosiasi dengan Eureka BookHouse. Anda mengakui
 bahwa atas kebijakannya sendiri dan setiap saat Eureka BookHouse dapat berhenti
 menyediakan Layanan, baik sebagian atau secara keseluruhan, tanpa pemberitahuan. </p>
               <br>
               <h3>4. PERANGKAT LUNAK              </h3>
               <br>
               <p>
                 Setiap perangkat lunak yang disediakan oleh kami kepada Anda sebagai bagian dari Layanan
 tunduk pada ketentuan Syarat Layanan ini. Eureka BookHouse mencadangkan semua hak
 atas perangkat lunak yang tidak secara tegas diberikan oleh Eureka BookHouse dalam Syarat
 Layanan ini. Setiap skrip atau kode pihak ketiga yang tertaut atau dirujuk dari Layanan
 dilisensikan kepada Anda oleh pihak ketiga yang memiliki skrip atau kode tersebut, bukan oleh
 Eureka BookHouse.
 </p>
               <br>
            
 
               <!-- Pengungkapan Informasi -->
               <h3>5. AKUN DAN KEAMANAN
               </h3>
               <br/>
               <p>
                 Kami tidak akan membagikan informasi pribadi Anda kepada pihak ketiga tanpa persetujuan Anda, kecuali sebagaimana diatur dalam kebijakan privasi ini atau sebagaimana diwajibkan oleh hukum yang berlaku. Kami dapat mengungkapkan informasi Anda kepada:
                 <ul>
                   <br/> 
                   <li> 5.1  Apabila Anda memilih ID Pengguna yang dianggap tidak sopan atau tidak pantas oleh Eureka
 BookHouse atas kebijakannya sendiri, Eureka BookHouse berhak untuk menangguhkan atau
 mengakhiri Akun Anda. </li>
 <br/>
                   <li>5.2 Anda setuju untuk (a) menjaga kerahasiaan kata sandi Anda dan hanya menggunakan ID
 Pengguna dan kata sandi Anda saat login, (b) memastikan bahwa Anda keluar dari akun Anda
 pada akhir setiap sesi di Situs, (c) segera memberi tahu Eureka BookHouse tentang
 penggunaan tanpa izin atas Akun, ID Pengguna dan/atau kata sandi Anda, dan (d) memastikan
 bahwa informasi Akun Anda akurat dan terkini. Anda sepenuhnya bertanggung jawab untuk
 semua kegiatan yang dilakukan dengan menggunakan ID Pengguna dan Akun Anda bahkan
 bila kegiatan atau penggunaan tersebut tidak dilakukan oleh Anda. Eureka BookHouse tidak
 akan bertanggung jawab untuk setiap kerugian atau kerusakan yang timbul dari penggunaan
 tanpa izin atas kata sandi Anda atau kegagalan Anda untuk mematuhi Bagian ini.
 </li>
 <br/>
 <li>5.3 Eureka BookHouse dapat mengakhiri Akun dan ID Pengguna anda serta menghapus atau
 membuang setiap Konten yang berkaitan dengan Akun dan ID Pengguna Anda dari Situs.
 Dasar untuk pengakhiran tersebut dapat termasuk, tetapi tidak terbatas pada,
 <br/>
 <p>(a) atas permintaan Anda</p>
 <p>(b) pelanggaran terhadap ketentuan atau semangat Syarat Layanan ini,
 </p>
 <p>(c) perilaku yang ilegal, menipu, melecehkan, memfitnah, mengancam atau kasar,</p>
 <p>(d) memiliki beberapa akun pengguna untuk alasan yang tidak sah,
 </p>
 <p>(e) perilaku yang merugikan Pengguna lain, pihak ketiga, atau kepentingan bisnis Eureka
 BookHouse
 </p>
 </li>
 <br/>
 <li>5.4 Pengguna dapat menghapus Akun mereka jika mereka memberi tahu Eureka BookHouse
   secara tertulis (termasuk melalui email di eurekabookhouse@gmail.com) tentang niatan mereka tersebut. Terlepas dari adanya penghapusan tersebut, Pengguna tetap bertanggung
 jawab dan berkewajiban untuk setiap transaksi yang belum selesai (baik apakah dimulai
 sebelum atau setelah penghapusan tersebut), pengiriman produk, pembayaran untuk produk,
 atau hal semacam itu, dan Pengguna harus menghubungi Eureka BookHouse setelah ia telah
 melakukan dan menyelesaikan semua transaksi yang belum selesai dengan segera dan efektif
 sesuai dengan Syarat Layanan. Eureka BookHouse tidak akan memiliki kewajiban, dan tidak
 akan bertanggung jawab atas setiap kerugian yang terjadi akibat tindakan yang diambil sesuai
 dengan bagian ini. Pengguna melepaskan setiap dan semua klaim berdasarkan tindakan
 semacam itu yang diambil oleh Eureka BookHouse.
 <br/>
 </li>
                 </ul>
               </p>
               <br/>
               <h3>6. PERSYARATAN PENGGUNAAN
               </h3>
               <br/>
               <p> 6.1 
                 Izin untuk menggunakan Situs dan Layanan ini berlaku sampai diakhiri. Izin ini akan berakhir
 sebagaimana diatur dalam Syarat Layanan ini atau jika Anda gagal mematuhi persyaratan atau
 ketentuan apapun dari Syarat Layanan ini. Dalam hal demikian, Eureka
 BookHouse dapat melakukan pengakhiran tersebut dengan atau tanpa memberikan
 pemberitahuan kepada Anda.
               </p>
 <br/>
 <span> 6.2 
   Anda setuju untuk tidak:
   <div class="ml-4">   <br/> <p>(a) memposting, mengunggah, memposting, mengirimkan atau menyediakan Konten yang
 melanggar hukum, berbahaya, mengancam, kasar, melecehkan, mengkhawatirkan,
 meresahkan, berliku-liku, memfitnah, vulgar, cabul, mencemarkan, invasif terhadap privasi
 pihak lain, penuh kebencian, atau mengandung unsur SARA atau lainnya;</p>
 <br/>
 <p>(b) menggunakan Layanan untuk tujuan menipu;</p>
   <br/>
   <p>(c) memanipulasi harga barang apapun atau mengganggu daftar Pengguna lain;
   </p>
   <br/>
   <p>(d) melanggar undang-undang, termasuk dengan tidak terbatas pada undang-undang dan
 peraturan sehubungan dengan batasan ekspor dan impor, hak pihak ketiga atau
 kebijakan Barang yang Dilarang dan Dibatasi kami;
   </p>
   <br/>
   <p>(e) mengunggah, mengirim email, memposting, mengirimkan atau menyediakan iklan, materi
 promosi yang tidak diinginkan atau tidak sah, "surat sampah", "spam", atau bentuk ajakan
 lainnya yang tidak sah;
 
   </p>
   <br/>
   <p>(f) melakukan tindakan atau terlibat dalam perilaku yang secara langsung atau tidak langsung
 dapat merusak, melumpuhkan, membebani secara berlebihan, atau mengganggu Layanan
 atau server atau jaringan yang terhubung ke Layanan;
 
 
   </p>
   <br/>
   <p>(g) mengunggah, mengirim email, memposting, mengirimkan atau menyediakan Konten
 apapun yang melanggar hak paten, merek dagang, rahasia dagang, hak cipta atau hak
 kepemilikan lainnya dari pihak mana pun;
 
 
   </p>
   <br/>
   <p>(g) mengunggah, mengirim email, memposting, mengirimkan atau menyediakan Konten
 apapun yang melanggar hak paten, merek dagang, rahasia dagang, hak cipta atau hak
 kepemilikan lainnya dari pihak mana pun;
 
 
   </p>
   <br/>
   <p>(h) menggunakan Layanan untuk melanggar privasi pihak lainnya atau untuk "menguntit" atau
 mengganggu pihak lain;
 </p>
 <br/>
   <p>(i) mendaftarkan barang yang melanggar hak cipta, merek dagang atau hak kekayaan
 intelektual pihak ketiga lainnya atau menggunakan layanan dengan cara yang akan
 melanggar hak kekayaan intelektual pihak lain;</p>
 <br/>
   <p>(j) memungut atau mengumpulkan informasi apapun tentang atau mengenai pemegang Akun
     lain, termasuk, dengan tidak terbatas pada, data atau informasi pribadi apapun;</p>
   <br/>
     <p>(k) memengaruhi, memanipulasi atau mengganggu Layanan atau server atau jaringan yang
 terhubung ke Layanan atau penggunaan dan kenyamanan menikmati Layanan oleh
 Pengguna lainnya, atau tidak menaati persyaratan, prosedur, kebijakan atau peraturan
 jaringan yang terhubung ke Situs;
 </p></div>
 <br/>
   <p>6.3 Anda memahami bahwa semua Konten, baik yang diposting untuk umum atau dikirimkan
 secara pribadi, merupakan tanggung jawab tunggal orang dari mana Konten tersebut berasal.
 Ini berarti Anda, dan bukan Eureka BookHouse, bertanggung jawab penuh untuk semua
 Konten yang Anda unggah, posting, kirim melalui email, kirimkan atau sediakan melalui Situs.
 Anda memahami bahwa dengan menggunakan Situs ini. </p>
 <br/>
 <span>6.4 Anda mengakui bahwa Eureka BookHouse dan pihak yang ditunjuk olehnya memiliki hak
 (tetapi bukan kewajiban) atas kebijakan mereka sendiri untuk melakukan penyaringan awal,
 menolak, menghapus atau memindahkan Konten, termasuk dengan tidak terbatas pada setiap
 Konten atau informasi yang diposting oleh Anda, yang tersedia di Situs. Tanpa membatasi
 ketentuan di atas, Eureka BookHouse dan pihak yang ditunjuk olehnya berhak untuk
 menghapus Konten
 <div class="ml-4">
   <br/>
   <p>(i) yang melanggar Syarat Layanan ini;
   </p>
   <br/>
   <p>(ii) jika kami menerima keluhan dari Pengguna lain;</p>
   <br/>
   <p>(ii) jika kami menerima keluhan dari Pengguna lain;</p>
   <br/>
   <p>(iv) jika Konten tersebut tidak pantas. Kami juga dapat memblokir pengiriman komunikasi
 (termasuk, dengan tidak terbatas pada, pembaruan status, postingan, pesan dan/atau obrolan)
 ke atau dari Layanan sebagai bagian dari upaya kami melindungi Layanan atau Pengguna
 kami, atau menegakkan ketentuan-ketentuan dalam Syarat dan Ketentuan ini. Anda setuju
 bahwa Anda harus mengevaluasi, dan menanggung semua risiko yang berkaitan dengan
 penggunaan Konten, termasuk, dengan tidak terbatas pada, kepercayaan pada keakuratan,
 kelengkapan, atau kegunaan Konten tersebut. </p>
 </div>
 </span>
   </span>
   <br/>
 <span>6.5 Anda mengakui, mengizinkan dan setuju bahwa Eureka BookHouse dapat mengakses,
 menyimpan dan mengungkapkan informasi Akun dan Konten Anda jika tersebut memang wajar
 diperlukan untuk:
 
 <div class="ml-4">
   <br/>
   <p>(a) menegakkan Syarat Layanan ini;
   </p>
   <br/>
   <p>(b) menanggapi klaim bahwa Konten melanggar hak pihak ketiga;
   </p>
   <br/>
   <p>(c) menanggapi permintaan Anda untuk layanan pelanggan; </p>
   <br/>
   <p>(d) melindungi hak, milik atau keselamatan pribadi Eureka BookHouse, Penggunanya dan/atau
 masyarakat; atau
  </p>
  <br/>
  <p>(e) mematuhi proses hukum.</p>
 </div>
 </span>
 <br/>
               <h3>7. PELANGGARAN TERHADAP SYARAT LAYANAN KAMI
               </h3>
               <br/>
 <span>Pelanggaran terhadap kebijakan ini dapat mengakibatkan berbagai tindakan, termasuk, dengan tidak
 terbatas pada, salah satu atau semua dari hal-hal berikut ini:
 
 
 <div class="ml-4">
   <br/>
   <p>- Penghapusan daftar
   </p>
   <br/>
   <p>- Batasan diberlakukan pada hak Akun
   </p>
   <br/>
   <p>- Penangguhan dan pengakhiran akun  </p>
   <br/>
   <p>- Tuntutan pidana
  </p>
  <br/>
  <p>- Tindakan perdata, termasuk dengan tidak terbatas pada klaim untuk kerugian dan/atau ganti
  rugi sementara atau perintah ganti rugi oleh pengadilan.
 </p>
 </div>
 </span>
 <br/>
               <h3>8. MELAPORKAN PELANGGARAN HAK KEKAYAAN INTELEKTUAL
               </h3>
               <br/>
               <p>8.1 Pengguna adalah individu atau bisnis independen dan mereka tidak mempunyai hubungan
 dengan Eureka BookHouse dalam cara apapun. Eureka BookHouse bukanlah agen atau
 perwakilan dari Pengguna dan tidak memegang dan/atau memiliki barang yang tercantum di
 Situs.
 </p>
               <p>8.1 Pengguna adalah individu atau bisnis independen dan mereka tidak mempunyai hubungan
 dengan Eureka BookHouse dalam cara apapun. Eureka BookHouse bukanlah agen atau
 perwakilan dari Pengguna dan tidak memegang dan/atau memiliki barang yang tercantum di
 Situs.
 </p>
 <span>Klaim berdasarkan Bagian 8 ini harus mencakup sekurang-kurangnya hal berikut:
 
 
 <div class="ml-4">
 
   <br/>
   <p>(a) penjelasan tentang jenis dan sifat hak kekayaan intelektual yang diduga dilanggar;
 
   </p>
   <br/>
   <p>(b) rincian dari daftar yang berisi dugaan pelanggaran;
   </p>
   <br/>
   <p>(c) tanda tangan fisik atau elektronik Pemilik HAKI atau Agen HAKI (secara bersama-sama
     disebut “Informan”); </p>
   <br/>
   <p>(d) informasi yang cukup untuk memperbolehkan Eureka BookHouse menghubungi Informan,
     seperti alamat fisik, nomor telepon dan alamat e-mail Informan;
  </p>
  <br/>
  <p>- Tindakan perdata, termasuk dengan tidak terbatas pada klaim untuk kerugian dan/atau ganti
  rugi sementara atau perintah ganti rugi oleh pengadilan.
 </p>
 </div>
 </span>
 <br/>
               <h3>9. PEMBELIAN DAN PEMBAYARAN
               </h3>
               <br/>
             
 <span>9.1 Eureka BookHouse mendukung satu atau lebih metode pembayaran berikut:
 
 
 
 <div class="ml-4">
 
   <br/>
   <span>(i) Kartu kredit
     <br/>
 
 <p>Pembayaran dengan kartu diproses melalui saluran pembayaran pihak ketiga dan jenis
 kartu kredit yang diterima oleh saluran pembayaran ini mungkin bervariasi;</p>
 </span>
   <br/>
   <span>(ii) Transfer Bank
     <br/>
 
 <p>Pembeli dapat melakukan pembayaran melalui Anjungan Tunai Mandiri (ATM) atau
   transfer bank via internet (“Transfer Bank”) ke Rekening kami yang ditunjuk. Pembeli harus memberikan bukti transfer atau referensi transaksi pembayaran kepada Eureka
 BookHouse untuk tujuan verifikasi melalui fungsi 'Unggah Bukti Pembayaran' yang dapat
 ditemukan di platform Eureka BookHouse sebagai konfirmasi pembayaran. Apabila
 konfirmasi pembayaran tidak diterima Eureka BookHouse dalam tiga (3) hari, pesanan
 Pembeli akan dibatalkan;
 </p>
 </span>
 <span>(iii) Metode Pembayaran
     <br/>
 
 <p>metode pembayaran lainnya sebagaimana ditampilkan di Situs.
 </p>
 </span>
   <br/>
 </div>
 </span>              <p>9.2 Pembeli boleh mengganti mode pembayaran pilihan untuk pembelian mereka hanya sebelum
 melakukan pembayaran.
 
 </p>
 <br/>
 <p>9.3 Eureka BookHouse tidak bertanggung jawab dan tidak memiliki kewajiban apapun untuk setiap
 kerugian atau kerusakan terhadap Pembeli yang timbul dari informasi pengiriman dan/atau
 informasi pembayaran yang dimasukkan oleh Pembeli atau pengiriman uang yang salah oleh
 Pembeli sehubungan dengan pembayaran untuk barang yang dibeli. Kami berhak untuk
 mengecek apakah Pembeli berwenang untuk menggunakan metode pembayaran tertentu, dan
 dapat menangguhkan transaksi sampai otorisasi tersebut dikonfirmasi atau membatalkan
 transaksi yang bersangkutan bila konfirmasi tersebut tidak tersedia.
 
 
 </p>
 <br/>
 <p>9.3 Eureka BookHouse tidak bertanggung jawab dan tidak memiliki kewajiban apapun untuk setiap
 kerugian atau kerusakan terhadap Pembeli yang timbul dari informasi pengiriman dan/atau
 informasi pembayaran yang dimasukkan oleh Pembeli atau pengiriman uang yang salah oleh
 Pembeli sehubungan dengan pembayaran untuk barang yang dibeli. Kami berhak untuk
 mengecek apakah Pembeli berwenang untuk menggunakan metode pembayaran tertentu, dan
 dapat menangguhkan transaksi sampai otorisasi tersebut dikonfirmasi atau membatalkan
 transaksi yang bersangkutan bila konfirmasi tersebut tidak tersedia.
 
 
 </p>
 <br/>
 <p>9.4 Pada saat ini, Eureka BookHouse hanya dapat melakukan pembayaran kepada Pengguna
 melalui bank transfer. Oleh karena itu, Pengguna diminta untuk memberikan rincian rekening
 bank Pengguna kepada Eureka BookHouse untuk menerima pembayaran yaitu dari penjualan
 barang atau pengembalian dana dari Eureka BookHouse.</p>
 
 <br/>
 <h3>11. PENARIKAN DAN PENYESUAIAN SALDO
               </h3>
               <br/>
               <p>11.1 Anda dapat mentransfer dana dari Saldo Penjual Anda dengan mentransfer dana ke Rekening
 Tertaut Anda dengan mengirimkan permintaan transfer (“Permintaan Penarikan”) dalam
 frekuensi harian yang wajar sebagaimana ditentukan oleh Eureka BookHouse atau maksimal
 sekali per hari untuk Saldo Penjual. Eureka BookHouse dapat juga secara otomatis
 mentransfer dana dari Saldo Penjual Anda ke Rekening Tertaut Anda secara regular,
 sebagaimana ditentukan oleh Eureka BookHouse. Transfer tersebut mungkin membutuhkan
 waktu hingga tiga hari kerja, atau periode lain yang ditentukan oleh bank penerima, untuk
 dikreditkan ke Rekening Tertaut Anda.</p>
 
 <br/>
 <p>11.2 Setelah diajukan, Anda tidak dapat mengubah atau membatalkan Permintaan Penarikan</p>
 
 <br/>
 <h3>12. SISTEM PENGHARGAAN EUREKA KOIN
 
               </h3>
               <br/>
               <p>12.1 Eureka Koin tidak memiliki nilai moneter, bukan merupakan properti Anda dan tidak dapat
                 dibeli, dijual, dialihkan atau ditukar dengan uang;</p>
 
 <br/>
 <p>12.2 Pengguna dapat mendapatkan “Eureka Koin” dengan membeli barang di Situs atau dengan
 berpartisipasi dalam kegiatan Eureka BookHouse lainnya sebagaimana dapat ditentukan dari
 waktu ke waktu oleh Eureka BookHouse berdasarkan nilai konversi yang ditentukan oleh
 Eureka BookHouse atas kebijakannya sendiri. Umumnya, Eureka Koin akan dikreditkan ke
 Akun Pengguna pada saat penyelesaian transaksi yang berhasil atau aktivitas yang disetujui
 oleh Eureka BookHouse;</p>
   <br/>
 <p>12.3 Transaksi yang tidak diselesaikan di Situs tidak memenuhi syarat untuk sistem penghargaan
 Eureka Koin. Eureka BookHouse dapat dengan kebijakannya sendiri mengecualikan kategorikategori barang dari sistem penghargaan Eureka Koin;
 </p>
 <br/>
 <p>12.4 Setiap Eureka Koin dilengkapi dengan tanggal kadaluarsa. Mohon untuk memeriksa rincian
   akun Anda di Situs untuk saldo dan tanggal kadaluarsa Eureka Koin;
 </p>
 <br/>
 <p>12.5 Eureka BookHouse berhak untuk (i) menghentikan Sistem Eureka Koin setiap waktu atas
 kebijakannya sendiri dan (ii) membatalkan atau menangguhkan hak Pengguna untuk
 berpartisipasi dalam Eureka Koin, termasuk kemampuan untuk mendapatkan dan menukarkan
 Eureka Koin atas kebijakannya sendiri.
 </p>
 <br/>
 <h3>13. PENGIRIMAN
 
 </h3>
 <br/>
 <p>13.1 Penjual harus melakukan upaya terbaiknya untuk memastikan Pembeli menerima barang yang
 dibeli selama batas waktu yang ditentukan oleh Penjual
 </p>
 
 <br/>
 <p>13.2 Eureka BookHouse akan memberi tahu Penjual kapan Eureka BookHouse menerima Uang
 Pembelian Pembeli. Penjual kemudian harus melakukan pengaturan yang diperlukan untuk
 mengirimkan barang yang dibeli kepada Pembeli dan memberikan rincian seperti nama
 perusahaan pengiriman, nomor pelacakan, dsb. kepada Pembeli melalui Situs.</p>
 <br/>
 <p>13.3 Pengguna memahami bahwa Penjual menanggung seluruh resiko yang melekat pada
 pengiriman barang(-barang) yang dibeli dan menjamin bahwa Penjual memiliki atau akan
 memperoleh cakupan asuransi yang memadai untuk pengiriman barang(-barang) yang dibeli.
 Dalam hal dimana barang(-barang) yang dibeli rusak, hilang atau mengalami kegagalan
 pengiriman selama masa pengiriman, Pengguna mengakui dan setuju bahwa Eureka
 BookHouse tidak akan bertanggung jawab atas kerusakan, pengeluaran, biaya atau ongkos
 yang timbul dari hal tersebut dan Penjual dan/atau Pembeli akan membicarakan hal tersebut
 dengan penyedia jasa logistik untuk menyelesaikan permasalahan tersebut.
 </p>
 <br/>
 <h3>14. PEMBATALAN, PENGEMBALIAN BARANG DAN UANG
 </h3>
 <br/>
 <p>14.1 Pembeli hanya boleh membatalkan pesanannya sebelum pembayaran Uang Pembelian
 Pembeli ke Eureka BookHouse.
 
 </p>
 
 <br/>
 <p>14.2 Pembeli dapat mengajukan permohonan pengembalian barang yang dibeli dan pengembalian
 dana sebelum berakhirnya masa yang sudah ditentukan Eureka BookHouse, bilamana berlaku,
 dengan tunduk dan sesuai dengan Kebijakan Pengembalian Dana dan Barang Eureka
 BookHouse. </p>
 <br/>
 <p>14.3 Eureka BookHouse berhak untuk membatalkan transaksi apapun di Situs dan Pembeli setuju
 bahwa satu-satunya upaya perbaikan yang dimiliki oleh Pembeli adalah menerima
 pengembalian dana dari Uang Pembelian Pembeli yang dibayarkan ke Eureka BookHouse
 </p>
 <br/>
 <p>14.4 Eureka BookHouse tidak memantau proses pembatalan, pengembalian barang dan uang untuk
 pembayaran offline.
 </p>
 <br/>
 <h3>15. TANGGUNG JAWAB PENJUAL
 
 </h3>
 <br/>
 <p>15.1 Penjual harus mengelola dengan baik dan memastikan bahwa informasi relevan seperti harga
 dan rincian barang, jumlah persediaan serta syarat dan ketentuan penjualannya diperbarui
 pada daftar Penjual dan tidak boleh memposting informasi yang tidak akurat atau
 menyesatkan.
 
 </p>
 
 <br/>
 <p>15.2 Penjual setuju bahwa atas kebijakannya Eureka BookHouse dapat terlibat dalam kegiatan
 promosi untuk mendorong transaksi antara Pembeli dan Penjual dengan mengurangi,
 memotong atau mengembalikan biaya, atau dengan cara lain. Penyesuaian tersebut akan
 berlaku atas harga akhir yang akan dibayar oleh Pembeli. </p>
 <br/>
 <p>15.3 Harga barang yang dijual akan ditentukan oleh Penjual atas kebijakannya sendiri. Harga
 barang dan biaya kirim harus termasuk seluruh jumlah yang akan dikenakan kepada Pembeli
 seperti pajak penjualan, pajak pertambahan nilai, ongkos, dsb. dan Penjual tidak boleh
 mengenakan jumlah semacam itu sebagai tambahan dan secara terpisah kepada Pembeli.
 </p>
 <br/>
 <p>15.4 Penjual harus mengeluarkan tanda terima, slip kartu kredit atau faktur pajak kepada Pembeli
   atas permintaan.
 </p>
 <br/>
 <p>15.5 Penjual mengakui dan setuju bahwa Penjual akan bertanggung jawab untuk membayar seluruh
 pajak, bea dan cukai untuk barang yang dijual dan penjual disarankan untuk mencari nasihat
 profesional jika mengalami keraguan.
 
 </p>
 <br/>
 <p>15.6 Penjual mengakui dan setuju bahwa pelanggaran Penjual terhadap salah satu kebijakan
 Eureka BookHouse akan mengakibatkan berbagai tindakan seperti yang tercantum dalam
 Bagian 7.
 
 </p>
 <br/>
             </div>
           </div>
         </div>
       </section>
     </div>
   </div>
 </template>
   
   <script>
 export default {
   name: "syaratLayanan",
   components: {},
   data() {
     return {};
   },
   mounted() {
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: "smooth",
     });
   },
   methods: {},
 };
 </script>
   
   <style>
 </style>