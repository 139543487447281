<template>
  <div>
    <div class="bg6 p-b-140">
      <div class="container p-lr-0-sm">
        <div class="p-t-15">
          <div class="bg0 bor6 p-all-15 bs1">
            <h3 class="mtext-106">
              TI & Komunikasi SD<small class="ml-3 stext-107"
                >TI & Komunikasi SD</small
              >
            </h3>
          </div>
        </div>
        <div class="p-t-15">
          <div class="container p-lr-0">
            <div class="row">
              <div class="col-lg-3 col-md-3" id="menu_side">
                <div class="bg0 bor6 p-b-10 p-lr-15-sm bs1">
                  <br />

                  <div class="bord-tb">
                    <p class="stext-101 cl2 p-tb-8 p-l-15 text-left">
                      <b> BERDASAR KATEGORI </b>
                    </p>
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom">Buku Pelajaran</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="https://blog.hubspot.com/">Buku SD</a>
                          <a href="https://academy.hubspot.com/">Buku SMP</a>
                          <a href="https://www.youtube.com/user/hubspot"
                            >Buku SMA</a
                          >
                          <a href="https://blog.hubspot.com/">Buku SMK</a>
                          <a href="https://academy.hubspot.com/">Buku TK</a>
                          <a href="https://www.youtube.com/user/hubspot"
                            >Buku Tematik</a
                          >
                          <a href="https://www.youtube.com/user/hubspot"
                            >Buku MI</a
                          >
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom1">Buku Umum</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Masakan dan Minuman</a>
                          <a href="">Hobi dan Hastakarya</a>
                          <a href="">Komputer</a>
                          <a href="">Parenting</a>
                          <a href="">Pendidikan Umum</a>
                          <a href="">Peta Dan Atlas</a>
                          <a href="">Komik</a>
                          <a href="">Seri Karakter</a>
                          <a href="">Kamus dan Bahasa</a>
                          <a href="">Buku Seri</a>
                          <a href="">Fashion dan kecantikan</a>
                          <a href="">Buku Umum Lainnya </a>
                          <a href="">Buku Agama</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom2">Buku Soal</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Soal SD</a>
                          <a href="">Soal SMP</a>
                          <a href="">Soal SMA</a>
                          <a href="">Soal SMK</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom3">Buku Import</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Fiction</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom4">Gaya Hidup</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Tas</a>
                          <a href="">Sepatu</a>
                          <a href="">Tumbler</a>
                          <a href="">Aksesoris</a>
                          <a href="">Voucher</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom5">Mainan dan Hobi</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Perlengkapan</a>
                          <a href="">Puzzle</a>
                          <a href="">Diecast & Model Kit </a>
                          <a href="">Figur </a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom6">Buku Anak</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Ensiklopedia</a>
                          <a href="">Paket Buku Spesial</a>
                          <a href="">Menggambar</a>
                          <a href="">Lift The Flap </a>
                          <a href="">Keterampilan Anak</a>
                          <a href="">Creative Writing</a>
                          <a href="">Cerita Rakyat Nusantara</a>
                          <a href="">Buku Stiker </a>
                          <a href="">Atlas dan Geografi </a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom7">Novel</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Novel Remaja</a>
                          <a href="">Novel Romance</a>
                          <a href="">Novel Horor </a>
                          <a href="">Terjemahan </a>
                          <a href="">Lainnya</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom8">Buku Murah</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Agro Group</a>
                          <a href="">Bhuana lmu Populer</a>
                          <a href="">Elex Media</a>
                          <a href="">Gramedia</a>
                          <a href="">Mizan Media Utama </a>
                          <a href="">Promo Sales</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom9">Perguruan Tinggi</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Administrasi</a>
                          <a href="">Akuntansi</a>
                          <a href="">At a Glance</a>
                          <a href="">Bahasa & Sastra</a>
                          <a href="">Ekonomi</a>
                          <a href="">Hukum</a>
                          <a href="">Kedokteran</a>
                          <a href="">Keperawatan</a>
                          <a href="">Lecture Notes</a>
                          <a href="">Manajamen </a>
                          <a href="">MIPA</a>
                          <a href="">Teknik</a>
                          <a href="">Psikologi</a>
                          <a href="">Sospol </a>
                          <a href="">Statistik</a>
                          <a href="">Schaum Outline</a>
                          <a href="">Jurnalistik </a>
                          <a href="">PERTI Lainnya</a>
                          <a href="">Mesin</a>
                          <a href="">Manajemen</a>
                          <a href="">Komputer </a>
                          <a href="">Islam </a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom10">
                          Olahraga dan Outdoor
                        </button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Gym & Fitness</a>
                          <a href="">Basket </a>
                          <a href="">Sepakbola dan Futsal</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom11">ATK</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Alat dan buku tulis</a>
                          <a href="">Perlengkapan Kantor</a>
                          <a href="">IT Supply</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom12">Sayuran Organik</button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom13">Paket Push</button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom14">Buku Flat</button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom15">Tour and Travel</button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom16">Off. Merchandise</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Local Merch</a>
                          <a href="">Import Merch </a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom17">Eureka Mart</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Kebutuhan Rumah</a>
                          <a href="">Perawatan Kesehatan</a>
                          <a href="">Keperluan Bayi</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom18">eBook</button>
                        <div class="dropdown-content">
                          <br />
                          <a href="">Buku Umum </a>
                          <a href="">Buku Sekolah</a>
                          <a href="">Koran</a>
                          <a href="">Majalah</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom19">DIY</button>
                      </div>
                    </div>
                    <br />
                    <div>
                      <div class="dropdown">
                        <button class="dr-custom20">ebook-elib</button>
                      </div>
                    </div>
                    <br />
                    <p class="stext-101 cl2 p-tb-8 p-l-15 text-left">
                      <b> BERDASAR BRAND </b>
                    </p>
                  </div>
                  <div class="flex-w flex-t p-b-13 text-left">
                    <div class="scrollbar" id="">
                      <div id="menuwrapper">
                        <ul>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=adinata"
                              class="card-categories-li-content"
                              >ADINATA(9)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=andi-publisher"
                              class="card-categories-li-content"
                              >Andi Publisher(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=artemedia"
                              class="card-categories-li-content"
                              >ARTEMEDIA(15)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=banana"
                              class="card-categories-li-content"
                              >Banana(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=bantex"
                              class="card-categories-li-content"
                              >BANTEX(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=bartega-arts"
                              class="card-categories-li-content"
                              >Bartega ARTS(21)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=bath-dan-body-works"
                              class="card-categories-li-content"
                              >Bath & Body Works(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=bazic-stationary"
                              class="card-categories-li-content"
                              >BAZIC STATIONARY(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=bino-mitra-sejati"
                              class="card-categories-li-content"
                              >Bino Mitra Sejati(12)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-akik"
                              class="card-categories-li-content"
                              >Buku Akik(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-erlangga"
                              class="card-categories-li-content"
                              >Buku Erlangga(3123)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-kita"
                              class="card-categories-li-content"
                              >Buku Kita(70)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=buku-murah"
                              class="card-categories-li-content"
                              >Buku Murah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=cubicfun-indo"
                              class="card-categories-li-content"
                              >Cubicfun Indo(33)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=deli-indonesia"
                              class="card-categories-li-content"
                              >DELI Indonesia(40)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=dian-rakyat"
                              class="card-categories-li-content"
                              >Dian Rakyat(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=diomedia"
                              class="card-categories-li-content"
                              >Diomedia(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=edufuntoys"
                              class="card-categories-li-content"
                              >Edufuntoys(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=elexmedia"
                              class="card-categories-li-content"
                              >Elexmedia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=emco"
                              class="card-categories-li-content"
                              >Emco(14)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=emway-globalindo"
                              class="card-categories-li-content"
                              >Emway Globalindo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=faber-castel"
                              class="card-categories-li-content"
                              >Faber Castel(33)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=famillia-indo"
                              class="card-categories-li-content"
                              >Famillia Indo(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=flomo"
                              class="card-categories-li-content"
                              >Flomo(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=gramedia"
                              class="card-categories-li-content"
                              >Gramedia(331)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=grass-media"
                              class="card-categories-li-content"
                              >Grass Media(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=greebel"
                              class="card-categories-li-content"
                              >GREEBEL(23)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=harvest"
                              class="card-categories-li-content"
                              >Harvest(23)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=hutamedia"
                              class="card-categories-li-content"
                              >Hutamedia(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=ilikegap.com"
                              class="card-categories-li-content"
                              >iLikegap.com(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=indonesia-tera"
                              class="card-categories-li-content"
                              >Indonesia Tera(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=jingga-unggul"
                              class="card-categories-li-content"
                              >Jingga Unggul(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=karya-mulia-adonai"
                              class="card-categories-li-content"
                              >Karya Mulia Adonai(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=kawah-media"
                              class="card-categories-li-content"
                              >Kawah Media(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=kubus-media"
                              class="card-categories-li-content"
                              >Kubus Media(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=macs-world"
                              class="card-categories-li-content"
                              >Macs World(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=mainan-kayu"
                              class="card-categories-li-content"
                              >mainan kayu(19)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=maped"
                              class="card-categories-li-content"
                              >Maped(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=miya-arts"
                              class="card-categories-li-content"
                              >Miya Arts(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=mizan"
                              class="card-categories-li-content"
                              >Mizan(173)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=mojok-store"
                              class="card-categories-li-content"
                              >Mojok Store(16)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=penerbit-haru"
                              class="card-categories-li-content"
                              >Penerbit Haru(22)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=penerbit-inari"
                              class="card-categories-li-content"
                              >Penerbit Inari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=periplus"
                              class="card-categories-li-content"
                              >Periplus(18)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=pico"
                              class="card-categories-li-content"
                              >PICO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=post-santa"
                              class="card-categories-li-content"
                              >POST SANTA(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=powerpuff-girls"
                              class="card-categories-li-content"
                              >Powerpuff Girls(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=prakardus"
                              class="card-categories-li-content"
                              >Prakardus(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=pustaka-alvabet"
                              class="card-categories-li-content"
                              >Pustaka Alvabet(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=rajagrafindo"
                              class="card-categories-li-content"
                              >Rajagrafindo(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=rdm-publisher"
                              class="card-categories-li-content"
                              >RDM Publisher(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=reon-comic"
                              class="card-categories-li-content"
                              >REON COMIC(16)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=rodiya-official"
                              class="card-categories-li-content"
                              >Rodiya Official(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=samb"
                              class="card-categories-li-content"
                              >SAMB(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=shifra"
                              class="card-categories-li-content"
                              >SHIFRA(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=sinar-dunia"
                              class="card-categories-li-content"
                              >Sinar Dunia(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=solo-murni"
                              class="card-categories-li-content"
                              >Solo Murni(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=staedtler"
                              class="card-categories-li-content"
                              >Staedtler(12)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=tempo-scan"
                              class="card-categories-li-content"
                              >Tempo Scan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=tisa-diamanta"
                              class="card-categories-li-content"
                              >Tisa Diamanta(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=titi"
                              class="card-categories-li-content"
                              >Titi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=tomiko-stationery"
                              class="card-categories-li-content"
                              >Tomiko Stationery(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=toyu-indonesia"
                              class="card-categories-li-content"
                              >Toyu Indonesia(33)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=unilever"
                              class="card-categories-li-content"
                              >Unilever(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?manufacturer=we-bare-bears"
                              class="card-categories-li-content"
                              >We Bare Bears(7)</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="bord-tb text-left">
                    <p class="stext-101 cl2 p-tb-8 p-l-15">
                      <b> BERDASARKAN PENULIS </b>
                    </p>
                  </div>
                  <div class="flex-w flex-t p-b-13 text-left">
                    <div class="scrollbar" id="">
                      <div id="menuwrapper">
                        <ul>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author="
                              class="card-categories-li-content"
                              >(324)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-sumardianta-wahyu-kris-aw"
                              class="card-categories-li-content"
                            >
                              J. Sumardianta, Wahyu Kris AW(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=karla-m-nashar"
                              class="card-categories-li-content"
                            >
                              Karla M. Nashar(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ucita-pohan-dan-jozz-felix"
                              class="card-categories-li-content"
                            >
                              Ucita Pohan & Jozz Felix(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nasihatku"
                              class="card-categories-li-content"
                              >@nasihatku(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=a-tabi-in-nur-khikmah"
                              class="card-categories-li-content"
                              >A Tabi`In, Nur Khikmah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=a-ashin-thohari"
                              class="card-categories-li-content"
                              >A. Ashin Thohari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=a-helwa"
                              class="card-categories-li-content"
                              >A. Helwa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=a-indradi-rahmah-p"
                              class="card-categories-li-content"
                              >A. Indradi-Rahmah P.(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=aan-wulandari-rae-sita-fifi-afiah"
                              class="card-categories-li-content"
                              >Aan Wulandari-Rae Sita-Fifi Afiah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=aas-saidah-tiara-damayanti"
                              class="card-categories-li-content"
                              >Aas Saidah-Tiara Damayanti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abay-adhitya"
                              class="card-categories-li-content"
                              >Abay Adhitya(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abd-rosyid-dkk"
                              class="card-categories-li-content"
                              >Abd.Rosyid, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abdul-rokhim"
                              class="card-categories-li-content"
                              >Abdul Rokhim(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abdurrahman"
                              class="card-categories-li-content"
                              >Abdurrahman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abigail-a"
                              class="card-categories-li-content"
                              >Abigail A(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=abu-yasid"
                              class="card-categories-li-content"
                              >Abu Yasid(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=acemoglu-laibson-list"
                              class="card-categories-li-content"
                              >Acemoglu, Laibson, List(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=achi-tm"
                              class="card-categories-li-content"
                              >Achi Tm(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=acp-magazines"
                              class="card-categories-li-content"
                              >Acp Magazines(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adele-geras"
                              class="card-categories-li-content"
                              >Adele Geras(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adham"
                              class="card-categories-li-content"
                              >Adham(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adi-k"
                              class="card-categories-li-content"
                              >Adi K.(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adjie-santosoputro"
                              class="card-categories-li-content"
                              >ADJIE SANTOSOPUTRO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adolf-heuken"
                              class="card-categories-li-content"
                              >Adolf Heuken(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=adolf-heuken-sj"
                              class="card-categories-li-content"
                              >Adolf Heuken Sj.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=agus-sachari"
                              class="card-categories-li-content"
                              >Agus Sachari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=agus-setiawan"
                              class="card-categories-li-content"
                              >Agus Setiawan(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=agustinus-agus-setiawan"
                              class="card-categories-li-content"
                              >Agustinus Agus Setiawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=agustinus-indradi"
                              class="card-categories-li-content"
                              >Agustinus Indradi(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=agustinus-indradi"
                              class="card-categories-li-content"
                              >Agustinus Indradi(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ahmad-baso"
                              class="card-categories-li-content"
                              >Ahmad Baso(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ahmad-erani-yustika"
                              class="card-categories-li-content"
                              >Ahmad Erani Yustika(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ahmad-faried-danny-halim-tri-hanggono-a"
                              class="card-categories-li-content"
                              >Ahmad Faried-Danny Halim-Tri Hanggono A.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ahmad-syaekhuddin"
                              class="card-categories-li-content"
                              >Ahmad Syaekhuddin(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ahn-jean-myung-lee-kyung-ah-han-hoo-young"
                              class="card-categories-li-content"
                              >Ahn Jean Myung-Lee Kyung Ah-Han Hoo Young(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ainun-nufus"
                              class="card-categories-li-content"
                              >Ainun Nufus(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=akaigita"
                              class="card-categories-li-content"
                              >Akaigita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=akhmad-fauzy"
                              class="card-categories-li-content"
                              >Akhmad Fauzy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=akmal"
                              class="card-categories-li-content"
                              >Akmal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alam-rudianto"
                              class="card-categories-li-content"
                              >Alam - Rudianto(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alam-s"
                              class="card-categories-li-content"
                              >Alam S(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alam-situmorang"
                              class="card-categories-li-content"
                              >Alam Situmorang(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alan-lightman"
                              class="card-categories-li-content"
                              >Alan Lightman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alan-v-oppenheim"
                              class="card-categories-li-content"
                              >Alan V.Oppenheim(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alastair-mc-alpine"
                              class="card-categories-li-content"
                              >Alastair Mc Alpine(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=albertus-setya-budhi"
                              class="card-categories-li-content"
                              >Albertus Setya Budhi(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=albin-michel"
                              class="card-categories-li-content"
                              >Albin Michel(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alex-fifth-dkk"
                              class="card-categories-li-content"
                              >Alex Fifth, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alex-frith"
                              class="card-categories-li-content"
                              >Alex Frith(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alex-suryanta"
                              class="card-categories-li-content"
                              >Alex Suryanta(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alexander-thian"
                              class="card-categories-li-content"
                              >Alexander Thian(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alfredo-h-s-ang"
                              class="card-categories-li-content"
                              >Alfredo H.S. Ang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ali-nurdin"
                              class="card-categories-li-content"
                              >Ali Nurdin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alice-whately"
                              class="card-categories-li-content"
                              >Alice Whately(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alison-i-dan-ron-z"
                              class="card-categories-li-content"
                              >Alison I & Ron Z(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=allan-r-drebin"
                              class="card-categories-li-content"
                              >Allan R. Drebin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=allison-van-diepen"
                              class="card-categories-li-content"
                              >Allison Van Diepen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ally-carter"
                              class="card-categories-li-content"
                              >Ally Carter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alpha-chiang"
                              class="card-categories-li-content"
                              >Alpha Chiang(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alton-thygerson"
                              class="card-categories-li-content"
                              >Alton Thygerson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alvin-a-a"
                              class="card-categories-li-content"
                              >Alvin A.A.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=alvin-hall"
                              class="card-categories-li-content"
                              >Alvin Hall(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ama-achmad"
                              class="card-categories-li-content"
                              >Ama Achmad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amal-hamsan"
                              class="card-categories-li-content"
                              >Amal Hamsan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ambar-setyorini-h-himawan"
                              class="card-categories-li-content"
                              >Ambar Setyorini - H. Himawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ambar-setyorini-suwaji"
                              class="card-categories-li-content"
                              >Ambar Setyorini - Suwaji(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amenkcoy"
                              class="card-categories-li-content"
                              >Amenkcoy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=american-greetings"
                              class="card-categories-li-content"
                              >American Greetings(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amin-kuneifi"
                              class="card-categories-li-content"
                              >Amin Kuneifi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amin-syukur"
                              class="card-categories-li-content"
                              >Amin Syukur(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=aminarni-h-asyari"
                              class="card-categories-li-content"
                              >Aminarni - H. Asy'Ari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=aminuddin"
                              class="card-categories-li-content"
                              >Aminuddin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amir-machmud"
                              class="card-categories-li-content"
                              >Amir Machmud(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amrine-h-t"
                              class="card-categories-li-content"
                              >Amrine H.T.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amrullah-dkk"
                              class="card-categories-li-content"
                              >Amrullah, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amy-ackelsberg"
                              class="card-categories-li-content"
                              >Amy Ackelsberg(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=amy-g-miron"
                              class="card-categories-li-content"
                              >Amy G.Miron(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=analisa-widyaningrum"
                              class="card-categories-li-content"
                              >Analisa Widyaningrum(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andersen"
                              class="card-categories-li-content"
                              >Andersen(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andi-hasad"
                              class="card-categories-li-content"
                              >Andi Hasad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andi-novianto"
                              class="card-categories-li-content"
                              >Andi Novianto(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andi-stix-dan-frank-hrbek"
                              class="card-categories-li-content"
                              >Andi Stix & Frank Hrbek(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andi-sururi-dan-ker-radnesa"
                              class="card-categories-li-content"
                              >Andi Sururi & Ker Radnesa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andrea-hirata"
                              class="card-categories-li-content"
                              >Andrea Hirata(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andrea-posner"
                              class="card-categories-li-content"
                              >Andrea Posner(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andrew-betsis"
                              class="card-categories-li-content"
                              >Andrew Betsis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andrew-betsis-lawrence"
                              class="card-categories-li-content"
                              >Andrew Betsis - Lawrence(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=andrew-mari"
                              class="card-categories-li-content"
                              >Andrew, Mari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=angela-wilkes"
                              class="card-categories-li-content"
                              >Angela Wilkes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anggun-pradesha"
                              class="card-categories-li-content"
                              >Anggun Pradesha(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=angie-thomas"
                              class="card-categories-li-content"
                              >Angie Thomas(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=angus-j-m"
                              class="card-categories-li-content"
                              >Angus J.M.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anies-listyowati"
                              class="card-categories-li-content"
                              >Anies Listyowati(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anita-ganeri"
                              class="card-categories-li-content"
                              >Anita Ganeri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anjani-fitriana"
                              class="card-categories-li-content"
                              >ANJANI FITRIANA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ann-jackman"
                              class="card-categories-li-content"
                              >Ann Jackman(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anna-ervita-dewi"
                              class="card-categories-li-content"
                              >ANNA ERVITA DEWI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anna-godbersen"
                              class="card-categories-li-content"
                              >Anna Godbersen(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anna-nielsen"
                              class="card-categories-li-content"
                              >Anna Nielsen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anna-palmer"
                              class="card-categories-li-content"
                              >Anna Palmer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=annabel-karmel"
                              class="card-categories-li-content"
                              >Annabel Karmel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anne-vande"
                              class="card-categories-li-content"
                              >Anne Vande(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=annisa-rahmania"
                              class="card-categories-li-content"
                              >Annisa Rahmania(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=annisa-rizkiana-rahmasari"
                              class="card-categories-li-content"
                              >Annisa Rizkiana Rahmasari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=anton-s"
                              class="card-categories-li-content"
                              >Anton S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=any-sufiaty-ari-damari"
                              class="card-categories-li-content"
                              >Any Sufiaty-Ari Damari(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=april-cahya"
                              class="card-categories-li-content"
                              >April Cahya(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=apsley"
                              class="card-categories-li-content"
                              >Apsley(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arata-kim"
                              class="card-categories-li-content"
                              >Arata Kim(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arens"
                              class="card-categories-li-content"
                              >Arens(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arens-elder-beasley"
                              class="card-categories-li-content"
                              >Arens, Elder, Beasley(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ari-damari"
                              class="card-categories-li-content"
                              >Ari Damari(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ari-wiyati-purwandari"
                              class="card-categories-li-content"
                              >Ari Wiyati Purwandari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ari-wiyati-dkk"
                              class="card-categories-li-content"
                              >Ari Wiyati,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arief-sabaruddin"
                              class="card-categories-li-content"
                              >Arief Sabaruddin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arif-saifudin-yudistira"
                              class="card-categories-li-content"
                              >Arif Saifudin Yudistira(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arista-vee"
                              class="card-categories-li-content"
                              >Arista Vee(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arleen-a"
                              class="card-categories-li-content"
                              >Arleen A.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arman-dhani"
                              class="card-categories-li-content"
                              >Arman Dhani(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arswendo-atmowiloto"
                              class="card-categories-li-content"
                              >Arswendo Atmowiloto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=arumi-e"
                              class="card-categories-li-content"
                              >Arumi E.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ary-nilandria"
                              class="card-categories-li-content"
                              >Ary Nilandria(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asep-parantika"
                              class="card-categories-li-content"
                              >Asep Parantika(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ashley-mendoza"
                              class="card-categories-li-content"
                              >Ashley Mendoza(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ashton"
                              class="card-categories-li-content"
                              >Ashton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asri-aci"
                              class="card-categories-li-content"
                              >Asri Aci(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asrika-v"
                              class="card-categories-li-content"
                              >Asrika V(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asrorun-niam-sholeh"
                              class="card-categories-li-content"
                              >Asrorun Niam Sholeh(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=assyifa-s-harum"
                              class="card-categories-li-content"
                              >Assyifa S. Harum(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=astri-apriyani"
                              class="card-categories-li-content"
                              >ASTRI APRIYANI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=astrid-wen"
                              class="card-categories-li-content"
                              >Astrid Wen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asyari-dkk"
                              class="card-categories-li-content"
                              >Asy'Ari, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asyari-dkk"
                              class="card-categories-li-content"
                              >AsyAri, Dkk(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=asyifhashi"
                              class="card-categories-li-content"
                              >Asyifhashi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=atlanta-bartlett"
                              class="card-categories-li-content"
                              >Atlanta Bartlett(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=atwi-suparman"
                              class="card-categories-li-content"
                              >Atwi Suparman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=audrey-yu-jia-hui"
                              class="card-categories-li-content"
                              >Audrey Yu Jia Hui(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=aya-widjaja-x-arumi-e"
                              class="card-categories-li-content"
                              >Aya Widjaja X Arumi E(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ayep-rosidi"
                              class="card-categories-li-content"
                              >Ayep Rosidi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ayu-p-dan-edith-n"
                              class="card-categories-li-content"
                              >Ayu P & Edith N(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=azhari-aiyub"
                              class="card-categories-li-content"
                              >Azhari Aiyub(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=b-k-noormandiri"
                              class="card-categories-li-content"
                              >B.K. Noormandiri(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bagoes-wiryomartono"
                              class="card-categories-li-content"
                              >Bagoes Wiryomartono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bagyono"
                              class="card-categories-li-content"
                              >Bagyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bambang-sugeng"
                              class="card-categories-li-content"
                              >Bambang Sugeng(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bambang-sutejo"
                              class="card-categories-li-content"
                              >Bambang Sutejo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bambang-sutejo"
                              class="card-categories-li-content"
                              >Bambang Sutejo(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bambang-sutopo"
                              class="card-categories-li-content"
                              >Bambang Sutopo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=banu-susanto"
                              class="card-categories-li-content"
                              >BANU SUSANTO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=baron-branscombe"
                              class="card-categories-li-content"
                              >Baron-Branscombe(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=basher-dan-dingle"
                              class="card-categories-li-content"
                              >Basher & Dingle(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bayu-permana"
                              class="card-categories-li-content"
                              >Bayu Permana(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ben-sohib"
                              class="card-categories-li-content"
                              >Ben Sohib(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=beni-satryo"
                              class="card-categories-li-content"
                              >Beni Satryo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=benjamin-lawner"
                              class="card-categories-li-content"
                              >Benjamin Lawner(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=benny-rhamdani"
                              class="card-categories-li-content"
                              >Benny Rhamdani(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bernard-hennessy"
                              class="card-categories-li-content"
                              >Bernard Hennessy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=beth-harwood"
                              class="card-categories-li-content"
                              >Beth Harwood(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bill-scollon"
                              class="card-categories-li-content"
                              >Bill Scollon(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bill-scolon"
                              class="card-categories-li-content"
                              >Bill Scolon(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bimo-suciono"
                              class="card-categories-li-content"
                              >Bimo Suciono(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bimo-suciono-deky-noviar"
                              class="card-categories-li-content"
                              >Bimo Suciono-Deky Noviar(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=birsen-ekim-ozen"
                              class="card-categories-li-content"
                              >BIRSEN EKIM OZEN(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bkg-for-education"
                              class="card-categories-li-content"
                              >Bkg For Education(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=blanchard-johnson"
                              class="card-categories-li-content"
                              >Blanchard-Johnson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=blundell"
                              class="card-categories-li-content"
                              >Blundell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bonggas-l-tobing"
                              class="card-categories-li-content"
                              >Bonggas L. Tobing(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=borassaem"
                              class="card-categories-li-content"
                              >BORASSAEM(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bowles-j-e"
                              class="card-categories-li-content"
                              >Bowles J.E.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=boy-candra"
                              class="card-categories-li-content"
                              >Boy Candra(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bradley-zweig"
                              class="card-categories-li-content"
                              >Bradley Zweig(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=braja-m-das"
                              class="card-categories-li-content"
                              >Braja M. Das(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=brealey-myers"
                              class="card-categories-li-content"
                              >Brealey-Myers(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bret-pitt"
                              class="card-categories-li-content"
                              >Bret Pitt(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=brian-clegg"
                              class="card-categories-li-content"
                              >Brian Clegg(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bruce-r-munson"
                              class="card-categories-li-content"
                              >Bruce R.Munson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bts-indonesia-army"
                              class="card-categories-li-content"
                              >BTS INDONESIA ARMY(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=budi-prasodjo-dita-adi-s"
                              class="card-categories-li-content"
                              >Budi Prasodjo-Dita Adi S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=budi-santoso-dkk"
                              class="card-categories-li-content"
                              >Budi Santoso, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=budiman-hakim"
                              class="card-categories-li-content"
                              >BUDIMAN HAKIM(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=bumilangit-comic-media"
                              class="card-categories-li-content"
                              >Bumilangit Comic Media(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=burl-e-dishongh"
                              class="card-categories-li-content"
                              >Burl E.Dishongh(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=c-ray-johnson"
                              class="card-categories-li-content"
                              >C. Ray Johnson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=c-jotin-khisty"
                              class="card-categories-li-content"
                              >C.Jotin Khisty(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cambell"
                              class="card-categories-li-content"
                              >Cambell(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=candra-malik"
                              class="card-categories-li-content"
                              >Candra Malik(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=capt-desmond-hutagaol"
                              class="card-categories-li-content"
                              >Capt. Desmond Hutagaol(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caramel"
                              class="card-categories-li-content"
                              >Caramel(12)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caren-lissner"
                              class="card-categories-li-content"
                              >CAREN LISSNER(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caren-traffford"
                              class="card-categories-li-content"
                              >Caren Traffford(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=carl-sagan"
                              class="card-categories-li-content"
                              >Carl Sagan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=carlson"
                              class="card-categories-li-content"
                              >Carlson(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=carmantyo"
                              class="card-categories-li-content"
                              >CARMANTYO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=carmine-gallo"
                              class="card-categories-li-content"
                              >Carmine Gallo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caro-handley"
                              class="card-categories-li-content"
                              >Caro Handley(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=carole-wade-dan-carol-t"
                              class="card-categories-li-content"
                              >Carole Wade & Carol T(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caroline-bingham"
                              class="card-categories-li-content"
                              >Caroline Bingham(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=caroline-young"
                              class="card-categories-li-content"
                              >Caroline Young(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=case-fair"
                              class="card-categories-li-content"
                              >Case - Fair(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cath-senker"
                              class="card-categories-li-content"
                              >Cath Senker(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=catherine-walters"
                              class="card-categories-li-content"
                              >Catherine Walters(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cathy-chamer"
                              class="card-categories-li-content"
                              >Cathy Chamer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cathy-delanssay"
                              class="card-categories-li-content"
                              >Cathy Delanssay(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=catriona-melville"
                              class="card-categories-li-content"
                              >Catriona Melville(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cgee"
                              class="card-categories-li-content"
                              >Cgee(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chabib-duta-hapsoro"
                              class="card-categories-li-content"
                              >CHABIB DUTA HAPSORO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chan-nam-joo"
                              class="card-categories-li-content"
                              >CHAN NAM JOO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=charlaine-harris"
                              class="card-categories-li-content"
                              >Charlaine Harris(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=charles-t-hongren"
                              class="card-categories-li-content"
                              >Charles T. Hongren(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=charlotte-bronte"
                              class="card-categories-li-content"
                              >Charlotte Bronte(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=charlton"
                              class="card-categories-li-content"
                              >Charlton(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chatheriene-gerber"
                              class="card-categories-li-content"
                              >Chatheriene Gerber(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chawrelia"
                              class="card-categories-li-content"
                              >Chawrelia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chechyl-miliani"
                              class="card-categories-li-content"
                              >Chechyl Miliani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chef-salimoz"
                              class="card-categories-li-content"
                              >Chef Salimoz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chelsea-karina"
                              class="card-categories-li-content"
                              >Chelsea Karina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chen-jian"
                              class="card-categories-li-content"
                              >Chen Jian(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cheryl-owen"
                              class="card-categories-li-content"
                              >Cheryl Owen(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chi-kong-tse"
                              class="card-categories-li-content"
                              >Chi Kong Tse(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=choeroni"
                              class="card-categories-li-content"
                              >Choeroni(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=choeroni-dkk"
                              class="card-categories-li-content"
                              >Choeroni, Dkk(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=choeroni-muh-syafrudin-nurokhim"
                              class="card-categories-li-content"
                              >Choeroni-Muh. Syafrudin-Nurokhim(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chris-gifford"
                              class="card-categories-li-content"
                              >Chris Gifford(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=christie"
                              class="card-categories-li-content"
                              >Christie(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=christina-goodings"
                              class="card-categories-li-content"
                              >Christina Goodings(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=christina-ricci"
                              class="card-categories-li-content"
                              >Christina Ricci(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=christine-tirta"
                              class="card-categories-li-content"
                              >Christine Tirta(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=christopher-pass"
                              class="card-categories-li-content"
                              >Christopher Pass(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=chu-kia-wang-ph-d"
                              class="card-categories-li-content"
                              >Chu Kia Wang Ph.D.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ciaran-walsh"
                              class="card-categories-li-content"
                              >Ciaran Walsh(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=citra-mustikawati"
                              class="card-categories-li-content"
                              >Citra Mustikawati(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=claire-dan-simon"
                              class="card-categories-li-content"
                              >Claire & Simon(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=claire-boyd"
                              class="card-categories-li-content"
                              >Claire Boyd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=claire-freedman"
                              class="card-categories-li-content"
                              >Claire Freedman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clara-amanda"
                              class="card-categories-li-content"
                              >Clara Amanda(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clara-ng"
                              class="card-categories-li-content"
                              >Clara Ng(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clara-woods"
                              class="card-categories-li-content"
                              >Clara Woods(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clare-ford"
                              class="card-categories-li-content"
                              >Clare Ford(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clarissa-goenawan"
                              class="card-categories-li-content"
                              >Clarissa Goenawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=clarissa-noviany"
                              class="card-categories-li-content"
                              >CLARISSA NOVIANY(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=claudia-fenderson"
                              class="card-categories-li-content"
                              >Claudia Fenderson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=colin-cooper"
                              class="card-categories-li-content"
                              >Colin Cooper(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=condrywati"
                              class="card-categories-li-content"
                              >Condrywati(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=constanza-droop"
                              class="card-categories-li-content"
                              >Constanza Droop(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cornelius-katona"
                              class="card-categories-li-content"
                              >Cornelius Katona(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cory"
                              class="card-categories-li-content"
                              >Cory(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cory-huff"
                              class="card-categories-li-content"
                              >CORY HUFF(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=courtney-carbone"
                              class="card-categories-li-content"
                              >Courtney Carbone(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=crisp-dan-elliot"
                              class="card-categories-li-content"
                              >Crisp & Elliot(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=criss-mills"
                              class="card-categories-li-content"
                              >Criss Mills(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cristina-eviutami"
                              class="card-categories-li-content"
                              >Cristina Eviutami(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cucu-nurhasanah"
                              class="card-categories-li-content"
                              >Cucu Nurhasanah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=cynthia-sherle"
                              class="card-categories-li-content"
                              >Cynthia Sherle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=da-pratiwi"
                              class="card-categories-li-content"
                              >Da.Pratiwi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=da-pratiwi-suharno-sri-maryati-bambang-s-srikini"
                              class="card-categories-li-content"
                              >Da.Pratiwi,Suharno,Sri Maryati,Bambang
                              S,Srikini(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dale-carniege"
                              class="card-categories-li-content"
                              >Dale Carniege(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dalmatian"
                              class="card-categories-li-content"
                              >Dalmatian(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dalmatian-press"
                              class="card-categories-li-content"
                              >Dalmatian Press(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dalmation-press"
                              class="card-categories-li-content"
                              >Dalmation Press(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=damodar-gujarati"
                              class="card-categories-li-content"
                              >Damodar Gujarati(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dan-ariely"
                              class="card-categories-li-content"
                              >Dan Ariely(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dan-gutman"
                              class="card-categories-li-content"
                              >Dan Gutman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dan-m-guy-c"
                              class="card-categories-li-content"
                              >Dan M. Guy C.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dan-sullivan"
                              class="card-categories-li-content"
                              >Dan Sullivan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=daniel-kahneman"
                              class="card-categories-li-content"
                              >Daniel Kahneman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dante-darmawangsa-yuliarti-murtiasih-ariessa-racmadhany"
                              class="card-categories-li-content"
                              >Dante Darmawangsa-Yuliarti Murtiasih-Ariessa
                              Racmadhany(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=darmawijoyo"
                              class="card-categories-li-content"
                              >Darmawijoyo(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=darwis-hude"
                              class="card-categories-li-content"
                              >Darwis Hude(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-bedford"
                              class="card-categories-li-content"
                              >David Bedford(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-bonavia"
                              class="card-categories-li-content"
                              >David Bonavia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-cowper"
                              class="card-categories-li-content"
                              >David Cowper(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-h-bangs-jr"
                              class="card-categories-li-content"
                              >David H.Bangs,Jr(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-k-eiteman"
                              class="card-categories-li-content"
                              >David K.Eiteman(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-kirkby"
                              class="card-categories-li-content"
                              >David Kirkby(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-m-kroenke"
                              class="card-categories-li-content"
                              >David M.Kroenke(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-o-sears"
                              class="card-categories-li-content"
                              >David O. Sears(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-roenche"
                              class="card-categories-li-content"
                              >David Roenche(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-rubenstain"
                              class="card-categories-li-content"
                              >David Rubenstain(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=david-wallace-wells"
                              class="card-categories-li-content"
                              >David Wallace-Wells(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dawn-brend"
                              class="card-categories-li-content"
                              >Dawn Brend(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=day-r-a"
                              class="card-categories-li-content"
                              >Day R.A.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=debbie-s-suryawan"
                              class="card-categories-li-content"
                              >Debbie S. Suryawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=deborah-morrison"
                              class="card-categories-li-content"
                              >Deborah Morrison(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dedi-gunarto"
                              class="card-categories-li-content"
                              >Dedi Gunarto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dedi-koswara-dkk"
                              class="card-categories-li-content"
                              >Dedi Koswara,Dkk(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dee-lestari"
                              class="card-categories-li-content"
                              >Dee Lestari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=delima-megaria"
                              class="card-categories-li-content"
                              >Delima Megaria(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dermawan-wibisono"
                              class="card-categories-li-content"
                              >Dermawan Wibisono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=despersa"
                              class="card-categories-li-content"
                              >Despersa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dewi-syaraswati"
                              class="card-categories-li-content"
                              >Dewi Syaraswati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dewi-wulan-sari"
                              class="card-categories-li-content"
                              >Dewi Wulan Sari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dewie-sekar"
                              class="card-categories-li-content"
                              >Dewie Sekar(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dhesy-adhalia"
                              class="card-categories-li-content"
                              >Dhesy Adhalia(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dhesy-adhalia-syafrizal-sy"
                              class="card-categories-li-content"
                              >Dhesy Adhalia-Syafrizal Sy.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dheti-azmi"
                              class="card-categories-li-content"
                              >Dheti Azmi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dhila-sina"
                              class="card-categories-li-content"
                              >Dhila Sina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dhurorudin-mashad"
                              class="card-categories-li-content"
                              >Dhurorudin Mashad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=diah-aryulina"
                              class="card-categories-li-content"
                              >Diah Aryulina(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=diah-nimpuno"
                              class="card-categories-li-content"
                              >Diah Nimpuno(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dian-malini-oktovina"
                              class="card-categories-li-content"
                              >Dian Malini Oktovina(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=diana-hendry"
                              class="card-categories-li-content"
                              >Diana Hendry(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=didang-setiawan-eti-winarsih-yasinto-shindu"
                              class="card-categories-li-content"
                              >Didang Setiawan-Eti Winarsih-Yasinto Shindu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=didik-widiyono-taufiq-handi-k"
                              class="card-categories-li-content"
                              >Didik Widiyono-Taufiq Handi K.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dimas-putra"
                              class="card-categories-li-content"
                              >Dimas Putra(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dinda-ryne"
                              class="card-categories-li-content"
                              >Dinda Ryne(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=disney"
                              class="card-categories-li-content"
                              >Disney(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=disney-enterprise"
                              class="card-categories-li-content"
                              >Disney Enterprise(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=disney-junior"
                              class="card-categories-li-content"
                              >Disney Junior(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=disney-press"
                              class="card-categories-li-content"
                              >Disney Press(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ditta-amelia-sraswati"
                              class="card-categories-li-content"
                              >Ditta Amelia Sraswati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=djoko-purwanto"
                              class="card-categories-li-content"
                              >Djoko Purwanto(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=djoko-warsito"
                              class="card-categories-li-content"
                              >Djoko Warsito(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dominick-salvatore"
                              class="card-categories-li-content"
                              >Dominick Salvatore(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=donald-r-cooper"
                              class="card-categories-li-content"
                              >Donald R. Cooper(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=donald-r-pitts"
                              class="card-categories-li-content"
                              >Donald R. Pitts(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=donals-e-kieso"
                              class="card-categories-li-content"
                              >Donals E.Kieso(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dong-wook-song-photis-panayides"
                              class="card-categories-li-content"
                              >Dong Wook Song-Photis Panayides(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=doris-bachle-k-susanna-bachle"
                              class="card-categories-li-content"
                              >Doris Bachle K.-Susanna Bachle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dorling-kindersley"
                              class="card-categories-li-content"
                              >Dorling Kindersley(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dornbusch"
                              class="card-categories-li-content"
                              >Dornbusch(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=douglas-c-giancoli"
                              class="card-categories-li-content"
                              >Douglas C. Giancoli(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=douglas-miller"
                              class="card-categories-li-content"
                              >Douglas Miller(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-andreas-lako"
                              class="card-categories-li-content"
                              >Dr Andreas Lako(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-gia-pratama"
                              class="card-categories-li-content"
                              >Dr Gia Pratama(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-wachyu-sundayana"
                              class="card-categories-li-content"
                              >Dr Wachyu Sundayana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-ab-susanto"
                              class="card-categories-li-content"
                              >Dr. Ab. Susanto(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-aso-sentana"
                              class="card-categories-li-content"
                              >Dr. Aso Sentana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-gia-pratama"
                              class="card-categories-li-content"
                              >dr. Gia Pratama(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-h-m-a-saleh"
                              class="card-categories-li-content"
                              >Dr. H.M.A.Saleh(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-kunjana-rahardi"
                              class="card-categories-li-content"
                              >Dr. Kunjana Rahardi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-mahyus-ekananda"
                              class="card-categories-li-content"
                              >Dr. Mahyus Ekananda(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-meta-hanindita"
                              class="card-categories-li-content"
                              >Dr. Meta Hanindita(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-meta-hanindita-sp-a-k"
                              class="card-categories-li-content"
                              >DR. META HANINDITA, SP.A(K)(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-meta-hanindita-sp-a"
                              class="card-categories-li-content"
                              >Dr. Meta Hanindita, Sp.a.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-safari"
                              class="card-categories-li-content"
                              >Dr. Safari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-susanto"
                              class="card-categories-li-content"
                              >Dr. Susanto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-irene-maria-juli-astuti-m-pd"
                              class="card-categories-li-content"
                              >Dr.Irene Maria Juli Astuti,M.Pd(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-karen-l-otazo"
                              class="card-categories-li-content"
                              >Dr.Karen L.Otazo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dr-richard-c-woolfson"
                              class="card-categories-li-content"
                              >Dr.Richard C.Woolfson(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dra-irnaningtyas"
                              class="card-categories-li-content"
                              >Dra. Irnaningtyas(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dra-mayke-s-tedjasaputra"
                              class="card-categories-li-content"
                              >Dra. Mayke S. Tedjasaputra(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dreamworks"
                              class="card-categories-li-content"
                              >Dreamworks(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-andi-subargo"
                              class="card-categories-li-content"
                              >Drs. Andi Subargo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-h-masrun-dkk"
                              class="card-categories-li-content"
                              >Drs. H. Masrun Dkk(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-hudiyono"
                              class="card-categories-li-content"
                              >Drs. Hudiyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-mangala-pakpahan"
                              class="card-categories-li-content"
                              >Drs. Mangala Pakpahan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-mochamad-nursalim"
                              class="card-categories-li-content"
                              >Drs. Mochamad Nursalim(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-subeki"
                              class="card-categories-li-content"
                              >Drs. SUBEKI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-sudibyo-m-m"
                              class="card-categories-li-content"
                              >Drs. Sudibyo, M.M(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=drs-lutfi"
                              class="card-categories-li-content"
                              >Drs.Lutfi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dugald-steer"
                              class="card-categories-li-content"
                              >Dugald Steer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-harti"
                              class="card-categories-li-content"
                              >Dwi Harti(9)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-harti-dkk"
                              class="card-categories-li-content"
                              >Dwi Harti,Dkk(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-pujiyanto"
                              class="card-categories-li-content"
                              >Dwi Pujiyanto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-u-irma-murti-k"
                              class="card-categories-li-content"
                              >Dwi Tyas U.-Irma Murti K.(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-u-irma-murti-k"
                              class="card-categories-li-content"
                              >Dwi Tyas U.-Irma Murti K.(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-utami"
                              class="card-categories-li-content"
                              >Dwi Tyas Utami(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-utami-irma-m"
                              class="card-categories-li-content"
                              >Dwi Tyas Utami - Irma M(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-utami-dkk"
                              class="card-categories-li-content"
                              >Dwi Tyas Utami, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-utami-irene-maria"
                              class="card-categories-li-content"
                              >Dwi Tyas Utami-Irene Maria(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dwi-tyas-utami-irma-murti"
                              class="card-categories-li-content"
                              >Dwi Tyas Utami-Irma Murti(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dya-ragil"
                              class="card-categories-li-content"
                              >Dya Ragil(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dyah-sriwilujeng"
                              class="card-categories-li-content"
                              >Dyah Sriwilujeng(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=dyah-utami-puspitarini"
                              class="card-categories-li-content"
                              >Dyah Utami Puspitarini(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=e-kosasih-restuti"
                              class="card-categories-li-content"
                              >E. Kosasih-Restuti(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ebert-griffin"
                              class="card-categories-li-content"
                              >Ebert-Griffin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eddy-hiariej"
                              class="card-categories-li-content"
                              >Eddy Hiariej(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eddyman-ferial"
                              class="card-categories-li-content"
                              >Eddyman Ferial(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edith-natasha"
                              class="card-categories-li-content"
                              >Edith Natasha(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edmunt-conway"
                              class="card-categories-li-content"
                              >Edmunt Conway(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edward-g-namy"
                              class="card-categories-li-content"
                              >Edward G. Namy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edward-j-tarbuck-frederick-k-lutgens"
                              class="card-categories-li-content"
                              >Edward J. Tarbuck-Frederick K. Lutgens(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edward-russell"
                              class="card-categories-li-content"
                              >Edward Russell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=edwin-c-lowenberg"
                              class="card-categories-li-content"
                              >Edwin C. Lowenberg(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=effendy-setyadarma"
                              class="card-categories-li-content"
                              >Effendy Setyadarma(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ega-dyp"
                              class="card-categories-li-content"
                              >Ega Dyp(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=egmont"
                              class="card-categories-li-content"
                              >Egmont(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ehren-myers"
                              class="card-categories-li-content"
                              >Ehren Myers(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-kurniawan"
                              class="card-categories-li-content"
                              >Eka Kurniawan(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-mulya-a-elly-sofiar"
                              class="card-categories-li-content"
                              >Eka Mulya A.-Elly Sofiar(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-mulya-astuti"
                              class="card-categories-li-content"
                              >Eka Mulya Astuti(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-mulya-astuti-shyla-lande"
                              class="card-categories-li-content"
                              >Eka Mulya Astuti-Shyla Lande(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-p-herni-b"
                              class="card-categories-li-content"
                              >EKA P.-HERNI B(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-p-herni-b"
                              class="card-categories-li-content"
                              >Eka P.-Herni B.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-purjiyanta-sudar-bambang-heru-i"
                              class="card-categories-li-content"
                              >Eka Purjiyanta-Sudar-Bambang Heru I.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eka-zuliana-dkk"
                              class="card-categories-li-content"
                              >Eka Zuliana, Dkk(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=el-sabil"
                              class="card-categories-li-content"
                              >El-Sabil(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eleanor-bull"
                              class="card-categories-li-content"
                              >Eleanor Bull(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ellen-warwick"
                              class="card-categories-li-content"
                              >Ellen Warwick(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=elly-sumarsih-dkk"
                              class="card-categories-li-content"
                              >Elly Sumarsih, Dkk(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=elsa-peterson"
                              class="card-categories-li-content"
                              >Elsa Peterson(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=elvina-lim-kusumo"
                              class="card-categories-li-content"
                              >Elvina Lim Kusumo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=elvina-lim-kusumo"
                              class="card-categories-li-content"
                              >Elvina Lim Kusumo(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emil-amir"
                              class="card-categories-li-content"
                              >Emil Amir(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emily-bone"
                              class="card-categories-li-content"
                              >Emily Bone(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emily-giffin"
                              class="card-categories-li-content"
                              >Emily Giffin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emma-baker"
                              class="card-categories-li-content"
                              >Emma Baker(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emma-susan"
                              class="card-categories-li-content"
                              >Emma Susan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=emmanuel"
                              class="card-categories-li-content"
                              >Emmanuel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=endah-rosjidah"
                              class="card-categories-li-content"
                              >Endah Rosjidah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=endrawati-hutasuhut-g-s"
                              class="card-categories-li-content"
                              >Endrawati-Hutasuhut G.S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=engkos-kosasih"
                              class="card-categories-li-content"
                              >Engkos Kosasih(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=engkos-kosasih-iin-hendriyani"
                              class="card-categories-li-content"
                              >Engkos Kosasih-Iin Hendriyani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=enung-suryati-s"
                              class="card-categories-li-content"
                              >Enung Suryati S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=enykojiro"
                              class="card-categories-li-content"
                              >ENYKOJIRO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=equita-millianda"
                              class="card-categories-li-content"
                              >Equita Millianda(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eric-barker"
                              class="card-categories-li-content"
                              >Eric Barker(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eric-j-simon-jean-l-dickey-kelly-a-hogan-jane-b-reece"
                              class="card-categories-li-content"
                              >Eric J. Simon-Jean L. Dickey-Kelly A. Hogan-Jane
                              B. Reece(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eric-schulz"
                              class="card-categories-li-content"
                              >Eric Schulz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eric-weiner"
                              class="card-categories-li-content"
                              >Eric Weiner(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erica-d-dan-warner-m"
                              class="card-categories-li-content"
                              >Erica D & Warner M(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erich-a-helfert-d-ba"
                              class="card-categories-li-content"
                              >Erich A. Helfert, D. Ba.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erik-wijaya"
                              class="card-categories-li-content"
                              >Erik Wijaya(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eriko-ono"
                              class="card-categories-li-content"
                              >Eriko Ono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erin-watt"
                              class="card-categories-li-content"
                              >ERIN WATT(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erisca-febriani"
                              class="card-categories-li-content"
                              >Erisca Febriani(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erlangga"
                              class="card-categories-li-content"
                              >Erlangga(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erlina-mmsi"
                              class="card-categories-li-content"
                              >Erlina, Mmsi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erlinda-seto-mulyadi"
                              class="card-categories-li-content"
                              >Erlinda-Seto Mulyadi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=erna-fitrini"
                              class="card-categories-li-content"
                              >Erna Fitrini(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ernest-f-haeussler-dkk"
                              class="card-categories-li-content"
                              >Ernest F. Haeussler, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ernst-burden"
                              class="card-categories-li-content"
                              >Ernst Burden(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ernst-neufert"
                              class="card-categories-li-content"
                              >Ernst Neufert(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=errol-n-dan-john-s"
                              class="card-categories-li-content"
                              >Errol N & John S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=esensi"
                              class="card-categories-li-content"
                              >Esensi(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ester-lianawati"
                              class="card-categories-li-content"
                              >Ester Lianawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=esti-nugraheni"
                              class="card-categories-li-content"
                              >Esti Nugraheni(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ets"
                              class="card-categories-li-content"
                              >Ets(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eugene-ehrlich"
                              class="card-categories-li-content"
                              >Eugene Ehrlich(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eugene-f-bringham"
                              class="card-categories-li-content"
                              >Eugene F. Bringham(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=evi-novriyanti"
                              class="card-categories-li-content"
                              >EVI NOVRIYANTI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=evi-pratami"
                              class="card-categories-li-content"
                              >Evi Pratami(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=eviimbow"
                              class="card-categories-li-content"
                              >eviimbow(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=evita-nuh-june-paski-sabila-a"
                              class="card-categories-li-content"
                              >EVITA NUH, JUNE PASKI, SABILA A(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=exo-squad"
                              class="card-categories-li-content"
                              >EXO Squad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=f-wigbout-ing"
                              class="card-categories-li-content"
                              >F.Wigbout Ing(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fadila-hanum"
                              class="card-categories-li-content"
                              >Fadila Hanum(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=faisal-h-basri"
                              class="card-categories-li-content"
                              >Faisal H.Basri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fajar-natsutomo"
                              class="card-categories-li-content"
                              >FAJAR NATSUTOMO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fakhrishina-amalia"
                              class="card-categories-li-content"
                              >Fakhrishina Amalia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fanny-fatullah"
                              class="card-categories-li-content"
                              >Fanny Fatullah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=faradita"
                              class="card-categories-li-content"
                              >FARADITA(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=farah-margaretha"
                              class="card-categories-li-content"
                              >Farah Margaretha(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=farid-wajdi"
                              class="card-categories-li-content"
                              >Farid Wajdi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fatchiyah"
                              class="card-categories-li-content"
                              >Fatchiyah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fatkhul-a-umar-s"
                              class="card-categories-li-content"
                              >Fatkhul A.-Umar S.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fatmah"
                              class="card-categories-li-content"
                              >Fatmah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=felicity-brooks"
                              class="card-categories-li-content"
                              >Felicity Brooks(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=felix-k-nesi"
                              class="card-categories-li-content"
                              >Felix K. Nesi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fifi-afiah"
                              class="card-categories-li-content"
                              >Fifi Afiah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fiona-smith"
                              class="card-categories-li-content"
                              >Fiona Smith(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fiona-watt"
                              class="card-categories-li-content"
                              >Fiona Watt(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=firman-hamdani-dkk"
                              class="card-categories-li-content"
                              >Firman Hamdani, Dkk(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fitri-fauziah"
                              class="card-categories-li-content"
                              >Fitri Fauziah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fitri-normasari-dkk-php-00"
                              class="card-categories-li-content"
                              >Fitri Normasari,Dkk.php%00(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fitria-chakrawati"
                              class="card-categories-li-content"
                              >Fitria Chakrawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fitriyan-g-dennis"
                              class="card-categories-li-content"
                              >Fitriyan G. Dennis(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=forgottenglimmer"
                              class="card-categories-li-content"
                              >forgottenglimmer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=forum-alumni-mep-australia-indonesia"
                              class="card-categories-li-content"
                              >Forum Alumni MEP Australia-Indonesia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=francine-jay"
                              class="card-categories-li-content"
                              >Francine Jay(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=francis"
                              class="card-categories-li-content"
                              >Francis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=francis-d-k-ching"
                              class="card-categories-li-content"
                              >Francis D.K. Ching(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=francis-d-k-ching"
                              class="card-categories-li-content"
                              >Francis D.K. Ching(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=frank-ayres-jr-ph-d"
                              class="card-categories-li-content"
                              >Frank Ayres Jr. Ph.D.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=frank-ching"
                              class="card-categories-li-content"
                              >Frank Ching(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=frans-wade"
                              class="card-categories-li-content"
                              >Frans Wade(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fransiska-s-dkk"
                              class="card-categories-li-content"
                              >Fransiska S., Dkk(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fransiska-s-indah-sofiani-astrid-w"
                              class="card-categories-li-content"
                              >Fransiska S.-Indah Sofiani-Astrid W.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fransiska-indah-astrid"
                              class="card-categories-li-content"
                              >Fransiska-Indah-Astrid(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=franz-dwiono"
                              class="card-categories-li-content"
                              >Franz Dwiono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fraser-p-seitel"
                              class="card-categories-li-content"
                              >Fraser P. Seitel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=frederick-e-ginseck"
                              class="card-categories-li-content"
                              >Frederick E.Ginseck(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=frederick-s-hilli"
                              class="card-categories-li-content"
                              >Frederick S.Hilli(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=friedman-schustack"
                              class="card-categories-li-content"
                              >Friedman-Schustack(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=fumio-sasaki"
                              class="card-categories-li-content"
                              >Fumio Sasaki(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gabungan-paket"
                              class="card-categories-li-content"
                              >Gabungan (Paket)(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=garry-dessler"
                              class="card-categories-li-content"
                              >Garry Dessler(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gaza-saleh-ali-auwines"
                              class="card-categories-li-content"
                              >GAZA SALEH ALI AUWINES(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=geof-smith"
                              class="card-categories-li-content"
                              >Geof Smith(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=geoff-hiscock"
                              class="card-categories-li-content"
                              >Geoff Hiscock(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=george-b-thomas-jr"
                              class="card-categories-li-content"
                              >George B. Thomas, Jr(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=george-clayton"
                              class="card-categories-li-content"
                              >George Clayton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=george-e-dieter"
                              class="card-categories-li-content"
                              >George E. Dieter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=george-orwell"
                              class="card-categories-li-content"
                              >George Orwell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=george-v-lawry"
                              class="card-categories-li-content"
                              >George V. Lawry(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gerry-rudy"
                              class="card-categories-li-content"
                              >GERRY RUDY(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gibson"
                              class="card-categories-li-content"
                              >Gibson(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gilbert-a-curcil"
                              class="card-categories-li-content"
                              >Gilbert A.Curcil(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gillespie-dan-bamford"
                              class="card-categories-li-content"
                              >Gillespie & Bamford(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gina-gold"
                              class="card-categories-li-content"
                              >Gina Gold(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ginsberg"
                              class="card-categories-li-content"
                              >Ginsberg(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=golden-books"
                              class="card-categories-li-content"
                              >Golden Books(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=golrida-karyawati"
                              class="card-categories-li-content"
                              >Golrida Karyawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gracia-rianti"
                              class="card-categories-li-content"
                              >Gracia Rianti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=grant-stewart"
                              class="card-categories-li-content"
                              >Grant Stewart(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=greenberg"
                              class="card-categories-li-content"
                              >Greenberg(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gregory"
                              class="card-categories-li-content"
                              >Gregory(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gregory-mankiw"
                              class="card-categories-li-content"
                              >Gregory Mankiw(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=griffin"
                              class="card-categories-li-content"
                              >Griffin(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=guillaume-musso"
                              class="card-categories-li-content"
                              >Guillaume Musso(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunanto-wini-kristianti"
                              class="card-categories-li-content"
                              >Gunanto- Wini Kristianti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunanto-dhesy-adhalia"
                              class="card-categories-li-content"
                              >Gunanto-Dhesy Adhalia(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunanto-dhesy-adhalia"
                              class="card-categories-li-content"
                              >Gunanto-Dhesy Adhalia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunanto-wini-kristanti"
                              class="card-categories-li-content"
                              >GUNANTO-WINI KRISTANTI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunanto-wini-kristianti"
                              class="card-categories-li-content"
                              >Gunanto-Wini Kristianti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gunawan-dan-abi-lingga"
                              class="card-categories-li-content"
                              >GUNAWAN & ABI LINGGA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gustaman-s-dan-cosmas-s"
                              class="card-categories-li-content"
                              >Gustaman S. & Cosmas S.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=gwyne-richards"
                              class="card-categories-li-content"
                              >Gwyne Richards(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-ali-nursidi-lc"
                              class="card-categories-li-content"
                              >H. Ali Nursidi. Lc(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-jamhari-tasimin"
                              class="card-categories-li-content"
                              >H. Jamhari - Tasimin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-muchtar"
                              class="card-categories-li-content"
                              >H. MUCHTAR(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-musafak-dkk"
                              class="card-categories-li-content"
                              >H. Musafak, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-suwatno"
                              class="card-categories-li-content"
                              >H. Suwatno(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=h-zulfika-satria-kol-tni-kusuma"
                              class="card-categories-li-content"
                              >H. Zulfika Satria-Kol. Tni Kusuma(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hachete-jeunesse"
                              class="card-categories-li-content"
                              >Hachete Jeunesse(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hachette-jeunesse"
                              class="card-categories-li-content"
                              >Hachette Jeunesse(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hadi-satyagraha"
                              class="card-categories-li-content"
                              >Hadi Satyagraha(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hadiyatullah"
                              class="card-categories-li-content"
                              >Hadiyatullah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=haemin-sunim"
                              class="card-categories-li-content"
                              >Haemin Sunim(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=halliday"
                              class="card-categories-li-content"
                              >Halliday(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=halliday-resnick"
                              class="card-categories-li-content"
                              >Halliday-Resnick(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=halliday-resnick-walker"
                              class="card-categories-li-content"
                              >Halliday-Resnick-Walker(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hamka-haq"
                              class="card-categories-li-content"
                              >Hamka Haq(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hamlyn-octopus"
                              class="card-categories-li-content"
                              >Hamlyn Octopus(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hanif-nurcholis"
                              class="card-categories-li-content"
                              >Hanif Nurcholis(9)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hanif-nurcholis-nurkhozin"
                              class="card-categories-li-content"
                              >Hanif Nurcholis-Nurkhozin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hanny-handayani"
                              class="card-categories-li-content"
                              >Hanny Handayani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hanum-salsabiela-rais"
                              class="card-categories-li-content"
                              >Hanum Salsabiela Rais(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=harinaldi-budiarso"
                              class="card-categories-li-content"
                              >Harinaldi-Budiarso(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=harper-collins"
                              class="card-categories-li-content"
                              >Harper Collins(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=harper-w-boyd-jr"
                              class="card-categories-li-content"
                              >Harper W. Boyd, Jr(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hartati-n-papafragos"
                              class="card-categories-li-content"
                              >Hartati N. Papafragos(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=haruki-murakami"
                              class="card-categories-li-content"
                              >Haruki Murakami(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=harvard-business-school"
                              class="card-categories-li-content"
                              >Harvard Business School(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=harwood"
                              class="card-categories-li-content"
                              >Harwood(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=haryanto"
                              class="card-categories-li-content"
                              >Haryanto(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=haryanto-drs"
                              class="card-categories-li-content"
                              >Haryanto, Drs(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=haryo-tamtomo"
                              class="card-categories-li-content"
                              >Haryo Tamtomo(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hasan-saefullah"
                              class="card-categories-li-content"
                              >Hasan Saefullah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hasan-saefullah-m-ag"
                              class="card-categories-li-content"
                              >Hasan Saefullah, M.Ag(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hasan-saifullah"
                              class="card-categories-li-content"
                              >Hasan Saifullah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hazel-maskell"
                              class="card-categories-li-content"
                              >Hazel Maskell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hearn-lafcadio"
                              class="card-categories-li-content"
                              >Hearn, Lafcadio (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=helen-wightman"
                              class="card-categories-li-content"
                              >Helen Wightman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=helmi-r-willia-s"
                              class="card-categories-li-content"
                              >Helmi R.-Willia S.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=helyantini-soetopo"
                              class="card-categories-li-content"
                              >Helyantini Soetopo(29)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=helyantini-soetopo"
                              class="card-categories-li-content"
                              >Helyantini Soetopo(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hendra-susanto-spd"
                              class="card-categories-li-content"
                              >Hendra Susanto, Spd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hendrick-tanuwijaya"
                              class="card-categories-li-content"
                              >HENDRICK TANUWIJAYA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hendro-santono"
                              class="card-categories-li-content"
                              >Hendro Santono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hengki-ferdianto"
                              class="card-categories-li-content"
                              >Hengki Ferdianto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heni-nurhayani"
                              class="card-categories-li-content"
                              >Heni Nurhayani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hening-pratiwi"
                              class="card-categories-li-content"
                              >Hening Pratiwi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=henry-pandia"
                              class="card-categories-li-content"
                              >Henry Pandia(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heri-prasetyo"
                              class="card-categories-li-content"
                              >Heri Prasetyo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heri-purwono"
                              class="card-categories-li-content"
                              >Heri Purwono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heru-effendy"
                              class="card-categories-li-content"
                              >Heru Effendy(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heru-k-dan-dian-wahyu-sri-lestari"
                              class="card-categories-li-content"
                              >Heru K & Dian Wahyu Sri Lestari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heru-kurniawan"
                              class="card-categories-li-content"
                              >Heru Kurniawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heru-kurniawan-dan-feny-nida-fitriyani"
                              class="card-categories-li-content"
                              >Heru Kurniawan & Feny Nida Fitriyani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heryanto"
                              class="card-categories-li-content"
                              >Heryanto(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=heryanto-s-pd-m-si"
                              class="card-categories-li-content"
                              >Heryanto, S.Pd., M.Si(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hesti-dwi-rachmawati"
                              class="card-categories-li-content"
                              >Hesti Dwi Rachmawati(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hilda-karli-m-pd"
                              class="card-categories-li-content"
                              >Hilda Karli M.Pd(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=himawan"
                              class="card-categories-li-content"
                              >Himawan(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=himawan-siti-rochani-sri-mintarti"
                              class="card-categories-li-content"
                              >Himawan-Siti Rochani-Sri Mintarti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hindraswari"
                              class="card-categories-li-content"
                              >Hindraswari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hindraswari-enggar-dwipeni"
                              class="card-categories-li-content"
                              >Hindraswari Enggar Dwipeni(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hirotaka-akagi"
                              class="card-categories-li-content"
                              >HIROTAKA AKAGI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hj-andy-suwaji"
                              class="card-categories-li-content"
                              >Hj. Andy - Suwaji(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hj-andy-s-dkk"
                              class="card-categories-li-content"
                              >Hj. Andy S, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hj-andy-dkk"
                              class="card-categories-li-content"
                              >Hj. Andy, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hj-iim-halimah-dkk"
                              class="card-categories-li-content"
                              >Hj. Iim Halimah,Dkk(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=holifurrahman-s-ag-dkk"
                              class="card-categories-li-content"
                              >Holifurrahman, S.Ag., Dkk.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=holmes"
                              class="card-categories-li-content"
                              >Holmes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=holowenko-ar"
                              class="card-categories-li-content"
                              >Holowenko Ar.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=horngren-dan-harrison"
                              class="card-categories-li-content"
                              >Horngren & Harrison(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=horngren-sundem"
                              class="card-categories-li-content"
                              >Horngren, Sundem(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hotma-p-sibuea"
                              class="card-categories-li-content"
                              >Hotma P. Sibuea(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hotma-sibuea"
                              class="card-categories-li-content"
                              >Hotma Sibuea(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=howard-anton"
                              class="card-categories-li-content"
                              >Howard Anton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=huan-h-gray"
                              class="card-categories-li-content"
                              >Huan H.Gray(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hudy-jayanata"
                              class="card-categories-li-content"
                              >Hudy Jayanata(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hugh-d-young"
                              class="card-categories-li-content"
                              >Hugh D.Young(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=huh-kook-hwa-dan-park-kyung-ran"
                              class="card-categories-li-content"
                              >Huh Kook Hwa & Park Kyung Ran(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=husein-tampomas"
                              class="card-categories-li-content"
                              >Husein Tampomas(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=husin-eni-rita-zahara"
                              class="card-categories-li-content"
                              >Husin-Eni Rita Zahara(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=husin-rustamaji"
                              class="card-categories-li-content"
                              >Husin-Rustamaji(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hwei-hsu"
                              class="card-categories-li-content"
                              >Hwei Hsu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=hwie-in-indira"
                              class="card-categories-li-content"
                              >Hwie In Indira(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=i-made-sudana"
                              class="card-categories-li-content"
                              >I Made Sudana(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=i-nyoman-surna"
                              class="card-categories-li-content"
                              >I Nyoman Surna(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=i-wayan-badrika"
                              class="card-categories-li-content"
                              >I Wayan Badrika(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=i-d-sobandi"
                              class="card-categories-li-content"
                              >I.D. Sobandi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ialf"
                              class="card-categories-li-content"
                              >Ialf(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ian-peate"
                              class="card-categories-li-content"
                              >Ian Peate(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ibnu-burdah"
                              class="card-categories-li-content"
                              >Ibnu Burdah(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=icha-irawan"
                              class="card-categories-li-content"
                              >Icha Irawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ida-ayu-saraswati"
                              class="card-categories-li-content"
                              >Ida Ayu Saraswati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ika-natassa"
                              class="card-categories-li-content"
                              >Ika Natassa(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ilana-tan"
                              class="card-categories-li-content"
                              >Ilana Tan(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=im-tri-suyoto-achmad-sapari"
                              class="card-categories-li-content"
                              >Im Tri Suyoto-Achmad Sapari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ima-rohimah"
                              class="card-categories-li-content"
                              >Ima Rohimah(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ima-rohimah"
                              class="card-categories-li-content"
                              >Ima Rohimah(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=imam-zainuri-spd"
                              class="card-categories-li-content"
                              >Imam Zainuri, Spd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=iman-soeharto"
                              class="card-categories-li-content"
                              >Iman Soeharto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=imdadun-rahmat"
                              class="card-categories-li-content"
                              >Imdadun Rahmat(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=indira"
                              class="card-categories-li-content"
                              >Indira(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=indra-bastian"
                              class="card-categories-li-content"
                              >Indra Bastian(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=indriati-agung-r-m-azhar-m"
                              class="card-categories-li-content"
                              >Indriati Agung R.-M. Azhar M.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=inkina-oktari"
                              class="card-categories-li-content"
                              >INKINA OKTARI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=insan-mulyardewi"
                              class="card-categories-li-content"
                              >Insan Mulyardewi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=intari-dyah-pramudita"
                              class="card-categories-li-content"
                              >Intari Dyah Pramudita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ir-jimmy-s"
                              class="card-categories-li-content"
                              >Ir. Jimmy S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ir-tikno-iensufiie"
                              class="card-categories-li-content"
                              >Ir. Tikno Iensufiie(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ira-lathief"
                              class="card-categories-li-content"
                              >Ira Lathief(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-kilpatrick"
                              class="card-categories-li-content"
                              >Irene Kilpatrick(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-m-j-a"
                              class="card-categories-li-content"
                              >Irene M.J.A(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-m-j-a-dkk"
                              class="card-categories-li-content"
                              >Irene M.J.A.-Dkk(20)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-m-j-a-dkk"
                              class="card-categories-li-content"
                              >Irene M.J.A.-Dkk(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-m-j-a-khristiyono-nani-rosdijati"
                              class="card-categories-li-content"
                              >Irene M.J.A.-Khristiyono-Nani Rosdijati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-maria-dan-hilda-karli"
                              class="card-categories-li-content"
                              >Irene Maria & Hilda Karli(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-maria-juli-astuti"
                              class="card-categories-li-content"
                              >Irene Maria Juli Astuti(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-mja"
                              class="card-categories-li-content"
                              >Irene Mja(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-mja"
                              class="card-categories-li-content"
                              >Irene Mja(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-mja-dwi-tyas-wini-k"
                              class="card-categories-li-content"
                              >Irene Mja-Dwi Tyas-Wini K.(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-mja-hilda-k-khristiyono-p-s"
                              class="card-categories-li-content"
                              >Irene Mja-Hilda K.-Khristiyono P.S.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-mja-khristiyono-p-s"
                              class="card-categories-li-content"
                              >Irene Mja-Khristiyono P.S.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irene-dkk"
                              class="card-categories-li-content"
                              >Irene, Dkk(14)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=irna-nur-baiti"
                              class="card-categories-li-content"
                              >Irna Nur Baiti(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ismail-solihin"
                              class="card-categories-li-content"
                              >Ismail Solihin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=israr-ardiansyah"
                              class="card-categories-li-content"
                              >Israr Ardiansyah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=istamar-syamsuri"
                              class="card-categories-li-content"
                              >Istamar Syamsuri(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=istamar-syamsuri-dkk"
                              class="card-categories-li-content"
                              >Istamar Syamsuri,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ita-syuri"
                              class="card-categories-li-content"
                              >Ita Syuri(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=iva-hardiana"
                              class="card-categories-li-content"
                              >Iva Hardiana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=iwan-yuswandi"
                              class="card-categories-li-content"
                              >Iwan Yuswandi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=izzul-fitriyah"
                              class="card-categories-li-content"
                              >Izzul Fitriyah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-paul-peter"
                              class="card-categories-li-content"
                              >J. Paul Peter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-supranto"
                              class="card-categories-li-content"
                              >J. Supranto(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-e-prawitasari"
                              class="card-categories-li-content"
                              >J.E. Prawitasari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-k-rowling"
                              class="card-categories-li-content"
                              >J.K Rowling(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-m-c-johari"
                              class="card-categories-li-content"
                              >J.M.C. Johari(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-m-c-johari-meniek-rachmawati"
                              class="card-categories-li-content"
                              >J.M.C. Johari-Meniek Rachmawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-r-r-tolkien"
                              class="card-categories-li-content"
                              >J.r.r Tolkien(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=j-s-khairen"
                              class="card-categories-li-content"
                              >J.S. Khairen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jack-challoner"
                              class="card-categories-li-content"
                              >Jack Challoner(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jack-cormact"
                              class="card-categories-li-content"
                              >Jack Cormact(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jack-hirshleifer"
                              class="card-categories-li-content"
                              >Jack Hirshleifer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jack-kerouac"
                              class="card-categories-li-content"
                              >Jack Kerouac(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jack-trout"
                              class="card-categories-li-content"
                              >Jack Trout(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jackie-silberg"
                              class="card-categories-li-content"
                              >Jackie Silberg(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jacob-grey"
                              class="card-categories-li-content"
                              >Jacob Grey(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jacobus-dwihartanto"
                              class="card-categories-li-content"
                              >Jacobus Dwihartanto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jafar-suryomenggolo"
                              class="card-categories-li-content"
                              >Jafar Suryomenggolo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jamal-al-banna"
                              class="card-categories-li-content"
                              >Jamal Al-Banna(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-c-collins"
                              class="card-categories-li-content"
                              >James C. Collins(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-c-snyder"
                              class="card-categories-li-content"
                              >James C. Snyder(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-c-van-horne"
                              class="card-categories-li-content"
                              >James C. Van Horne(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-henslin"
                              class="card-categories-li-content"
                              >James Henslin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-r-welty"
                              class="card-categories-li-content"
                              >James R. Welty(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-stewart"
                              class="card-categories-li-content"
                              >James Stewart(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=james-t-mc-clave-dkk"
                              class="card-categories-li-content"
                              >James T. Mc Clave, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jamhari-dkk"
                              class="card-categories-li-content"
                              >Jamhari, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jamille-nagtalon-ramos"
                              class="card-categories-li-content"
                              >Jamille-Nagtalon-Ramos(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jamille-nagtalon-ramos"
                              class="card-categories-li-content"
                              >Jamille-Nagtalon-Ramos(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jan-clark"
                              class="card-categories-li-content"
                              >Jan Clark(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jan-ivens"
                              class="card-categories-li-content"
                              >Jan Ivens(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jan-krebs"
                              class="card-categories-li-content"
                              >Jan Krebs(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jane-bull"
                              class="card-categories-li-content"
                              >Jane Bull(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jane-chumbley"
                              class="card-categories-li-content"
                              >Jane Chumbley(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jane-e-darling"
                              class="card-categories-li-content"
                              >Jane E. Darling(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=janet-wright"
                              class="card-categories-li-content"
                              >Janet Wright(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=janette-marshall"
                              class="card-categories-li-content"
                              >Janette Marshall(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=janice-jones"
                              class="card-categories-li-content"
                              >Janice Jones(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=japan-foundation"
                              class="card-categories-li-content"
                              >Japan Foundation(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jasman-pardede"
                              class="card-categories-li-content"
                              >Jasman Pardede(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jason-fruchter"
                              class="card-categories-li-content"
                              >Jason Fruchter(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jean-e-weber"
                              class="card-categories-li-content"
                              >Jean E. Weber(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeanne-ellis-ormrod"
                              class="card-categories-li-content"
                              >Jeanne Ellis Ormrod(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jee-luvina"
                              class="card-categories-li-content"
                              >Jee Luvina(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeffrey-j-fox"
                              class="card-categories-li-content"
                              >Jeffrey J.Fox(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeffrey-liker"
                              class="card-categories-li-content"
                              >Jeffrey Liker(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeffrey-s-nevid"
                              class="card-categories-li-content"
                              >Jeffrey S. Nevid(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jein-setiyantohendri"
                              class="card-categories-li-content"
                              >Jein (@setiyantohendri)(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jenn-bennett"
                              class="card-categories-li-content"
                              >Jenn Bennett(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jenny-js-sondakh"
                              class="card-categories-li-content"
                              >Jenny Js Sondakh(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeremy-bullmore"
                              class="card-categories-li-content"
                              >Jeremy Bullmore(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeremy-cherfas"
                              class="card-categories-li-content"
                              >Jeremy Cherfas(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jeremy-s-ramsden"
                              class="card-categories-li-content"
                              >Jeremy S. Ramsden(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jerome-polin-sijabat"
                              class="card-categories-li-content"
                              >Jerome Polin Sijabat(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jessica-fox"
                              class="card-categories-li-content"
                              >Jessica Fox(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jessica-jung"
                              class="card-categories-li-content"
                              >Jessica Jung(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jevon-dan-ewens"
                              class="card-categories-li-content"
                              >Jevon & Ewens(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jill-blake"
                              class="card-categories-li-content"
                              >Jill Blake(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jim-barrett"
                              class="card-categories-li-content"
                              >Jim Barrett(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jim-helmore-karen-wall"
                              class="card-categories-li-content"
                              >Jim Helmore-Karen Wall(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jim-pipe"
                              class="card-categories-li-content"
                              >Jim Pipe(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jinny-johnson"
                              class="card-categories-li-content"
                              >Jinny Johnson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jiteng-marsudi"
                              class="card-categories-li-content"
                              >Jiteng Marsudi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jo-joo-hee"
                              class="card-categories-li-content"
                              >JO Joo-hee(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jo-reynolds"
                              class="card-categories-li-content"
                              >Jo Reynolds(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=joel-bakan"
                              class="card-categories-li-content"
                              >Joel Bakan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-c-mowen"
                              class="card-categories-li-content"
                              >John C. Mowen(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-gribbin"
                              class="card-categories-li-content"
                              >John Gribbin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-holt"
                              class="card-categories-li-content"
                              >John Holt(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-malam"
                              class="card-categories-li-content"
                              >John Malam(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-p-kotter"
                              class="card-categories-li-content"
                              >John P. Kotter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-rees"
                              class="card-categories-li-content"
                              >John Rees(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-van-walle"
                              class="card-categories-li-content"
                              >John Van Walle(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=john-w-santrock"
                              class="card-categories-li-content"
                              >John W. Santrock(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=johnny-ball"
                              class="card-categories-li-content"
                              >Johnny Ball(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=johnny-w-situmorang"
                              class="card-categories-li-content"
                              >Johnny W. Situmorang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=joko-pinurbo"
                              class="card-categories-li-content"
                              >Joko Pinurbo(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jonathan-gleadle"
                              class="card-categories-li-content"
                              >Jonathan Gleadle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jonathan-lorenz"
                              class="card-categories-li-content"
                              >Jonathan Lorenz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jong-i"
                              class="card-categories-li-content"
                              >Jong I(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jos-daniel-parera"
                              class="card-categories-li-content"
                              >Jos Daniel Parera(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=joseph-a-edminister"
                              class="card-categories-li-content"
                              >Joseph A. Edminister(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=joseph-w-wilkinson"
                              class="card-categories-li-content"
                              >Joseph W. Wilkinson(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=josephine-collins"
                              class="card-categories-li-content"
                              >Josephine Collins(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=js-pictures-dan-kim-mi-gyo"
                              class="card-categories-li-content"
                              >Js. Pictures & Kim Mi Gyo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=judith-wilson"
                              class="card-categories-li-content"
                              >Judith Wilson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=judy-golbraith"
                              class="card-categories-li-content"
                              >Judy Golbraith(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julia-hubery"
                              class="card-categories-li-content"
                              >Julia Hubery(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julia-onggo"
                              class="card-categories-li-content"
                              >Julia Onggo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julia-waterlow"
                              class="card-categories-li-content"
                              >Julia Waterlow(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julie-sykes"
                              class="card-categories-li-content"
                              >Julie Sykes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julien-neel"
                              class="card-categories-li-content"
                              >Julien Neel(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=julius-panero"
                              class="card-categories-li-content"
                              >Julius Panero(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=juna-bei"
                              class="card-categories-li-content"
                              >Juna Bei(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=junaidi-hidayat-dkk"
                              class="card-categories-li-content"
                              >Junaidi Hidayat Dkk(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=junanto-herdiawan"
                              class="card-categories-li-content"
                              >JUNANTO HERDIAWAN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=june-thompson"
                              class="card-categories-li-content"
                              >June Thompson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=jung-jessica"
                              class="card-categories-li-content"
                              >Jung, Jessica(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=junita"
                              class="card-categories-li-content"
                              >Junita(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=k-wardiyatmoko"
                              class="card-categories-li-content"
                              >K. Wardiyatmoko(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kahlil-gibran"
                              class="card-categories-li-content"
                              >KAHLIL GIBRAN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kalis-mardiasih"
                              class="card-categories-li-content"
                              >Kalis Mardiasih(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kappa-books"
                              class="card-categories-li-content"
                              >Kappa Books(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=karen-kingham"
                              class="card-categories-li-content"
                              >Karen Kingham(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=karen-m-mcmanus"
                              class="card-categories-li-content"
                              >Karen M. McManus(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=karl-marx"
                              class="card-categories-li-content"
                              >Karl Marx(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmina"
                              class="card-categories-li-content"
                              >Kasmina(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmina"
                              class="card-categories-li-content"
                              >Kasmina(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmina-asmaatul-kusna"
                              class="card-categories-li-content"
                              >Kasmina-Asmaatul Kusna(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmina-rustamaji-endrawati"
                              class="card-categories-li-content"
                              >Kasmina-Rustamaji-Endrawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmina-toali"
                              class="card-categories-li-content"
                              >Kasmina-Toali(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kasmir"
                              class="card-categories-li-content"
                              >Kasmir(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kate-knighton"
                              class="card-categories-li-content"
                              >Kate Knighton(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=katherine-shepard"
                              class="card-categories-li-content"
                              >Katherine Shepard(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kathy-carner"
                              class="card-categories-li-content"
                              >Kathy Carner(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kathy-o-beirne"
                              class="card-categories-li-content"
                              >Kathy O' Beirne(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kaur-rupi"
                              class="card-categories-li-content"
                              >Kaur, Rupi (2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kawaguchi-toshikazu"
                              class="card-categories-li-content"
                              >Kawaguchi, Toshikazu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kb-1196110800008"
                              class="card-categories-li-content"
                              >KB-1196110800008(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=keigo-higashino"
                              class="card-categories-li-content"
                              >Keigo Higashino(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=keith-davis"
                              class="card-categories-li-content"
                              >Keith Davis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ken-hurst"
                              class="card-categories-li-content"
                              >Ken Hurst(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ken-mogi"
                              class="card-categories-li-content"
                              >KEN MOGI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kent-wertime"
                              class="card-categories-li-content"
                              >Kent Wertime(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ketan-j-patel"
                              class="card-categories-li-content"
                              >Ketan J. Patel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ketut-silvanita"
                              class="card-categories-li-content"
                              >Ketut Silvanita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kevin-a-bybee"
                              class="card-categories-li-content"
                              >Kevin A. Bybee(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kevin-kwan"
                              class="card-categories-li-content"
                              >Kevin Kwan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kevindra-p-soemantri"
                              class="card-categories-li-content"
                              >Kevindra P. Soemantri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=khristiyono"
                              class="card-categories-li-content"
                              >Khristiyono(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=khristiyono"
                              class="card-categories-li-content"
                              >Khristiyono(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kidek-pingetania"
                              class="card-categories-li-content"
                              >Kidek Pingetania(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kim-dafies"
                              class="card-categories-li-content"
                              >Kim Dafies(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kim-eun-ju"
                              class="card-categories-li-content"
                              >Kim Eun Ju(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kim-rang"
                              class="card-categories-li-content"
                              >Kim Rang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kimbab-family"
                              class="card-categories-li-content"
                              >Kimbab Family(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kira-gembri"
                              class="card-categories-li-content"
                              >Kira Gembri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kl-management"
                              class="card-categories-li-content"
                              >KL Management(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=koi-dan-jui-dhan-akhir"
                              class="card-categories-li-content"
                              >KOI & JUI / DHAN AKHIR(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=komarudin-hidayat-khoiruddin-bashori"
                              class="card-categories-li-content"
                              >Komarudin Hidayat-Khoiruddin Bashori(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=konntz"
                              class="card-categories-li-content"
                              >Konntz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kourtney-kim-dan-khloe-kardashian"
                              class="card-categories-li-content"
                              >Kourtney, Kim & Khloe Kardashian(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kricskovics-zsuzsanna"
                              class="card-categories-li-content"
                              >Kricskovics Zsuzsanna(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kristine-carlson-dan-richard-carlson"
                              class="card-categories-li-content"
                              >Kristine Carlson & Richard Carlson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kristiyono"
                              class="card-categories-li-content"
                              >Kristiyono(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kristya-mintarja"
                              class="card-categories-li-content"
                              >Kristya Mintarja(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kun-maryati"
                              class="card-categories-li-content"
                              >Kun Maryati(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kun-maryati-juju-suryawati"
                              class="card-categories-li-content"
                              >Kun Maryati-Juju Suryawati(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kun-maryati-sr-ariawan"
                              class="card-categories-li-content"
                              >Kun Maryati-Sr Ariawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kunduri-ambar-setyorini"
                              class="card-categories-li-content"
                              >Kunduri - Ambar Setyorini(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kunduri-hj-sri-mintarti"
                              class="card-categories-li-content"
                              >Kunduri - Hj. Sri Mintarti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kunjana-rahardi"
                              class="card-categories-li-content"
                              >Kunjana Rahardi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kuntarti"
                              class="card-categories-li-content"
                              >Kuntarti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kuntarti-dkk"
                              class="card-categories-li-content"
                              >Kuntarti, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kurniawan"
                              class="card-categories-li-content"
                              >Kurniawan(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kurniawan-gunadi"
                              class="card-categories-li-content"
                              >Kurniawan Gunadi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kwantes-j"
                              class="card-categories-li-content"
                              >Kwantes J.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=kyung-sook-shin"
                              class="card-categories-li-content"
                              >Kyung Sook Shin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=laksmi-pamuntjak"
                              class="card-categories-li-content"
                              >Laksmi Pamuntjak(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lala-bohang"
                              class="card-categories-li-content"
                              >Lala Bohang(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lalu-abdul-mubarok"
                              class="card-categories-li-content"
                              >Lalu Abdul Mubarok(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=laode-insan"
                              class="card-categories-li-content"
                              >Laode Insan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lara-b-dan-corey-w"
                              class="card-categories-li-content"
                              >Lara B & Corey W(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lara-bergen"
                              class="card-categories-li-content"
                              >Lara Bergen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lara-bryan-rose-hall"
                              class="card-categories-li-content"
                              >Lara Bryan-Rose Hall(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=larasati"
                              class="card-categories-li-content"
                              >Larasati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=larry-king-bill-gilbert"
                              class="card-categories-li-content"
                              >Larry King, Bill Gilbert(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=laura-p-hartman"
                              class="card-categories-li-content"
                              >Laura P. Hartman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=laura-purdie-salas"
                              class="card-categories-li-content"
                              >Laura Purdie Salas(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=laura-thomas"
                              class="card-categories-li-content"
                              >Laura Thomas(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lauren-cecil"
                              class="card-categories-li-content"
                              >Lauren Cecil(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lauren-ho"
                              class="card-categories-li-content"
                              >Lauren Ho(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lauryn-silverhardt"
                              class="card-categories-li-content"
                              >Lauryn Silverhardt(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lawrence-g-friedman"
                              class="card-categories-li-content"
                              >Lawrence G.Friedman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leav-lang"
                              class="card-categories-li-content"
                              >Leav, Lang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lee-do-woo"
                              class="card-categories-li-content"
                              >Lee Do Woo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lee-kyu-young"
                              class="card-categories-li-content"
                              >Lee Kyu Young(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lee-sun-yong"
                              class="card-categories-li-content"
                              >Lee Sun Yong(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lehninger-a"
                              class="card-categories-li-content"
                              >Lehninger A.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leila-s-chudori"
                              class="card-categories-li-content"
                              >Leila S. Chudori(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leo-agung"
                              class="card-categories-li-content"
                              >Leo Agung(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leonard-j-kazmier"
                              class="card-categories-li-content"
                              >Leonard J.Kazmier(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leonie-pratt"
                              class="card-categories-li-content"
                              >Leonie Pratt(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lesley-rees"
                              class="card-categories-li-content"
                              >Lesley Rees(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leslie-doelle"
                              class="card-categories-li-content"
                              >Leslie Doelle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=leslle-valdes"
                              class="card-categories-li-content"
                              >Leslle Valdes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lexie-xu"
                              class="card-categories-li-content"
                              >Lexie Xu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=liang-chi-shen"
                              class="card-categories-li-content"
                              >Liang Chi Shen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lidwina-sri-ardiasih"
                              class="card-categories-li-content"
                              >Lidwina Sri Ardiasih(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ligiah-v-dan-alex-m"
                              class="card-categories-li-content"
                              >Ligiah V & Alex M(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=linda-christanty"
                              class="card-categories-li-content"
                              >Linda Christanty(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=linda-f-adimidjaja"
                              class="card-categories-li-content"
                              >Linda F. Adimidjaja(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=linda-heffner"
                              class="card-categories-li-content"
                              >Linda Heffner(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lippsmeijer-g"
                              class="card-categories-li-content"
                              >Lippsmeijer G.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lipschutz-s"
                              class="card-categories-li-content"
                              >Lipschutz S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lipsey"
                              class="card-categories-li-content"
                              >Lipsey(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lisa-genova"
                              class="card-categories-li-content"
                              >Lisa Genova(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lola-orett"
                              class="card-categories-li-content"
                              >Lola Orett(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lonnie-paceli"
                              class="card-categories-li-content"
                              >Lonnie Paceli(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=louisa-may-alcott"
                              class="card-categories-li-content"
                              >Louisa May Alcott(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=louise-alexander"
                              class="card-categories-li-content"
                              >Louise Alexander(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lovestock"
                              class="card-categories-li-content"
                              >Lovestock(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lucy-bowman"
                              class="card-categories-li-content"
                              >Lucy Bowman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lucy-broadbent"
                              class="card-categories-li-content"
                              >Lucy Broadbent(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lucy-mead"
                              class="card-categories-li-content"
                              >Lucy Mead(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=luiqi-garlando"
                              class="card-categories-li-content"
                              >Luiqi Garlando(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lukman-hakim"
                              class="card-categories-li-content"
                              >Lukman Hakim(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lulu-winarti"
                              class="card-categories-li-content"
                              >Lulu Winarti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=lusiana-rumintang"
                              class="card-categories-li-content"
                              >Lusiana Rumintang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-aan-mansyur"
                              class="card-categories-li-content"
                              >M. Aan Mansyur(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-abbas"
                              class="card-categories-li-content"
                              >M. Abbas(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-abdul-anan"
                              class="card-categories-li-content"
                              >M. Abdul Anan(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-amrullah"
                              class="card-categories-li-content"
                              >M. Amrullah(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-asikin-dkk"
                              class="card-categories-li-content"
                              >M. Asikin, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-azrul-tanjung"
                              class="card-categories-li-content"
                              >M. Azrul Tanjung(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-cholik-adinawan-muji-darmanto"
                              class="card-categories-li-content"
                              >M. Cholik Adinawan-Muji Darmanto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-cholik-adinawan"
                              class="card-categories-li-content"
                              >M. Cholik. Adinawan(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-ghufroni-thriwaty-arsal"
                              class="card-categories-li-content"
                              >M. GHUFRONI-THRIWATY ARSAL(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-khafid"
                              class="card-categories-li-content"
                              >M. Khafid(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-kholik"
                              class="card-categories-li-content"
                              >M. Kholik(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-taufiq-amir"
                              class="card-categories-li-content"
                              >M. TAUFIQ AMIR(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-alwi-suparman"
                              class="card-categories-li-content"
                              >M.Alwi Suparman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-ansari"
                              class="card-categories-li-content"
                              >M.Ansari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-khafid"
                              class="card-categories-li-content"
                              >M.Khafid(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-kholik"
                              class="card-categories-li-content"
                              >M.Kholik(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=m-ridho"
                              class="card-categories-li-content"
                              >M.Ridho(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=machrus-asad"
                              class="card-categories-li-content"
                              >Machrus As'Ad(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=machrus-asad"
                              class="card-categories-li-content"
                              >Machrus AsAd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=madame-carolyn-s"
                              class="card-categories-li-content"
                              >Madame Carolyn S(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=madame-carolyn-scrace"
                              class="card-categories-li-content"
                              >Madame Carolyn Scrace(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maggie-hull"
                              class="card-categories-li-content"
                              >Maggie Hull(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maggie-kneen"
                              class="card-categories-li-content"
                              >Maggie Kneen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maharani"
                              class="card-categories-li-content"
                              >Maharani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mahfud-ikhwan"
                              class="card-categories-li-content"
                              >Mahfud Ikhwan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mahmud-imrona"
                              class="card-categories-li-content"
                              >Mahmud Imrona(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mahrus-asad-dkk"
                              class="card-categories-li-content"
                              >Mahrus Asad, Dkk(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mahyus-ekananda"
                              class="card-categories-li-content"
                              >Mahyus Ekananda(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=makmun-gharib"
                              class="card-categories-li-content"
                              >Makmun Gharib(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=malik-thachir-dkk"
                              class="card-categories-li-content"
                              >Malik Thachir, Dkk(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=malvino-albert-paul-ph-d"
                              class="card-categories-li-content"
                              >Malvino Albert Paul, Ph.D.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maman-suherman"
                              class="card-categories-li-content"
                              >Maman Suherman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maman-sumanta-fitria-juwita-s"
                              class="card-categories-li-content"
                              >Maman Sumanta-Fitria Juwita S.(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mandal"
                              class="card-categories-li-content"
                              >Mandal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mangatas-siringoringo"
                              class="card-categories-li-content"
                              >Mangatas Siringoringo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marc-gobe"
                              class="card-categories-li-content"
                              >Marc Gobe(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marcel-van-assen"
                              class="card-categories-li-content"
                              >Marcel Van Assen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marcella-fp"
                              class="card-categories-li-content"
                              >Marcella Fp(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marchella-fp"
                              class="card-categories-li-content"
                              >Marchella FP(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marchella-pp"
                              class="card-categories-li-content"
                              >Marchella PP(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marci-beighley"
                              class="card-categories-li-content"
                              >Marci Beighley(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marcy-kelman"
                              class="card-categories-li-content"
                              >Marcy Kelman(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=margareth-wild"
                              class="card-categories-li-content"
                              >Margareth Wild(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=margot-richardson"
                              class="card-categories-li-content"
                              >Margot Richardson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mari-andrew"
                              class="card-categories-li-content"
                              >Mari Andrew(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-bintang"
                              class="card-categories-li-content"
                              >Maria Bintang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-bintang"
                              class="card-categories-li-content"
                              >Maria Bintang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-montessori"
                              class="card-categories-li-content"
                              >Maria Montessori(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-rossa"
                              class="card-categories-li-content"
                              >Maria Rossa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-shriver"
                              class="card-categories-li-content"
                              >Maria Shriver(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maria-susiati"
                              class="card-categories-li-content"
                              >Maria Susiati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marianne-klimchuk"
                              class="card-categories-li-content"
                              >Marianne Klimchuk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mariati"
                              class="card-categories-li-content"
                              >Mariati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mariati-atkah"
                              class="card-categories-li-content"
                              >MARIATI ATKAH(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marilyn-jackson"
                              class="card-categories-li-content"
                              >Marilyn Jackson(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mario-paz"
                              class="card-categories-li-content"
                              >Mario Paz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marion-dawidowski"
                              class="card-categories-li-content"
                              >Marion Dawidowski(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mariskova"
                              class="card-categories-li-content"
                              >Mariskova(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mark-a-garlick"
                              class="card-categories-li-content"
                              >Mark A. Garlick(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mark-manson"
                              class="card-categories-li-content"
                              >Mark Manson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=markplus-institute"
                              class="card-categories-li-content"
                              >Markplus Institute(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marlina-hambali"
                              class="card-categories-li-content"
                              >Marlina Hambali(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marlina-lin"
                              class="card-categories-li-content"
                              >Marlina Lin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marni-mcgee"
                              class="card-categories-li-content"
                              >Marni Mcgee(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marni-s-kep-ns-m-kes"
                              class="card-categories-li-content"
                              >Marni S.Kep., Ns., M.Kes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marsaid-s-pd-tutus-salman-farisi-s-pd"
                              class="card-categories-li-content"
                              >Marsaid, S.Pd-Tutus Salman Farisi, S.Pd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marsella-tina"
                              class="card-categories-li-content"
                              >Marsella Tina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marshall-e-dimock"
                              class="card-categories-li-content"
                              >Marshall E. Dimock(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marshall-sashkin"
                              class="card-categories-li-content"
                              >Marshall Sashkin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marsudi-raharjo"
                              class="card-categories-li-content"
                              >Marsudi Raharjo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marsudi-raharjo-andri-setiawan"
                              class="card-categories-li-content"
                              >MARSUDI RAHARJO-ANDRI SETIAWAN(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marthen-kanginan"
                              class="card-categories-li-content"
                              >Marthen Kanginan(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=marthen-kanginan"
                              class="card-categories-li-content"
                              >Marthen Kanginan(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=martin-perry"
                              class="card-categories-li-content"
                              >Martin Perry(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mary-jane"
                              class="card-categories-li-content"
                              >Mary Jane(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mary-tillworth"
                              class="card-categories-li-content"
                              >Mary Tillworth(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mas-aik"
                              class="card-categories-li-content"
                              >Mas Aik(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mas-tholle"
                              class="card-categories-li-content"
                              >Mas Tholle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=masashi-kishimoto"
                              class="card-categories-li-content"
                              >Masashi Kishimoto(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mashuri"
                              class="card-categories-li-content"
                              >MASHURI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=masrian-kunduri"
                              class="card-categories-li-content"
                              >Masrian - Kunduri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=masrian-dkk"
                              class="card-categories-li-content"
                              >Masrian, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=masrian-dkk"
                              class="card-categories-li-content"
                              >Masrian, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=masrian-aminarni-warso-sri-mintartidansukrisno"
                              class="card-categories-li-content"
                              >Masrian,Aminarni,Warso,Sri
                              Mintarti&Sukrisno(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=matroji"
                              class="card-categories-li-content"
                              >Matroji(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=matt-haig"
                              class="card-categories-li-content"
                              >Matt Haig(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=matz"
                              class="card-categories-li-content"
                              >Matz(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=maureen-raynor"
                              class="card-categories-li-content"
                              >Maureen Raynor(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mel-bakara"
                              class="card-categories-li-content"
                              >Mel Bakara(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=melina-marchita"
                              class="card-categories-li-content"
                              >Melina Marchita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=melissa-ann-sugeng"
                              class="card-categories-li-content"
                              >MELISSA ANN SUGENG(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mendenball"
                              class="card-categories-li-content"
                              >Mendenball(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=meng-tien-wu"
                              class="card-categories-li-content"
                              >Meng Tien Wu(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=meriam-jl"
                              class="card-categories-li-content"
                              >Meriam Jl.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=merle-c-potter"
                              class="card-categories-li-content"
                              >Merle C. Potter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=merlinda"
                              class="card-categories-li-content"
                              >Merlinda(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=meshvara-k-dan-yongky-s"
                              class="card-categories-li-content"
                              >Meshvara K. & Yongky S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mia-arsjad"
                              class="card-categories-li-content"
                              >Mia Arsjad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-a-hitt"
                              class="card-categories-li-content"
                              >Michael A.Hitt(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-bland"
                              class="card-categories-li-content"
                              >Michael Bland(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-collins"
                              class="card-categories-li-content"
                              >Michael Collins(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-dahl"
                              class="card-categories-li-content"
                              >Michael Dahl(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-j-moran"
                              class="card-categories-li-content"
                              >Michael J.Moran(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-mc-carthy"
                              class="card-categories-li-content"
                              >Michael Mc.Carthy(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-niedle"
                              class="card-categories-li-content"
                              >Michael Niedle(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-p-todaro"
                              class="card-categories-li-content"
                              >Michael P. Todaro(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-purba"
                              class="card-categories-li-content"
                              >Michael Purba(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-purba-aas-saidah"
                              class="card-categories-li-content"
                              >Michael Purba-Aas Saidah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-purba-eti-sarwiyati"
                              class="card-categories-li-content"
                              >Michael Purba-Eti Sarwiyati(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-s-allen"
                              class="card-categories-li-content"
                              >Michael S.Allen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michael-w-maher"
                              class="card-categories-li-content"
                              >Michael W. Maher(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=michelle-kennedy"
                              class="card-categories-li-content"
                              >Michelle Kennedy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mickie-matheis"
                              class="card-categories-li-content"
                              >Mickie Matheis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mickie-mathies"
                              class="card-categories-li-content"
                              >Mickie Mathies(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mieke-m-tia-mutiara-dewi-luvfiati"
                              class="card-categories-li-content"
                              >Mieke M-Tia Mutiara-Dewi Luvfiati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mieke-miarsyah"
                              class="card-categories-li-content"
                              >Mieke Miarsyah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mikael-johani"
                              class="card-categories-li-content"
                              >Mikael Johani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mike-moser"
                              class="card-categories-li-content"
                              >Mike Moser(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mike-ollerton"
                              class="card-categories-li-content"
                              >Mike Ollerton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mike-schmoker"
                              class="card-categories-li-content"
                              >Mike Schmoker(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mikrajuddin"
                              class="card-categories-li-content"
                              >Mikrajuddin(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=miles-kelly"
                              class="card-categories-li-content"
                              >Miles Kelly(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mintardjo"
                              class="card-categories-li-content"
                              >Mintardjo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=miranda-seftiana"
                              class="card-categories-li-content"
                              >Miranda Seftiana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mirdawani"
                              class="card-categories-li-content"
                              >Mirdawani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mirita-uberti"
                              class="card-categories-li-content"
                              >Mirita Uberti(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=misri-grozan"
                              class="card-categories-li-content"
                              >Misri Grozan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=miss-ulfa"
                              class="card-categories-li-content"
                              >Miss Ulfa(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=misstigri-dan-katell"
                              class="card-categories-li-content"
                              >Misstigri & Katell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=moch-andik"
                              class="card-categories-li-content"
                              >Moch. Andik(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=moch-doddy-ariefianto"
                              class="card-categories-li-content"
                              >Moch. Doddy Ariefianto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mochamad-anshory"
                              class="card-categories-li-content"
                              >Mochamad Anshory(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=moh-masrun-dkk"
                              class="card-categories-li-content"
                              >Moh. Masrun, Dkk(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mohamad-samsul"
                              class="card-categories-li-content"
                              >Mohamad Samsul(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mohammad-ibnu"
                              class="card-categories-li-content"
                              >Mohammad Ibnu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=molly-potter"
                              class="card-categories-li-content"
                              >Molly Potter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=molly-reisner"
                              class="card-categories-li-content"
                              >Molly Reisner(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mommy-asf"
                              class="card-categories-li-content"
                              >MOMMY ASF(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mommy-ioi-riescha-puri-gayatri"
                              class="card-categories-li-content"
                              >Mommy Ioi (Riescha Puri Gayatri)(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mondy"
                              class="card-categories-li-content"
                              >Mondy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mondy-risutra"
                              class="card-categories-li-content"
                              >Mondy Risutra(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=monty-duggal"
                              class="card-categories-li-content"
                              >Monty Duggal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=moore"
                              class="card-categories-li-content"
                              >Moore(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mooseboo"
                              class="card-categories-li-content"
                              >Mooseboo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=moran"
                              class="card-categories-li-content"
                              >Moran(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=morwena-brett"
                              class="card-categories-li-content"
                              >Morwena Brett(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mudrajad-kuncoro"
                              class="card-categories-li-content"
                              >Mudrajad Kuncoro(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mudrajat-kuncoro"
                              class="card-categories-li-content"
                              >Mudrajat Kuncoro(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=muhajir"
                              class="card-categories-li-content"
                              >Muhajir(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=muhammad-amrullah"
                              class="card-categories-li-content"
                              >Muhammad Amrullah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=muhammad-muhyidin"
                              class="card-categories-li-content"
                              >Muhammad Muhyidin(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=muhammad-sholikhin"
                              class="card-categories-li-content"
                              >Muhammad Sholikhin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mujar-ibnu-syarif-dan-khamami-za"
                              class="card-categories-li-content"
                              >Mujar Ibnu Syarif & Khamami Za(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mukarto"
                              class="card-categories-li-content"
                              >Mukarto(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mukarto-josephine-sri-m-sujatmiko-widya-k"
                              class="card-categories-li-content"
                              >Mukarto-Josephine Sri M.-Sujatmiko-Widya K.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mukarto-sujatmiko-josephine-widya"
                              class="card-categories-li-content"
                              >Mukarto-Sujatmiko-Josephine-Widya(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mukarto-sujatmiko-josephine-widya-k"
                              class="card-categories-li-content"
                              >Mukarto-Sujatmiko-Josephine-Widya K.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mulyadhi"
                              class="card-categories-li-content"
                              >Mulyadhi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=munika-hasibuan"
                              class="card-categories-li-content"
                              >Munika Hasibuan(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mura-litharan-nair"
                              class="card-categories-li-content"
                              >Mura Litharan Nair(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=murata-sayaka"
                              class="card-categories-li-content"
                              >Murata Sayaka(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=murray"
                              class="card-categories-li-content"
                              >Murray(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=murray-spiegel"
                              class="card-categories-li-content"
                              >Murray Spiegel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=musgrave"
                              class="card-categories-li-content"
                              >Musgrave(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mustafal-bakri"
                              class="card-categories-li-content"
                              >Mustafal Bakri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=musthafa-hadna"
                              class="card-categories-li-content"
                              >Musthafa Hadna(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mustofa-s-pd"
                              class="card-categories-li-content"
                              >Mustofa, S.Pd(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mutaalimah"
                              class="card-categories-li-content"
                              >Muta'Alimah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mutaalimah"
                              class="card-categories-li-content"
                              >MutaAlimah(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=mutiarini"
                              class="card-categories-li-content"
                              >Mutiarini(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=n-gregory-m"
                              class="card-categories-li-content"
                              >N. Gregory M.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=n-suparno-t-d-haryo-tamtomo"
                              class="card-categories-li-content"
                              >N. Suparno-T.D. Haryo Tamtomo(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=n-wahyu-triyono-muidu-muif"
                              class="card-categories-li-content"
                              >N. Wahyu Triyono-Muidu Muif(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=n-suparno-sri-pujiastuti-haryo-tamtomo"
                              class="card-categories-li-content"
                              >N.Suparno, Sri Pujiastuti, Haryo Tamtomo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=n-suparno-haryo-tamtomo"
                              class="card-categories-li-content"
                              >N.Suparno-Haryo Tamtomo(9)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=na-tae-joo"
                              class="card-categories-li-content"
                              >Na Tae Joo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=naala"
                              class="card-categories-li-content"
                              >NAALA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nadya-karima-melati"
                              class="card-categories-li-content"
                              >Nadya Karima Melati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=naela-ali"
                              class="card-categories-li-content"
                              >Naela Ali(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nancy-didona"
                              class="card-categories-li-content"
                              >Nancy Didona(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nancy-loewen"
                              class="card-categories-li-content"
                              >Nancy Loewen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nancy-oreyfuss"
                              class="card-categories-li-content"
                              >Nancy Oreyfuss(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nani-r-irma-murti-k"
                              class="card-categories-li-content"
                              >Nani R.-Irma Murti K.(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nani-r-irma-murti-k"
                              class="card-categories-li-content"
                              >Nani R.-Irma Murti K.(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=naomi-octavia"
                              class="card-categories-li-content"
                              >Naomi Octavia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=naresh-m-dan-james-c"
                              class="card-categories-li-content"
                              >Naresh M & James C(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nasikin-dkk"
                              class="card-categories-li-content"
                              >Nasikin, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nathalia-theodora"
                              class="card-categories-li-content"
                              >Nathalia Theodora(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=national-georaphic-kids"
                              class="card-categories-li-content"
                              >National Georaphic Kids(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=neal-shusterman-dan-jarrod-shusterman"
                              class="card-categories-li-content"
                              >NEAL SHUSTERMAN & JARROD SHUSTERMAN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=neil-tumbull"
                              class="card-categories-li-content"
                              >Neil Tumbull(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=neilbingham"
                              class="card-categories-li-content"
                              >Neilbingham(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nevid-rathus-greene"
                              class="card-categories-li-content"
                              >Nevid-Rathus-Greene(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=new-teaching-resource"
                              class="card-categories-li-content"
                              >New Teaching Resource(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ng-foo-mun-dkk"
                              class="card-categories-li-content"
                              >Ng Foo Mun, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ni-ketut-lasmi"
                              class="card-categories-li-content"
                              >Ni Ketut Lasmi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ni-ketut-lasmi"
                              class="card-categories-li-content"
                              >Ni Ketut Lasmi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nia-aw"
                              class="card-categories-li-content"
                              >Nia Aw(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nick-skellon"
                              class="card-categories-li-content"
                              >Nick Skellon(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nick-word"
                              class="card-categories-li-content"
                              >Nick Word(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nickelodeon"
                              class="card-categories-li-content"
                              >Nickelodeon(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nicola-graimes"
                              class="card-categories-li-content"
                              >Nicola Graimes(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nikol-kidman"
                              class="card-categories-li-content"
                              >Nikol Kidman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nikolas-simanjuntak"
                              class="card-categories-li-content"
                              >Nikolas Simanjuntak(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nina-ruriya"
                              class="card-categories-li-content"
                              >NINA RURIYA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nina-samidi"
                              class="card-categories-li-content"
                              >Nina Samidi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nishida-masaki"
                              class="card-categories-li-content"
                              >Nishida Masaki(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=noam-chomsky"
                              class="card-categories-li-content"
                              >NOAM CHOMSKY(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=non-figg"
                              class="card-categories-li-content"
                              >Non Figg(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=noorhadi"
                              class="card-categories-li-content"
                              >Noorhadi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=notmeforget"
                              class="card-categories-li-content"
                              >notmeforget(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nouf-zahrah-anastasia"
                              class="card-categories-li-content"
                              >Nouf Zahrah Anastasia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-hadi"
                              class="card-categories-li-content"
                              >Nur Hadi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-hadidanm-rifai"
                              class="card-categories-li-content"
                              >Nur Hadi&M. RifaI(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-hadi-sulasih-abdul-rokhim"
                              class="card-categories-li-content"
                              >Nur Hadi-Sulasih-Abdul Rokhim(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-khaliq-ridwan"
                              class="card-categories-li-content"
                              >Nur Khaliq Ridwan(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-zaida"
                              class="card-categories-li-content"
                              >Nur Zaida(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nur-zaida"
                              class="card-categories-li-content"
                              >Nur Zaida(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nuran-wibisono"
                              class="card-categories-li-content"
                              >Nuran Wibisono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nurhadi"
                              class="card-categories-li-content"
                              >Nurhadi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nurhadi-s-ag-m-pa"
                              class="card-categories-li-content"
                              >Nurhadi S.Ag.M.Pa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nursyamsuddin"
                              class="card-categories-li-content"
                              >Nursyamsuddin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=nyoman-aris-suparni"
                              class="card-categories-li-content"
                              >Nyoman Aris Suparni(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=o-callaghan"
                              class="card-categories-li-content"
                              >O Callaghan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=oeke-yunita"
                              class="card-categories-li-content"
                              >Oeke Yunita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ogata-k"
                              class="card-categories-li-content"
                              >Ogata K.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=okky-madasari"
                              class="card-categories-li-content"
                              >Okky Madasari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=olga-mecking"
                              class="card-categories-li-content"
                              >Olga Mecking(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=oliver"
                              class="card-categories-li-content"
                              >Oliver(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=omar-faiz"
                              class="card-categories-li-content"
                              >Omar Faiz(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ono-eriko"
                              class="card-categories-li-content"
                              >Ono Eriko(18)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=orizuka"
                              class="card-categories-li-content"
                              >Orizuka(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ormrod"
                              class="card-categories-li-content"
                              >Ormrod(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=osamu-dazai"
                              class="card-categories-li-content"
                              >Osamu Dazai(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=otong-surasman"
                              class="card-categories-li-content"
                              >Otong Surasman(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=oyasujiwo"
                              class="card-categories-li-content"
                              >OYASUJIWO(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=p-gendra-p"
                              class="card-categories-li-content"
                              >P. Gendra P.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=p-a-smith"
                              class="card-categories-li-content"
                              >P.A. Smith(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=p-w-atkins"
                              class="card-categories-li-content"
                              >P.W.Atkins(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pamela-druckerman"
                              class="card-categories-li-content"
                              >PAMELA DRUCKERMAN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pamela-lynn"
                              class="card-categories-li-content"
                              >Pamela Lynn(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=park-dong-sun"
                              class="card-categories-li-content"
                              >Park Dong Sun(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=park-joon"
                              class="card-categories-li-content"
                              >Park Joon(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=park-sung-woo-kim-hyo-eun"
                              class="card-categories-li-content"
                              >PARK, SUNG-WOO / KIM, HYO-EUN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=parker-littler"
                              class="card-categories-li-content"
                              >Parker Littler(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=parragon"
                              class="card-categories-li-content"
                              >Parragon(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=parragon-books"
                              class="card-categories-li-content"
                              >Parragon Books(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pascale-junker"
                              class="card-categories-li-content"
                              >Pascale Junker(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=patrick-davey"
                              class="card-categories-li-content"
                              >Patrick Davey(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=patrick-jank"
                              class="card-categories-li-content"
                              >Patrick Jank(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=patti-barber"
                              class="card-categories-li-content"
                              >Patti Barber(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-a-tipler"
                              class="card-categories-li-content"
                              >Paul A.Tipler(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-arden"
                              class="card-categories-li-content"
                              >Paul Arden(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-bennet-barbara-taylor"
                              class="card-categories-li-content"
                              >Paul Bennet, Barbara Taylor(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-bright"
                              class="card-categories-li-content"
                              >Paul Bright(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-green"
                              class="card-categories-li-content"
                              >Paul Green(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-hanna"
                              class="card-categories-li-content"
                              >Paul Hanna(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-laseau"
                              class="card-categories-li-content"
                              >Paul Laseau(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paul-strathern"
                              class="card-categories-li-content"
                              >Paul Strathern(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paula-polk-lillard"
                              class="card-categories-li-content"
                              >Paula Polk Lillard(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paulo-coelho"
                              class="card-categories-li-content"
                              >Paulo Coelho(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=paus-yohanes-paulus-ii"
                              class="card-categories-li-content"
                              >Paus Yohanes Paulus Ii(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pearson"
                              class="card-categories-li-content"
                              >Pearson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peckham"
                              class="card-categories-li-content"
                              >Peckham(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pelangi-tri-sakti"
                              class="card-categories-li-content"
                              >Pelangi Tri Sakti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=penelope-ody"
                              class="card-categories-li-content"
                              >Penelope Ody(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peng-hsueh-fen"
                              class="card-categories-li-content"
                              >Peng Hsueh Fen(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=penguin"
                              class="card-categories-li-content"
                              >Penguin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=penney-upton"
                              class="card-categories-li-content"
                              >Penney Upton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pere-sumbada"
                              class="card-categories-li-content"
                              >Pere Sumbada(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peter-bunzl"
                              class="card-categories-li-content"
                              >Peter Bunzl(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peter-knight"
                              class="card-categories-li-content"
                              >Peter Knight(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peter-scott"
                              class="card-categories-li-content"
                              >Peter Scott(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peter-spann"
                              class="card-categories-li-content"
                              >Peter Spann(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=peter-wylie"
                              class="card-categories-li-content"
                              >Peter Wylie(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=petrucci"
                              class="card-categories-li-content"
                              >Petrucci(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=phil-daurado"
                              class="card-categories-li-content"
                              >Phil Daurado(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=philip-dan-victoria-tebbs"
                              class="card-categories-li-content"
                              >Philip & Victoria Tebbs(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=philip-kotler"
                              class="card-categories-li-content"
                              >Philip Kotler(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=philip-steele"
                              class="card-categories-li-content"
                              >Philip Steele(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pia-devina"
                              class="card-categories-li-content"
                              >Pia Devina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pidi-baiq"
                              class="card-categories-li-content"
                              >Pidi Baiq(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pierce-a-g-dan-neil-r-b"
                              class="card-categories-li-content"
                              >Pierce A.G.& Neil R.B.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pierdomenico-baccalario"
                              class="card-categories-li-content"
                              >Pierdomenico Baccalario(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pierdominico-buccalario"
                              class="card-categories-li-content"
                              >Pierdominico Buccalario(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pindyck-dan-rubinfgld"
                              class="card-categories-li-content"
                              >Pindyck & Rubinfgld(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pinto-anugerah"
                              class="card-categories-li-content"
                              >Pinto Anugerah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=playfair-dan-chain"
                              class="card-categories-li-content"
                              >Playfair & Chain(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=poppy-suryaatmaja-w"
                              class="card-categories-li-content"
                              >Poppy Suryaatmaja W.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=poppy-reny-heny"
                              class="card-categories-li-content"
                              >Poppy-Reny-Heny(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pradip-r-patel"
                              class="card-categories-li-content"
                              >Pradip R. Patel(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prilly-latuconsina"
                              class="card-categories-li-content"
                              >PRILLY LATUCONSINA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=primary-literacy-team"
                              class="card-categories-li-content"
                              >Primary Literacy Team(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=priscilla-retnowati"
                              class="card-categories-li-content"
                              >Priscilla Retnowati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pristiadi-utomo"
                              class="card-categories-li-content"
                              >Pristiadi Utomo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-ahmad-erani-yustika-dkk"
                              class="card-categories-li-content"
                              >Prof. Ahmad Erani Yustika,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-andreas-lako"
                              class="card-categories-li-content"
                              >Prof. Andreas Lako(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-budi-winarno"
                              class="card-categories-li-content"
                              >Prof. Budi Winarno(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-djamaludin-ancok"
                              class="card-categories-li-content"
                              >Prof. Djamaludin Ancok(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-dr-h-achmad-h-p"
                              class="card-categories-li-content"
                              >Prof. Dr. H. Achmad H.P.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-stuart-cambell"
                              class="card-categories-li-content"
                              >Prof. Stuart Cambell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-dr-drh-maria-bintang-m-s"
                              class="card-categories-li-content"
                              >Prof.Dr.Drh. Maria Bintang, M.S(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-dr-erbreeht-dkk"
                              class="card-categories-li-content"
                              >Prof.Dr.Erbreeht,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prof-ir-edy-darmawan-m-tdanmaria-rosita-m-s-t"
                              class="card-categories-li-content"
                              >Prof.Ir.Edy Darmawan, M.T&Maria Rosita M,
                              S.T(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=prunella-bat"
                              class="card-categories-li-content"
                              >Prunella Bat(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=pukul-dua-malam"
                              class="card-categories-li-content"
                              >PUKUL DUA MALAM(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=purbayu"
                              class="card-categories-li-content"
                              >Purbayu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=purcell"
                              class="card-categories-li-content"
                              >Purcell(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=puthut-ea"
                              class="card-categories-li-content"
                              >Puthut EA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=putri-marino"
                              class="card-categories-li-content"
                              >Putri Marino(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=putri-sarah-olivia"
                              class="card-categories-li-content"
                              >Putri Sarah Olivia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=r-hani-handoko-dkk"
                              class="card-categories-li-content"
                              >R. Hani Handoko, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=r-kunjana-rahardi"
                              class="card-categories-li-content"
                              >R. Kunjana Rahardi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=r-a-day"
                              class="card-categories-li-content"
                              >R.A. Day(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=r-j-shook"
                              class="card-categories-li-content"
                              >R.J. Shook(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=r-susanto-dwi-nugroho-dkk"
                              class="card-categories-li-content"
                              >R.Susanto Dwi Nugroho, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rachael-caine"
                              class="card-categories-li-content"
                              >Rachael Caine(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=radin-azkia"
                              class="card-categories-li-content"
                              >Radin Azkia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rahayu-oktaviani"
                              class="card-categories-li-content"
                              >Rahayu Oktaviani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rahayu-s"
                              class="card-categories-li-content"
                              >Rahayu S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rahman-pura"
                              class="card-categories-li-content"
                              >Rahman Pura(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rahmat-cs"
                              class="card-categories-li-content"
                              >Rahmat Cs(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ramon-a-mata"
                              class="card-categories-li-content"
                              >Ramon A.Mata(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=randall-s-schuler"
                              class="card-categories-li-content"
                              >Randall S. Schuler(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ratna-hapsari-m-adil"
                              class="card-categories-li-content"
                              >Ratna Hapsari - M. Adil(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ratna-hapsari-m-adil"
                              class="card-categories-li-content"
                              >Ratna Hapsari - M. Adil(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ratna-hidayati"
                              class="card-categories-li-content"
                              >Ratna Hidayati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ratna-setyowati"
                              class="card-categories-li-content"
                              >Ratna Setyowati(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ratri-ninditya"
                              class="card-categories-li-content"
                              >Ratri Ninditya(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ray-gibson"
                              class="card-categories-li-content"
                              >Ray Gibson(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=raymond-chang"
                              class="card-categories-li-content"
                              >Raymond Chang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rebecca-craig"
                              class="card-categories-li-content"
                              >Rebecca Craig(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rebecca-fox-spencer"
                              class="card-categories-li-content"
                              >Rebecca Fox Spencer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rebecca-gilpin"
                              class="card-categories-li-content"
                              >Rebecca Gilpin(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rebecca-winter"
                              class="card-categories-li-content"
                              >Rebecca Winter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=reda-gaudiamo"
                              class="card-categories-li-content"
                              >Reda Gaudiamo (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=regis-machdy"
                              class="card-categories-li-content"
                              >Regis Machdy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rene-langga"
                              class="card-categories-li-content"
                              >Rene Langga(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rene-suhardono-intan-yamuna"
                              class="card-categories-li-content"
                              >Rene Suhardono, Intan Yamuna(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=renita"
                              class="card-categories-li-content"
                              >Renita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=reny-novita-ratnani"
                              class="card-categories-li-content"
                              >Reny Novita Ratnani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=resti-dahlan"
                              class="card-categories-li-content"
                              >Resti Dahlan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=retno-utami-dkk"
                              class="card-categories-li-content"
                              >Retno Utami,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=retno-w-sri-s"
                              class="card-categories-li-content"
                              >Retno W.-Sri S.(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=reverend-w-awdry"
                              class="card-categories-li-content"
                              >Reverend W. Awdry(39)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ria-sw"
                              class="card-categories-li-content"
                              >Ria SW(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-brealy"
                              class="card-categories-li-content"
                              >Richard Brealy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-carlson"
                              class="card-categories-li-content"
                              >Richard Carlson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-d-dan-ray-g"
                              class="card-categories-li-content"
                              >Richard D & Ray G.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-koch"
                              class="card-categories-li-content"
                              >Richard Koch(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-pelzer"
                              class="card-categories-li-content"
                              >Richard Pelzer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-templar"
                              class="card-categories-li-content"
                              >Richard Templar(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=richard-templar-nancy-dreyfus"
                              class="card-categories-li-content"
                              >Richard Templar+Nancy Dreyfus(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rien-nur-azizah"
                              class="card-categories-li-content"
                              >Rien Nur Azizah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rikar-lebang"
                              class="card-categories-li-content"
                              >RIKAR LEBANG(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=riliv"
                              class="card-categories-li-content"
                              >Riliv .(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rimla-lavari"
                              class="card-categories-li-content"
                              >Rimla Lavari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rina-puspita"
                              class="card-categories-li-content"
                              >Rina Puspita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rintik-sedu"
                              class="card-categories-li-content"
                              >Rintik Sedu(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rio-budi-prasadja-tan-dipl-to"
                              class="card-categories-li-content"
                              >Rio Budi Prasadja Tan, Dipl.To(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ririe-bogar"
                              class="card-categories-li-content"
                              >RIRIE BOGAR(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=riska-dewi"
                              class="card-categories-li-content"
                              >Riska Dewi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=risma-ridha-annisa"
                              class="card-categories-li-content"
                              >Risma Ridha Annisa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rizki-tri-martono"
                              class="card-categories-li-content"
                              >Rizki Tri Martono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roald-dahl"
                              class="card-categories-li-content"
                              >Roald Dahl(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rob-lyod-jones"
                              class="card-categories-li-content"
                              >Rob Lyod Jones(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robbins-dan-coulter"
                              class="card-categories-li-content"
                              >Robbins & Coulter(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robbins-coulter"
                              class="card-categories-li-content"
                              >Robbins-Coulter(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-a-baron"
                              class="card-categories-li-content"
                              >Robert A.Baron(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-aston"
                              class="card-categories-li-content"
                              >Robert Aston(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-d-mason"
                              class="card-categories-li-content"
                              >Robert D. Mason(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-f-cougblin"
                              class="card-categories-li-content"
                              >Robert F. Cougblin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-heller"
                              class="card-categories-li-content"
                              >Robert Heller(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-hendrik-liembono"
                              class="card-categories-li-content"
                              >Robert Hendrik Liembono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-j-gregory"
                              class="card-categories-li-content"
                              >Robert J. Gregory(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-m-grant"
                              class="card-categories-li-content"
                              >Robert M. Grant(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-pail-wolff"
                              class="card-categories-li-content"
                              >Robert Pail Wolff(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-solso"
                              class="card-categories-li-content"
                              >Robert Solso(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-t-kiyosaki"
                              class="card-categories-li-content"
                              >Robert T. Kiyosaki(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robert-w-bly"
                              class="card-categories-li-content"
                              >Robert W.Bly(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=robin-wilson"
                              class="card-categories-li-content"
                              >Robin Wilson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roger-g-schroeder"
                              class="card-categories-li-content"
                              >Roger G.Schroeder(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roger-m-keesing"
                              class="card-categories-li-content"
                              >Roger M. Keesing(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roger-williams"
                              class="card-categories-li-content"
                              >Roger Williams(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roidah"
                              class="card-categories-li-content"
                              >Roidah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rolf-heimann"
                              class="card-categories-li-content"
                              >Rolf Heimann(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ronal-gautreau"
                              class="card-categories-li-content"
                              >Ronal Gautreau(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ronald-pfeiffer"
                              class="card-categories-li-content"
                              >Ronald Pfeiffer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ros-jay"
                              class="card-categories-li-content"
                              >Ros Jay(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ros-taylor"
                              class="card-categories-li-content"
                              >Ros Taylor(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rosalynn-tamara"
                              class="card-categories-li-content"
                              >Rosalynn Tamara(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rosemary-contreras"
                              class="card-categories-li-content"
                              >Rosemary Contreras(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roshani-chokshi"
                              class="card-categories-li-content"
                              >Roshani Chokshi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rosmida-s-singgih-a-irene-maria-j-a"
                              class="card-categories-li-content"
                              >Rosmida S.-Singgih A.-Irene Maria J.A.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=roy-macrides"
                              class="card-categories-li-content"
                              >Roy Macrides(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rudi-corers"
                              class="card-categories-li-content"
                              >Rudi Corers(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rudi-hidayat"
                              class="card-categories-li-content"
                              >Rudi Hidayat(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rudianto"
                              class="card-categories-li-content"
                              >Rudianto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rudianto"
                              class="card-categories-li-content"
                              >Rudianto(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rudianto-fe-trisakti"
                              class="card-categories-li-content"
                              >Rudianto (Fe Trisakti)(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rukman-kiswari"
                              class="card-categories-li-content"
                              >Rukman Kiswari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rusdi-mathari"
                              class="card-categories-li-content"
                              >Rusdi Mathari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rusli-siri-s"
                              class="card-categories-li-content"
                              >Rusli Siri S(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rustam-effendi"
                              class="card-categories-li-content"
                              >Rustam Effendi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rustamaji"
                              class="card-categories-li-content"
                              >Rustamaji(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rustamaji-dkk"
                              class="card-categories-li-content"
                              >Rustamaji, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rustamaji-husin-shyla-k-p-gendra"
                              class="card-categories-li-content"
                              >Rustamaji-Husin-Shyla K.-P. Gendra(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=rutamianisa"
                              class="card-categories-li-content"
                              >Rutamianisa(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ruth-galloway"
                              class="card-categories-li-content"
                              >Ruth Galloway(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ruth-ware"
                              class="card-categories-li-content"
                              >RUTH WARE(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ruwi-meita"
                              class="card-categories-li-content"
                              >Ruwi Meita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=s-r-ariawan-kun-maryati"
                              class="card-categories-li-content"
                              >S.R. Ariawan-Kun Maryati(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sabda-armandio"
                              class="card-categories-li-content"
                              >Sabda Armandio(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sabine-kuegler"
                              class="card-categories-li-content"
                              >Sabine Kuegler(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sadi-m-nasikim"
                              class="card-categories-li-content"
                              >Sadi - M. Nasikim(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sadi-nasikin"
                              class="card-categories-li-content"
                              >Sadi-Nasikin(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sadiman"
                              class="card-categories-li-content"
                              >Sadiman(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=safri-ayat"
                              class="card-categories-li-content"
                              >Safri Ayat(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=saiful-falah"
                              class="card-categories-li-content"
                              >Saiful Falah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=saktiyono"
                              class="card-categories-li-content"
                              >Saktiyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=salamah"
                              class="card-categories-li-content"
                              >Salamah(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=salvatore"
                              class="card-categories-li-content"
                              >Salvatore(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sam-lake"
                              class="card-categories-li-content"
                              >Sam Lake(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=samantha-broke"
                              class="card-categories-li-content"
                              >Samantha Broke(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=samith-athif-al-zain"
                              class="card-categories-li-content"
                              >Samith Athif Al Zain(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=samuel-webster"
                              class="card-categories-li-content"
                              >Samuel Webster(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=samuelson"
                              class="card-categories-li-content"
                              >Samuelson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sandrine-gambart"
                              class="card-categories-li-content"
                              >Sandrine Gambart(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sapardi-djoko-armono"
                              class="card-categories-li-content"
                              >Sapardi Djoko Armono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sapardi-djoko-damono"
                              class="card-categories-li-content"
                              >Sapardi Djoko Damono(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sapardi-djoko-damono-rintik-sedu"
                              class="card-categories-li-content"
                              >Sapardi Djoko Damono, Rintik Sedu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sapardi-djoko-darmono"
                              class="card-categories-li-content"
                              >Sapardi Djoko Darmono (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=saptono"
                              class="card-categories-li-content"
                              >Saptono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=saptono-m-pd"
                              class="card-categories-li-content"
                              >Saptono, M.Pd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sara-lewis"
                              class="card-categories-li-content"
                              >Sara Lewis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-bridges"
                              class="card-categories-li-content"
                              >Sarah Bridges(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-goodwin-dkk"
                              class="card-categories-li-content"
                              >Sarah Goodwin, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-iwens"
                              class="card-categories-li-content"
                              >Sarah Iwens(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-khan"
                              class="card-categories-li-content"
                              >Sarah Khan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-prihantina"
                              class="card-categories-li-content"
                              >Sarah Prihantina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarah-toulmin"
                              class="card-categories-li-content"
                              >Sarah Toulmin(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sari-komala-dewi"
                              class="card-categories-li-content"
                              >Sari Komala Dewi(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sartono-w-muji-d"
                              class="card-categories-li-content"
                              >Sartono W.-Muji D.(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sartono-dr"
                              class="card-categories-li-content"
                              >Sartono, Dr(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarwoko"
                              class="card-categories-li-content"
                              >Sarwoko(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sarwoko"
                              class="card-categories-li-content"
                              >Sarwoko(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sasbadi"
                              class="card-categories-li-content"
                              >Sasbadi(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sascha-p-dan-adam-p"
                              class="card-categories-li-content"
                              >Sascha P & Adam P(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=saviour-pirotta"
                              class="card-categories-li-content"
                              >Saviour Pirotta(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=schaarwachter"
                              class="card-categories-li-content"
                              >Schaarwachter(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=scholastic-australia"
                              class="card-categories-li-content"
                              >Scholastic Australia(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=scout-driggs"
                              class="card-categories-li-content"
                              >Scout Driggs(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sekretariat-mui"
                              class="card-categories-li-content"
                              >Sekretariat Mui(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=selly-l"
                              class="card-categories-li-content"
                              >Selly L.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=seno-gumira-ajidarma"
                              class="card-categories-li-content"
                              >Seno Gumira Ajidarma(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=seplia"
                              class="card-categories-li-content"
                              >Seplia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=seto-mulyadi"
                              class="card-categories-li-content"
                              >Seto Mulyadi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=seymour-l"
                              class="card-categories-li-content"
                              >Seymour L.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shalil-ge"
                              class="card-categories-li-content"
                              >Shalil Ge(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sheila-kitzinger"
                              class="card-categories-li-content"
                              >Sheila Kitzinger(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sheila-sweeny"
                              class="card-categories-li-content"
                              >Sheila Sweeny(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sheiland"
                              class="card-categories-li-content"
                              >Sheiland(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sheri-radford"
                              class="card-categories-li-content"
                              >Sheri Radford(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sheridan-cain"
                              class="card-categories-li-content"
                              >Sheridan Cain(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sherman-alexie"
                              class="card-categories-li-content"
                              >Sherman Alexie(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shim-seung-hyun"
                              class="card-categories-li-content"
                              >SHIM SEUNG-HYUN(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shin-do-hyun-yoon-na-ru"
                              class="card-categories-li-content"
                              >Shin Do Hyun, Yoon Na Ru(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shindunata"
                              class="card-categories-li-content"
                              >Shindunata (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shindunata"
                              class="card-categories-li-content"
                              >Shindunata .(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sholeh-dimyathi-dkk"
                              class="card-categories-li-content"
                              >Sholeh Dimyathi, Dkk(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=shyla-k-lande-eka-mulya-astuti"
                              class="card-categories-li-content"
                              >Shyla K. Lande-Eka Mulya Astuti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sid-delmar-leach"
                              class="card-categories-li-content"
                              >Sid Delmar Leach(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siera-hartmann"
                              class="card-categories-li-content"
                              >Siera Hartmann(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sierray-medow"
                              class="card-categories-li-content"
                              >Sierray Medow(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=simon-brown"
                              class="card-categories-li-content"
                              >Simon Brown(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=simon-sumardi"
                              class="card-categories-li-content"
                              >Simon Sumardi(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=simone-wolpert"
                              class="card-categories-li-content"
                              >Simone Wolpert(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sindhunata"
                              class="card-categories-li-content"
                              >Sindhunata(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sioban-s"
                              class="card-categories-li-content"
                              >Sioban S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sir-arthur-conan-doyle"
                              class="card-categories-li-content"
                              >Sir Arthur Conan Doyle(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sirial"
                              class="card-categories-li-content"
                              >Sirial(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sirojudin"
                              class="card-categories-li-content"
                              >Sirojudin(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sisca-soewitomo"
                              class="card-categories-li-content"
                              >Sisca Soewitomo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sisca-susanto"
                              class="card-categories-li-content"
                              >Sisca Susanto(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siswanto-msc-fe-atmajaya-yg"
                              class="card-categories-li-content"
                              >Siswanto, Msc (Fe Atmajaya Yg)(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siti-alfiyah"
                              class="card-categories-li-content"
                              >SITI ALFIYAH(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siti-eryda"
                              class="card-categories-li-content"
                              >Siti Eryda(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siti-m-amin"
                              class="card-categories-li-content"
                              >Siti M. Amin(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=siti-naqiyah-suswanto"
                              class="card-categories-li-content"
                              >Siti Naqiyah - Suswanto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=slamet-wiyono"
                              class="card-categories-li-content"
                              >Slamet Wiyono(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sobandi"
                              class="card-categories-li-content"
                              >Sobandi(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=soedirman-dan-sumamur"
                              class="card-categories-li-content"
                              >Soedirman & Suma'Mur(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=soedjono"
                              class="card-categories-li-content"
                              >Soedjono(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sohn-won-pyung"
                              class="card-categories-li-content"
                              >Sohn Won - Pyung(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=solihin"
                              class="card-categories-li-content"
                              >Solihin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=spiegel-mr"
                              class="card-categories-li-content"
                              >Spiegel Mr.(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=spin-off"
                              class="card-categories-li-content"
                              >Spin Off(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-astuti-dkk"
                              class="card-categories-li-content"
                              >Sri Astuti, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-astuti-dkk"
                              class="card-categories-li-content"
                              >Sri Astuti, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-ayu-imaningtyas"
                              class="card-categories-li-content"
                              >Sri Ayu Imaningtyas(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-endang-r-suyetty-sri-mulyani"
                              class="card-categories-li-content"
                              >Sri Endang R.-Suyetty-Sri Mulyani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-endang-rahayu"
                              class="card-categories-li-content"
                              >Sri Endang Rahayu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-endang-rahayu-dkk"
                              class="card-categories-li-content"
                              >Sri Endang Rahayu,Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-endang-sri-mulyani-suyetty"
                              class="card-categories-li-content"
                              >Sri Endang-Sri Mulyani-Suyetty(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-kurnianingsih"
                              class="card-categories-li-content"
                              >Sri Kurnianingsih(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-kurnianingsih-dan-zaenudin"
                              class="card-categories-li-content"
                              >Sri Kurnianingsih & Zaenudin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-mintarti-ayep-rosidi"
                              class="card-categories-li-content"
                              >Sri Mintarti-Ayep Rosidi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-mintarti-himawan"
                              class="card-categories-li-content"
                              >Sri Mintarti-Himawan(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-silastri-dkk"
                              class="card-categories-li-content"
                              >Sri Silastri, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-sulastri"
                              class="card-categories-li-content"
                              >Sri Sulastri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-sulastri-dkk"
                              class="card-categories-li-content"
                              >Sri Sulastri, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-tutik-cahyaningsih"
                              class="card-categories-li-content"
                              >Sri Tutik Cahyaningsih(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-wahyuni-dewi-suryana"
                              class="card-categories-li-content"
                              >SRI WAHYUNI - DEWI SURYANA(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sri-yukaliana-dkk"
                              class="card-categories-li-content"
                              >Sri Yukaliana, Dkk(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stanley-j-baran"
                              class="card-categories-li-content"
                              >Stanley J. Baran(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stella-baggot"
                              class="card-categories-li-content"
                              >Stella Baggot(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stephanie-budiarta"
                              class="card-categories-li-content"
                              >Stephanie Budiarta(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stephanie-joshepine"
                              class="card-categories-li-content"
                              >Stephanie Joshepine(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stephenie-meyer"
                              class="card-categories-li-content"
                              >STEPHENIE MEYER(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stephie-anindita"
                              class="card-categories-li-content"
                              >Stephie Anindita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=steve-bruce"
                              class="card-categories-li-content"
                              >Steve Bruce(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=steve-martin-dkk"
                              class="card-categories-li-content"
                              >Steve Martin, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=steven-j-leon"
                              class="card-categories-li-content"
                              >Steven J/ Leon(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stoecker-wr"
                              class="card-categories-li-content"
                              >Stoecker Wr.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=stolk-jac-ir"
                              class="card-categories-li-content"
                              >Stolk Jac, Ir(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=su-laurent"
                              class="card-categories-li-content"
                              >Su. Laurent(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suarli-dan-yanyan-bahtiar"
                              class="card-categories-li-content"
                              >Suarli & Yanyan Bahtiar(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=subhash-c-jain"
                              class="card-categories-li-content"
                              >Subhash C. Jain(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sudirdja-dedi-fatah-y"
                              class="card-categories-li-content"
                              >Sudirdja-Dedi Fatah Y.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sudirman"
                              class="card-categories-li-content"
                              >Sudirman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sudjatmoko-dkk"
                              class="card-categories-li-content"
                              >Sudjatmoko, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sue-finnie"
                              class="card-categories-li-content"
                              >Sue Finnie(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sue-heap"
                              class="card-categories-li-content"
                              >Sue Heap(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sue-rose"
                              class="card-categories-li-content"
                              >Sue Rose(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sugianto"
                              class="card-categories-li-content"
                              >Sugianto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sugiyanto-dkk"
                              class="card-categories-li-content"
                              >Sugiyanto, Dkk(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sugiyanto-dkk"
                              class="card-categories-li-content"
                              >Sugiyanto,Dkk(11)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sugiyanto-t-agustien-probo-h-t-sapto"
                              class="card-categories-li-content"
                              >Sugiyanto-T. Agustien-Probo H.-T. Sapto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suhendra-dan-suroso"
                              class="card-categories-li-content"
                              >Suhendra Dan Suroso(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukhmeet-panesar"
                              class="card-categories-li-content"
                              >Sukhmeet Panesar(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukino"
                              class="card-categories-li-content"
                              >Sukino(18)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukino-edi-afrizal"
                              class="card-categories-li-content"
                              >Sukino-Edi Afrizal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukismo"
                              class="card-categories-li-content"
                              >Sukismo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukismo-dkk"
                              class="card-categories-li-content"
                              >Sukismo, Dkk(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukismo-wini-kristanti"
                              class="card-categories-li-content"
                              >Sukismo-Wini Kristanti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukismo-wini-kristianti"
                              class="card-categories-li-content"
                              >Sukismo-Wini Kristianti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukma-sahadewa-dkk"
                              class="card-categories-li-content"
                              >Sukma Sahadewa, Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sukron-kamil"
                              class="card-categories-li-content"
                              >Sukron Kamil(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sulardi"
                              class="card-categories-li-content"
                              >Sulardi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sulistiowati"
                              class="card-categories-li-content"
                              >Sulistiowati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sulistiyono"
                              class="card-categories-li-content"
                              >Sulistiyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sumantoro-kasdhani"
                              class="card-categories-li-content"
                              >Sumantoro Kasdhani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sumarwan"
                              class="card-categories-li-content"
                              >Sumarwan(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sunaryo-yuni-n"
                              class="card-categories-li-content"
                              >Sunaryo-Yuni N.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sunim-haemin"
                              class="card-categories-li-content"
                              >Sunim, Haemin(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suparman-marzuki"
                              class="card-categories-li-content"
                              >Suparman Marzuki(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=supranto-j-ma"
                              class="card-categories-li-content"
                              >Supranto J, Ma(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=susan-amerikaner"
                              class="card-categories-li-content"
                              >Susan Amerikaner(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=susan-meredith"
                              class="card-categories-li-content"
                              >Susan Meredith(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=susie-agung"
                              class="card-categories-li-content"
                              >SUSIE AGUNG(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suswanto-djoni-siti-naqiyah"
                              class="card-categories-li-content"
                              >Suswanto Djoni - Siti Naqiyah(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suswanto-dkk"
                              class="card-categories-li-content"
                              >Suswanto, Dkk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suswanto-dkk"
                              class="card-categories-li-content"
                              >Suswanto,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sutanto-leo"
                              class="card-categories-li-content"
                              >Sutanto Leo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suwaji-aminarni"
                              class="card-categories-li-content"
                              >Suwaji - Aminarni(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suwaji-kunduri"
                              class="card-categories-li-content"
                              >Suwaji - Kunduri(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=suwarsono-muhammad"
                              class="card-categories-li-content"
                              >Suwarsono Muhammad(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=swales"
                              class="card-categories-li-content"
                              >Swales(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=syakir-muharrar-dan-sri-verayanti"
                              class="card-categories-li-content"
                              >Syakir Muharrar & Sri Verayanti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=syarinayoon"
                              class="card-categories-li-content"
                              >Syarinayoon(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sylvia-t-pratiwi"
                              class="card-categories-li-content"
                              >Sylvia T. Pratiwi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=sylvia-y-muliawan"
                              class="card-categories-li-content"
                              >Sylvia Y. Muliawan(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=t-d-haryo-n-suparno"
                              class="card-categories-li-content"
                              >T.D Haryo-N.Suparno(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=t-d-haryo-tamtomo-n-suparno"
                              class="card-categories-li-content"
                              >T.D. Haryo Tamtomo-N. Suparno(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tabularasa"
                              class="card-categories-li-content"
                              >Tabularasa(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tae-won-joon"
                              class="card-categories-li-content"
                              >TAE WON JOON(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tamsin-weston"
                              class="card-categories-li-content"
                              >Tamsin Weston(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tamsyn-murray"
                              class="card-categories-li-content"
                              >Tamsyn Murray(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tanya-dalton"
                              class="card-categories-li-content"
                              >Tanya Dalton(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tara-westover"
                              class="card-categories-li-content"
                              >TARA WESTOVER(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tasaro-taufiq-saptoto-rohadi"
                              class="card-categories-li-content"
                              >Tasaro (Taufiq Saptoto Rohadi)(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tatag-e"
                              class="card-categories-li-content"
                              >Tatag E.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=taylor-adams"
                              class="card-categories-li-content"
                              >Taylor Adams(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tdb"
                              class="card-categories-li-content"
                              >Tdb(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=teddy-w-kusuma-dan-maesy-ang"
                              class="card-categories-li-content"
                              >Teddy W. Kusuma & Maesy Ang (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tedy-wibowo-bintari-ayu-s"
                              class="card-categories-li-content"
                              >Tedy Wibowo-Bintari Ayu S(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=teguh-irianto"
                              class="card-categories-li-content"
                              >Teguh Irianto(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tere-liye"
                              class="card-categories-li-content"
                              >Tere Liye(27)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=terence-a-shimp"
                              class="card-categories-li-content"
                              >Terence A.Shimp(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=terjemahan"
                              class="card-categories-li-content"
                              >Terjemahan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tessa-thomas"
                              class="card-categories-li-content"
                              >Tessa Thomas(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=th-m-sudarwati"
                              class="card-categories-li-content"
                              >Th. M. Sudarwati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=th-m-sudarwati-eudia-grace"
                              class="card-categories-li-content"
                              >Th. M. Sudarwati - Eudia Grace(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=th-m-sudarwati-eudia-grace"
                              class="card-categories-li-content"
                              >Th. M. Sudarwati-Eudia Grace(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=the-reverend-w-awdry"
                              class="card-categories-li-content"
                              >The Reverend W. Awdry(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=the-reverend-w-awdry"
                              class="card-categories-li-content"
                              >The Reverend W. Awdry(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thea-utomo-semiawan"
                              class="card-categories-li-content"
                              >Thea Utomo Semiawan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=theodora-sarah-abigail"
                              class="card-categories-li-content"
                              >Theodora Sarah Abigail(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=theoresia-rumthe-dan-weslly-johannes"
                              class="card-categories-li-content"
                              >Theoresia Rumthe & Weslly Johannes(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-c-wang"
                              class="card-categories-li-content"
                              >Thomas C. Wang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-cleary"
                              class="card-categories-li-content"
                              >Thomas Cleary(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-erikson"
                              class="card-categories-li-content"
                              >Thomas Erikson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-krist"
                              class="card-categories-li-content"
                              >Thomas Krist(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-porter-w"
                              class="card-categories-li-content"
                              >Thomas Porter W.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thomas-r-dyckman"
                              class="card-categories-li-content"
                              >Thomas R. Dyckman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=thriwaty-arsal"
                              class="card-categories-li-content"
                              >Thriwaty Arsal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tia-ragat"
                              class="card-categories-li-content"
                              >Tia Ragat(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim"
                              class="card-categories-li-content"
                              >Tim(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-abdi-guru"
                              class="card-categories-li-content"
                              >Tim Abdi Guru(18)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-abdi-guru"
                              class="card-categories-li-content"
                              >Tim Abdi Guru(14)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-anak-bangsa"
                              class="card-categories-li-content"
                              >Tim Anak Bangsa(30)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-anak-bangsa-ketua-tim-asyari"
                              class="card-categories-li-content"
                              >TIM ANAK BANGSA (KETUA TIM: ASYARI)(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-bina-cipta-prestasi"
                              class="card-categories-li-content"
                              >Tim Bina Cipta Prestasi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-bina-karya-guru"
                              class="card-categories-li-content"
                              >Tim Bina Karya Guru(60)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-bina-karya-guru"
                              class="card-categories-li-content"
                              >Tim Bina Karya Guru(44)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-cpp"
                              class="card-categories-li-content"
                              >Tim Cpp(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-dapur-esensi"
                              class="card-categories-li-content"
                              >Tim Dapur Esensi(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-dapus-esensi"
                              class="card-categories-li-content"
                              >Tim Dapus Esensi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-divaro"
                              class="card-categories-li-content"
                              >Tim Divaro(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editor"
                              class="card-categories-li-content"
                              >Tim Editor(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editor-penerbit-erlangga"
                              class="card-categories-li-content"
                              >Tim Editor Penerbit Erlangga(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editor-konsultan"
                              class="card-categories-li-content"
                              >Tim Editor-Konsultan(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editorial"
                              class="card-categories-li-content"
                              >Tim Editorial(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editorial-efk"
                              class="card-categories-li-content"
                              >Tim Editorial Efk(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-editorial-tk"
                              class="card-categories-li-content"
                              >Tim Editorial Tk(35)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-edukatif"
                              class="card-categories-li-content"
                              >Tim Edukatif(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-efk"
                              class="card-categories-li-content"
                              >Tim Efk(13)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-efk"
                              class="card-categories-li-content"
                              >Tim Efk(80)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-emir"
                              class="card-categories-li-content"
                              >Tim Emir(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-erlangga-fokus-akm"
                              class="card-categories-li-content"
                              >TIM ERLANGGA FOKUS AKM(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-erlangga-fokus-sma"
                              class="card-categories-li-content"
                              >Tim Erlangga Fokus Sma(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-erlangga-fokus-utbk"
                              class="card-categories-li-content"
                              >Tim Erlangga Fokus Utbk(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-erlangga-for-kids"
                              class="card-categories-li-content"
                              >Tim Erlangga For Kids(27)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-erlass"
                              class="card-categories-li-content"
                              >TIM ERLASS(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-esensi"
                              class="card-categories-li-content"
                              >Tim Esensi(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-esensi-2"
                              class="card-categories-li-content"
                              >Tim Esensi 2(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-explorer"
                              class="card-categories-li-content"
                              >Tim Explorer(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-ganesha-operation"
                              class="card-categories-li-content"
                              >Tim Ganesha Operation(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-gpu"
                              class="card-categories-li-content"
                              >Tim Gpu(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-guru-cinta-lingk"
                              class="card-categories-li-content"
                              >Tim Guru Cinta Lingk.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-ide-masak-tim-ide-masak"
                              class="card-categories-li-content"
                              >Tim Ide Masak, TIM IDE MASAK(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-igra-jawa-tengah"
                              class="card-categories-li-content"
                              >Tim Igra Jawa Tengah(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-kokoru"
                              class="card-categories-li-content"
                              >Tim Kokoru(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-kreatif-kokoru"
                              class="card-categories-li-content"
                              >Tim Kreatif Kokoru(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-mitra-guru"
                              class="card-categories-li-content"
                              >Tim Mitra Guru(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-mitra-guru"
                              class="card-categories-li-content"
                              >Tim Mitra Guru(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-mitra-guru-asyari-dkk"
                              class="card-categories-li-content"
                              >Tim Mitra Guru (Asy'Ari,Dkk)(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-mitra-pendidikan"
                              class="card-categories-li-content"
                              >Tim Mitra Pendidikan(44)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-pena-guru"
                              class="card-categories-li-content"
                              >Tim Pena Guru(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-pkbl"
                              class="card-categories-li-content"
                              >Tim Pkbl(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-progresif"
                              class="card-categories-li-content"
                              >Tim Progresif(50)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-pusat-karier-salemba"
                              class="card-categories-li-content"
                              >TIM PUSAT KARIER SALEMBA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-redaksi-majalah-thomas"
                              class="card-categories-li-content"
                              >Tim Redaksi Majalah Thomas(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-tadika-kreatif"
                              class="card-categories-li-content"
                              >Tim Tadika Kreatif(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tim-teropong-un"
                              class="card-categories-li-content"
                              >Tim Teropong Un(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=timoshenko"
                              class="card-categories-li-content"
                              >Timoshenko(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tina-schager"
                              class="card-categories-li-content"
                              >Tina Schager(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=titi-nurmanik-rinawati-ana-maulia"
                              class="card-categories-li-content"
                              >Titi Nurmanik-Rinawati-Ana Maulia(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tjitji-wartisah"
                              class="card-categories-li-content"
                              >Tjitji Wartisah(7)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tjitji-wartisah"
                              class="card-categories-li-content"
                              >Tjitji Wartisah(26)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=todaro-dan-smith"
                              class="card-categories-li-content"
                              >Todaro & Smith(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=todd-j-albert"
                              class="card-categories-li-content"
                              >Todd J. Albert(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=todung-mulya-lubis"
                              class="card-categories-li-content"
                              >Todung Mulya Lubis(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tom-lissauer-dan-arroy"
                              class="card-categories-li-content"
                              >Tom Lissauer & Arroy(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tom-oldfield"
                              class="card-categories-li-content"
                              >Tom Oldfield(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tom-tauli"
                              class="card-categories-li-content"
                              >Tom Tauli(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tony-maddox"
                              class="card-categories-li-content"
                              >Tony Maddox(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tony-ross"
                              class="card-categories-li-content"
                              >Tony Ross(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=toshikazu-kawaguchi"
                              class="card-categories-li-content"
                              >Toshikazu Kawaguchi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tracey-corderay"
                              class="card-categories-li-content"
                              >Tracey Corderay(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tracey-kelly"
                              class="card-categories-li-content"
                              >Tracey Kelly(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tresno-sukendro-sukarman"
                              class="card-categories-li-content"
                              >Tresno Sukendro-Sukarman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=trevor-linsley"
                              class="card-categories-li-content"
                              >Trevor Linsley(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=tri-harso-karyono"
                              class="card-categories-li-content"
                              >Tri Harso Karyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=triana-dan-karina"
                              class="card-categories-li-content"
                              >Triana & Karina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=trinity"
                              class="card-categories-li-content"
                              >TRINITY(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=triwibowo-yuwono"
                              class="card-categories-li-content"
                              >Triwibowo Yuwono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=uci-soemarno"
                              class="card-categories-li-content"
                              >Uci Soemarno(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ukim-komarudin"
                              class="card-categories-li-content"
                              >Ukim Komarudin(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ulil-abshar-abdalla"
                              class="card-categories-li-content"
                              >Ulil Abshar Abdalla(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=umar-igiasi"
                              class="card-categories-li-content"
                              >Umar Igiasi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ummu-faris"
                              class="card-categories-li-content"
                              >Ummu Faris(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ummu-faris"
                              class="card-categories-li-content"
                              >Ummu Faris(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=unggul-sudarmo"
                              class="card-categories-li-content"
                              >Unggul Sudarmo(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=unggul-sudarmo-nanik-mitayani"
                              class="card-categories-li-content"
                              >Unggul Sudarmo-Nanik Mitayani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=untung-w"
                              class="card-categories-li-content"
                              >Untung W.(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=untung-widodo"
                              class="card-categories-li-content"
                              >Untung Widodo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=usborne"
                              class="card-categories-li-content"
                              >Usborne(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=usborne-publishing"
                              class="card-categories-li-content"
                              >Usborne Publishing(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=usman-m-pd"
                              class="card-categories-li-content"
                              >Usman M.Pd(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=usmandanida-inayahwati"
                              class="card-categories-li-content"
                              >Usman&Ida Inayahwati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=utari-ninghadiyani"
                              class="card-categories-li-content"
                              >Utari Ninghadiyani(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=v-harlen-sinaga"
                              class="card-categories-li-content"
                              >V. Harlen Sinaga(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=valerie-w"
                              class="card-categories-li-content"
                              >Valerie W(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=valery-kumaat"
                              class="card-categories-li-content"
                              >Valery Kumaat(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=vanda-yuiranti"
                              class="card-categories-li-content"
                              >Vanda Yuiranti(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=venerdi-handoyo"
                              class="card-categories-li-content"
                              >Venerdi Handoyo (1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=vernon-a-musselman"
                              class="card-categories-li-content"
                              >Vernon A. Musselman(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=veronica-grabiella"
                              class="card-categories-li-content"
                              >Veronica Grabiella(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=veronica-rossi"
                              class="card-categories-li-content"
                              >VERONICA ROSSI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=vianna-valentina"
                              class="card-categories-li-content"
                              >Vianna Valentina(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=victoria-morran"
                              class="card-categories-li-content"
                              >Victoria Morran(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=victoria-tebbs"
                              class="card-categories-li-content"
                              >Victoria Tebbs(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=vidya-dwina-paramita"
                              class="card-categories-li-content"
                              >Vidya Dwina Paramita(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=viktor-e-frankl"
                              class="card-categories-li-content"
                              >Viktor E. Frankl.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=virginia-wolf"
                              class="card-categories-li-content"
                              >Virginia Wolf(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=virly-k-a"
                              class="card-categories-li-content"
                              >Virly K.A.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=von-christine-stief-christian-stang"
                              class="card-categories-li-content"
                              >Von Christine Stief-Christian Stang(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wade-tauris-garry"
                              class="card-categories-li-content"
                              >Wade-Tauris-Garry(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyu-aditya"
                              class="card-categories-li-content"
                              >wahyu aditya(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyu-aditya-dan-ika-yuliana-k"
                              class="card-categories-li-content"
                              >Wahyu Aditya dan Ika Yuliana K(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyu-aditya-dan-tria-ayu-k"
                              class="card-categories-li-content"
                              >Wahyu Aditya dan Tria Ayu K(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyu-hartikasari"
                              class="card-categories-li-content"
                              >Wahyu Hartikasari(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyu-triyono"
                              class="card-categories-li-content"
                              >Wahyu Triyono(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wahyudi-pratama"
                              class="card-categories-li-content"
                              >WAHYUDI PRATAMA(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=ward-clarke"
                              class="card-categories-li-content"
                              >Ward, Clarke(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=waris-dirie"
                              class="card-categories-li-content"
                              >Waris Dirie(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wayne-gold"
                              class="card-categories-li-content"
                              >Wayne Gold(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wensley-clarkson"
                              class="card-categories-li-content"
                              >Wensley Clarkson(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=widaningsih-dan-samsul-rizal"
                              class="card-categories-li-content"
                              >Widaningsih & Samsul Rizal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=widaningsih-samsul-rizal"
                              class="card-categories-li-content"
                              >Widaningsih-Samsul Rizal(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=widya-t-eka-m-sri-s-elly-s"
                              class="card-categories-li-content"
                              >Widya T.-Eka M.-Sri S.-Elly S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=widya-trisnawati"
                              class="card-categories-li-content"
                              >Widya Trisnawati(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=william-h-hayt-jr"
                              class="card-categories-li-content"
                              >William H. Hayt Jr.(4)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=william-j-stanton"
                              class="card-categories-li-content"
                              >William J. Stanton(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=william-stanfell"
                              class="card-categories-li-content"
                              >William Stanfell(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=william-waver"
                              class="card-categories-li-content"
                              >William Waver(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wilson-bangun"
                              class="card-categories-li-content"
                              >Wilson Bangun(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wini-kristianti"
                              class="card-categories-li-content"
                              >Wini Kristianti(10)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wini-kristianti-dkk"
                              class="card-categories-li-content"
                              >Wini Kristianti, Dkk(9)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wini-fransiska-irene"
                              class="card-categories-li-content"
                              >Wini-Fransiska-Irene(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wirda-mansur"
                              class="card-categories-li-content"
                              >Wirda Mansur(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wisnu-maulana"
                              class="card-categories-li-content"
                              >Wisnu Maulana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wiwiek-puspitasari"
                              class="card-categories-li-content"
                              >WIWIEK PUSPITASARI(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wiwik-puspitasari"
                              class="card-categories-li-content"
                              >Wiwik Puspitasari(18)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wono-setya-budhi"
                              class="card-categories-li-content"
                              >Wono Setya Budhi(12)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wono-setya-budhi-untung-widodo"
                              class="card-categories-li-content"
                              >Wono Setya Budhi-Untung Widodo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=wulanfadi"
                              class="card-categories-li-content"
                              >Wulanfadi(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yadika-putri"
                              class="card-categories-li-content"
                              >Yadika Putri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yanita-mila-ardiani"
                              class="card-categories-li-content"
                              >Yanita Mila Ardiani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yasinto-sindhu-p-wawan-i-h"
                              class="card-categories-li-content"
                              >Yasinto Sindhu P.-Wawan I. H(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yasinto-sindhu-priastomo"
                              class="card-categories-li-content"
                              >Yasinto Sindhu Priastomo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yasinto-sindhu-sunaryo"
                              class="card-categories-li-content"
                              >Yasinto Sindhu-Sunaryo(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yayat-sudaryat-deni-hadiansah"
                              class="card-categories-li-content"
                              >Yayat Sudaryat-Deni Hadiansah(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yayat-sudaryat-deni-hadiansah"
                              class="card-categories-li-content"
                              >Yayat Sudaryat-Deni Hadiansah(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yendaniels"
                              class="card-categories-li-content"
                              >Yendaniels(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yeni-mulyani"
                              class="card-categories-li-content"
                              >Yeni Mulyani(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yonezawa-honobu"
                              class="card-categories-li-content"
                              >Yonezawa Honobu(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yoppy-wahyu-purnomo"
                              class="card-categories-li-content"
                              >Yoppy Wahyu Purnomo(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuchaliana"
                              class="card-categories-li-content"
                              >Yuchaliana(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yugha-erlangga"
                              class="card-categories-li-content"
                              >Yugha Erlangga(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yugha-erlangga-tim-divaro"
                              class="card-categories-li-content"
                              >Yugha Erlangga-Tim Divaro(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yukaliana-s-w"
                              class="card-categories-li-content"
                              >Yukaliana S.W(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuki-anggia-putri"
                              class="card-categories-li-content"
                              >Yuki Anggia Putri(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuli-kismawati-s-pd"
                              class="card-categories-li-content"
                              >YULI KISMAWATI, S.Pd.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuliansyah-reskino-biana-inapti"
                              class="card-categories-li-content"
                              >Yuliansyah-Reskino-Biana Inapti(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yulita-rintyastini"
                              class="card-categories-li-content"
                              >Yulita Rintyastini(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yunita-cristy"
                              class="card-categories-li-content"
                              >Yunita Cristy(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yusi-avianto-pareanom"
                              class="card-categories-li-content"
                              >Yusi Avianto Pareanom(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yustinah"
                              class="card-categories-li-content"
                              >Yustinah(5)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yusup-somadinata"
                              class="card-categories-li-content"
                              >Yusup Somadinata(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuval-noah-harari"
                              class="card-categories-li-content"
                              >Yuval Noah Harari(2)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuyus-kardiman-dkk"
                              class="card-categories-li-content"
                              >Yuyus Kardiman, Dkk(8)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuyus-kardiman-dkk"
                              class="card-categories-li-content"
                              >Yuyus Kardiman,Dkk(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuyus-kardiman-alam-s"
                              class="card-categories-li-content"
                              >Yuyus Kardiman-Alam S.(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuyus-kardiman-dkk"
                              class="card-categories-li-content"
                              >Yuyus Kardiman-Dkk(3)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=yuyus-kardiman-tuty-alam-s"
                              class="card-categories-li-content"
                              >Yuyus Kardiman-Tuty-Alam S.(6)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=zara-zetira"
                              class="card-categories-li-content"
                              >Zara Zetira(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=zep"
                              class="card-categories-li-content"
                              >Zep(1)</a
                            >
                          </li>
                          <li>
                            <a
                              href="https://www.eurekabookhouse.co.id/cari/result?author=zulaehah-h-ratihqah-munar-w"
                              class="card-categories-li-content"
                              >Zulaehah H. - Ratihqah Munar W.(1)</a
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <p class="stext-101 cl2 p-tb-8 p-l-15 text-left">
                    <b> BERDASARKAN DISKON </b>
                  </p>
                  <div class="flex-w flex-t bor12 p-b-13">
                    <div class="pad-1">
                      <select
                        name="diskon"
                        class="filter_diskon"
                        style="height: 36px; width: 100%; margin-top: 0px"
                        required
                      >
                        <option value="">Pilih diskon</option>
                        <option value="1">5% - 10%</option>
                        <option value="2">10% - 15%</option>
                        <option value="3">15% - 20%</option>
                      </select>
                    </div>
                  </div>
                  <p class="stext-101 cl2 p-tb-8 p-l-15 text-left">
                    <b> BATAS HARGA</b>
                  </p>
                  <div class="flex-w flex-t p-b-13">
                    <div class="pad-1">
                      <div class="form-row">
                        <div class="form-group col-md-6">
                          <label for="inputEmail4">Awal</label>
                          <input
                            type="text"
                            name="min_price"
                            id="min_price"
                            class="form-control"
                            placeholder="Price"
                          />
                        </div>
                        <div class="form-group col-md-6">
                          <label for="inputPassword4">Akhir</label>
                          <input
                            type="text"
                            name="max_price"
                            id="max_price"
                            class="form-control"
                            placeholder="Price"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pad-1">
                    <button
                      id="submit_filter"
                      class="stext-101 cl0 btn-block size-103 bg1 bor4 hov-btn0 p-lr-15"
                    >
                      Filter
                    </button>
                    <button
                      id="submit_clear"
                      class="stext-101 cl0 btn-block size-103 bg1 bor4 hov-btn0 p-lr-15"
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              </div>

              <div id="content_side" class="col-md-9">
                <div class="bg0 bs1 p-3">
                  <div id="postList" class="row">
                    <div
                      class="post-id col-7 col-sm-4 col-md-3 col-lg-3 p-lr-5"
                      id="17289"
                      style="margin-bottom: 10px"
                    >
                      <div class="block2 bg0">
                        <div
                          class="block2-pic hov-img0 label-new text-center"
                          data-label="20%"
                        >
                          <img
                            class="img-official"
                            src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                          />
                          <a
                            href="https://www.eurekabookhouse.co.id/product/informatika-sd-kls6"
                            class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                          >
                            <img
                              class="lazy"
                              src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-informatika-sd-kls6.jpg"
                              alt="informatika-sd-kls-6"
                            />
                          </a>
                        </div>
                        <div class="block2-txt flex-w flex-t p-all-8">
                          <div class="block2-txt-child1 flex-col-l">
                            <a
                              href="https://www.eurekabookhouse.co.id/product/informatika-sd-kls6"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6 text-left"
                            >
                              Informatika Sd Kls.6
                            </a>
                            <div class="stext-105">
                              <s><small class="fs-9">Rp.88.000</small></s
                              ><strong class="cl13"> Rp.70.400</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5"
                      id="17285"
                      style="margin-bottom: 10px"
                    >
                      <div class="block2 bg0">
                        <div
                          class="block2-pic hov-img0 label-new text-center"
                          data-label="20%"
                        >
                          <img
                            class="img-official"
                            src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                          />
                          <a
                            href="https://www.eurekabookhouse.co.id/product/informatika-sd-kls4 text-left"
                            class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                          >
                            <img
                              class="lazy"
                              src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-informatika-sd-kls4.jpg"
                              alt="informatika-sd-kls-4"
                            />
                          </a>
                        </div>
                        <div class="block2-txt flex-w flex-t p-all-8">
                          <div class="block2-txt-child1 flex-col-l">
                            <a
                              href="https://www.eurekabookhouse.co.id/product/informatika-sd-kls4"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6 text-left"
                            >
                              Informatika Sd Kls.4
                            </a>
                            <div class="stext-105">
                              <s><small class="fs-9">Rp.78.000</small></s
                              ><strong class="cl13"> Rp.62.400</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5"
                      id="7316"
                      style="margin-bottom: 10px"
                    >
                      <div class="block2 bg0">
                        <div
                          class="block2-pic hov-img0 label-new text-center"
                          data-label="20%"
                        >
                          <img
                            class="img-official"
                            src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                          />
                          <a
                            href="https://www.eurekabookhouse.co.id/product/teknologi-infodankom-jl-1-ktsp-revisi"
                            class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                          >
                            <img
                              class="lazy"
                              src="https://cdn.eurekabookhouse.co.id/ebh/product/all/0020040010.jpg"
                              alt="teknologi-infodankom-jl-1-ktsp-revisi"
                            />
                          </a>
                        </div>
                        <div class="block2-txt flex-w flex-t p-all-8">
                          <div class="block2-txt-child1 flex-col-l">
                            <a
                              href="https://www.eurekabookhouse.co.id/product/teknologi-infodankom-jl-1-ktsp-revisi"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6 text-left"
                            >
                              Teknologi Infodankom Jl.1/Ktsp/Revisi
                            </a>
                            <div class="stext-105">
                              <s><small class="fs-9">Rp.99.000</small></s
                              ><strong class="cl13"> Rp.79.200</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="post-id col-6 col-sm-4 col-md-3 col-lg-3 p-lr-5"
                      id="7311"
                      style="margin-bottom: 10px"
                    >
                      <div class="block2 bg0">
                        <div
                          class="block2-pic hov-img0 label-new text-center"
                          data-label="20%"
                        >
                          <img
                            class="img-official"
                            src="https://stagingapi.eurekabookhouse.co.id/assets/image/mall_badge.png"
                          />
                          <a
                            href="https://www.eurekabookhouse.co.id/product/7311"
                            class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6"
                          >
                            <img
                              class="lazy"
                              src="https://cdn.eurekabookhouse.co.id/ebh/product/all/ebh-0020040060.jpg"
                              alt="teknologi-infodankom-jl-6-ktsp-revisi"
                            />
                          </a>
                        </div>
                        <div class="block2-txt flex-w flex-t p-all-8">
                          <div class="block2-txt-child1 flex-col-l">
                            <a
                              href="https://www.eurekabookhouse.co.id/product/7311"
                              class="stext-105 cl2 hov-cl1 trans-04 js-name-b2 p-b-6 text-left"
                            >
                              Teknologi Infodankom Jl.6/Ktsp/Revisi
                            </a>
                            <div class="stext-105">
                              <s><small class="fs-9">Rp.95.000</small></s
                              ><strong class="cl13"> Rp.76.000</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="show_more_main" id="show_more_main7311">
                      <button
                        id="7311"
                        class="show_load_more flex-c-m stext-101 cl0 size-103 bg1 bor1 hov-btn1 p-lr-15 trans-04"
                        style="margin: 0px auto"
                      >
                        Lainnya
                      </button>
                      <span class="loding" style="display: none"
                        ><span class="loding_txt">Loading...</span></span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script scope>
export default {
  name: "tiPage",
};
</script>